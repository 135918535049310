app.service("MedicationAPI", [
    "$http",
    "ENV",
    "Session",
    function ($http, ENV, Session) {
        var service = {
			isProcessing: false,
            getMedicationOptions: function () {
               return $http({
                  method: "GET",
                  url: ENV.API + '/api/medication',
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
               });
            },
            addMedication: function (patientId, medicationArray) {
                return $http({
                   method: "POST",
                   url: ENV.API + `/api/medication/${patientId}`,
                   headers: {
                      Authorization: "Bearer " + Session.token,
                      "X-PRACTICE-ID": `${Session.practice.id}`,
                   },
                   data: medicationArray
                });
             },
             getPatientsMedication: function (patientId) {
                return $http({
                    method: "GET",
                    url: ENV.API + `/api/medication/${patientId}?type=REGULAR`,
                    headers: {
                       Authorization: "Bearer " + Session.token,
                       "X-PRACTICE-ID": `${Session.practice.id}`,
                    },
                 });
             },
             deleteMedication: function(patientId, medicationId) {
                return $http({
                    method: "DELETE",
                    url: ENV.API + `/api/medication/${patientId}/${medicationId}`,
                    headers: {
                       Authorization: "Bearer " + Session.token,
                       "X-PRACTICE-ID": `${Session.practice.id}`,
                    },
                 });
             },
             prescription: function(patientId, medications){
               return $http({
                  method: "POST",
                  url: ENV.API + `/api/medication/${patientId}/prescription-note?prescriptionNoteType=PRINT`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
                  responseType: "arraybuffer",
                  data: medications
               });
             },
             electronicPrescription: function(patientId, medications, pharmacyId){
               return $http({
                  method: "POST",
                  url: ENV.API + `/api/medication/${patientId}/prescription-note?prescriptionNoteType=ELECTRONIC&pharmacyId=${pharmacyId}`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
                  responseType: "arraybuffer",
                  data: medications
               });
             },
             changeRegularMedication: function(patientId,  medication_ids){
               return $http({
                  method: "PUT",
                  url: ENV.API + `/api/medication/${patientId}/medication-regular`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
                  data: medication_ids
               });
             },
             updateMedication: function(patientId,medication_id, data){
               return $http({
                  method: "PUT",
                  url: ENV.API + `/api/medication/${patientId}/${medication_id}`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
                  data,
               });
             },
             queryPharmacy: function(term){
               return $http({
                  method: "GET",
                  url: ENV.API + `/api/medication/pharmacy?term=${term}`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`
                  },
               });
             },
             getPatientSettings: function(patientId){
               return $http({
                  method: "GET",
                  url: ENV.API + `/api/medication/${patientId}/settings`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
               });
             }
        }
        return service;
    }]);