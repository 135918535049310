app.component("preOpDiabetes", {
	templateUrl: "app/src/js/components/preOpHistory/preOpDiabetes/preOpDiabetes.html",
	controller: "preOpDiabetesController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("preOpDiabetesController", [
	"$scope",
	"PreOp",
	"MACULOPATHY",
	"RETINOPATHY",
	function ($scope, PreOp, MACULOPATHY, RETINOPATHY) {
		$scope.preop = PreOp;
		$scope.maculopathy = MACULOPATHY;
		$scope.retinopathy = RETINOPATHY;

		this.$onInit = function () {};

		$scope.watchNoView = function (ctrl) {
			ctrl.data.maculopathy = "NO_VIEW";
			ctrl.data.retinopathy = "NO_VIEW";
		};

		$scope.showRetinopathyPopUp = function () {
			if (this.$ctrl.showRetinopathy === false || this.$ctrl.showRetinopathy === undefined) {
				this.$ctrl.showRetinopathy = true;
			} else {
				this.$ctrl.showRetinopathy = false;
			}
		};

		$scope.showMaculopathyPopUp = function () {
			if (this.$ctrl.showMaculopathy === false || this.$ctrl.showMaculopathy === undefined) {
				this.$ctrl.showMaculopathy = true;
			} else {
				this.$ctrl.showMaculopathy = false;
			}
		};
	},
]);
