app.component("allergyForm", {
	templateUrl: "app/src/js/components/medications/AllergyForm.html",
	controller: "allergyFormController",
	bindings: {
		patientId: "=",
	},
});

app.controller("allergyFormController", [
	"$scope",
	"$modal",
    "$filter",
    "Session",
	"AllergyAPI",
	"ShareData",
	function ($scope, $modal,$filter,Session,AllergyAPI,ShareData) {

        this.$onInit = function () {
            $scope.causative_agents_noResults = false;
            $scope.causative_agents_loading = false;
            $scope.type_of_reaction_noResults = false;
            $scope.type_of_reaction_loading = false;

            $scope.AllergyAPI = AllergyAPI;
			$scope.formData = {
				causative_agents: "",
                causative_agents_search_term: "",
				causative_agents_object: null,
                reaction_type: "",
                reaction_type_search_term: "",
				reaction_type__object: null,
			};
            $scope.allergyList = [];
			$scope.NO_DRUG_ALLERGIES = {value: true};
			fetchPatientAllergy();
		};

		$scope.warn = false;

		$scope.$watch(
			"$ctrl.allergyForm",
			function (newValue, oldValue) {
				if($scope.$ctrl.allergyForm &&
					$scope.$ctrl.allergyForm.severity && 
					$scope.$ctrl.allergyForm.certainty &&
					$scope.formData.causative_agents &&
					$scope.formData.reaction_type
					){
						$scope.warn = true;
					}else{
						$scope.warn = false;
					}
			},
			true
		);

		$scope.$watch(
			"formData",
			function (newValue, oldValue) {
				if($scope.$ctrl.allergyForm &&
					$scope.$ctrl.allergyForm.severity && 
					$scope.$ctrl.allergyForm.certainty &&
					$scope.formData.causative_agents &&
					$scope.formData.reaction_type
					){
						$scope.warn = true;
					}else{
						$scope.warn = false;
					}
			},
			true
		);

		$scope.renderLastOccurrence = function(lastOccurrence) {
			return lastOccurrence ? `(${lastOccurrence})` : '';
		}
      
		$scope.checkboxModel = {};

        $scope.Session = Session;
		$scope.SD = ShareData;
        $scope.causativeAgentsOnSelect = function(item, model, label, event) {
			$scope.formData.causative_agents_object = item;
        }

        

        $scope.reactionOnSelect = function(item, model, label, event) {
			$scope.formData.reaction_type__object = item;
        }


        $scope.fetchCausativeAgents = function (value, theForm) {
            $scope.causative_agents_loading = true;
            return new Promise((resolve, reject) => {
                searchAllergy(value, resolve, reject)
            })
        };

        $scope.fetchReaction = function (value, theForm) {
            $scope.type_of_reaction_loading = true;
            return new Promise((resolve, reject) => {
                searchReaction(value, resolve, reject)
            })
        };

		$scope.NKDAOnChange = function(){
			if($scope.NO_DRUG_ALLERGIES.value == undefined){
				return;
			}
			$scope.AllergyAPI.isProcessing = true;
			$scope.AllergyAPI.saveNKDA($scope.$ctrl.patientId, $scope.NO_DRUG_ALLERGIES.value).then(function(resp){
				if(!$scope.NO_DRUG_ALLERGIES.value){
					$scope.AllergyAPI.getAllergy($scope.$ctrl.patientId).then(function(resp){
						$scope.allergyList = resp.data;
					},function(err){
						console.log(`${JSON.stringify(err)}`)
					});
				}
				
			},function(err){
				console.log(`${JSON.stringify(err)}`);
			}).then(function () {
				$scope.AllergyAPI.isProcessing = false;
			})
		}

		$scope.hasActiveAllergy = function () {
			return $scope.allergyList.some(t=>t.status === 'ACTIVE');
		}

		$scope.isFieldRequired = function () {
			if($scope.NO_DRUG_ALLERGIES.value){
				return false;
			}
			return !$scope.allergyList.some(t=>t.status ===  'ACTIVE');
		}


        function fetchPatientAllergy() {
			$scope.AllergyAPI.isProcessing = true;
			$scope.AllergyAPI.getNKDA($scope.$ctrl.patientId).then(function(resp){
				if(!resp.data.no_known_drug_allergies){
					$scope.AllergyAPI.getAllergy($scope.$ctrl.patientId).then(function(resp){
						$scope.allergyList = resp.data;
						$scope.SD.setAllergies($scope.$ctrl.patientId, resp.data);
					},function(err){
						console.log(`${JSON.stringify(err)}`)
					}).then(function () {
						$scope.AllergyAPI.isProcessing = false;
						$scope.NO_DRUG_ALLERGIES.value = resp.data.no_known_drug_allergies;
					})
				}else{
					$scope.AllergyAPI.isProcessing = false;
				}
			},function(err){
				$scope.AllergyAPI.isProcessing = false;
			})
        }
		


		function searchAllergy(terms, resolve, reject) {
			let result = [];
            $scope.causative_agents_noResults = false;
			$scope.AllergyAPI.searchAllergy(terms,'CAUSATIVE_AGENT')
				.then(
					function (resp) {
						if (resp.data) {
							result=resp.data;
                            $scope.formData.causative_agents_search_term = terms;
							resolve(result)
						}
					},
					function (err) {
						console.log(`${JSON.stringify(err)}`)
                        $scope.causative_agents_noResults = true;
                        result = [];
						reject(err)
					}
				).then(function () {
					$scope.causative_agents_loading = false;
				})
		}

        function searchReaction(terms, resolve, reject) {
			let result = [];
            $scope.type_of_reaction_noResults = false;
			$scope.AllergyAPI.searchAllergy(terms,'REACTION')
				.then(
					function (resp) {
						if (resp.data) {
							result=resp.data;
                            $scope.formData.reaction_type_search_term = terms;
							resolve(result)
						}
					},
					function (err) {
						console.log(`${JSON.stringify(err)}`)
                        $scope.type_of_reaction_noResults = true;
                        result = [];
						reject(err)
					}
				).then(function () {
					$scope.type_of_reaction_loading = false;
				})
		}

        $scope.removeAllergy = function (patient_allergy_id) {
			$scope.AllergyAPI.isProcessing = true;
			$scope.AllergyAPI.inactivatePatientAllergy($scope.$ctrl.patientId, patient_allergy_id)
				.then(
					function (resp) {
						// console.log(`!!!! ${JSON.stringify(resp.data)}`);
						// $scope.diagnosisData = resp.data;
					},
					function (err) {
						console.log(err);
                        $scope.serverErrorModal = $modal({
                            scope: $scope,
                            templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                            show: true,
                            title: "CatTrax has encountered an error",
                        });
					}
				)
				.then(function (obj) {
					$scope.AllergyAPI.isProcessing = false;
					fetchPatientAllergy();
				});
			
		}

		$scope.NO_DRUG_ALLERGIES = true;
		$scope.today = new Date();
	
	

		$scope.SEVERITY = [
			{ name: "Mild", value: "MILD" },
			{ name: "Moderate", value: "MODERATE" },
			{ name: "Severe", value: "SEVERE" },
			// { name: "Life threatening", value: "life_threatening" },
			{ name: "Fatal", value: "FATAL" },
		];

		$scope.CERTAINTY = [
			{ name: "Unlikely", value: "UNLIKELY" },
			{ name: "Likely", value: "LIKELY" },
			{ name: "Certain", value: "CERTAIN" },
			// { name: "Confirmed by challenge testing", value: "confirmed" },
		]

		$scope.removeAllergyPopover = {
			content: "Delete"
		};

		$scope.addAllergy = function (causative_agents,severity,certainty,type_of_reaction,first_experienced,reaction_details) {
			if (causative_agents == null || severity == null || certainty == null || type_of_reaction == null) {
				return;
			}

            var lname = $scope.Session.user.lastName || "";
			var fname = $scope.Session.user.firstName || "";
			const fullName = fname + " " + lname;
			
			const allergyData = {
                date_of_onset: $filter("date")(new Date(), "yyyy-MM-dd"),
                date_of_last_occurrence: $filter("date")(new Date(first_experienced), "yyyy-MM-dd"),
                asserter: fullName,
                comment: reaction_details,
                causative_agent_concept: causative_agents,
				reaction_concept: type_of_reaction,
				severity,
				certainty,
			}

            $scope.AllergyAPI.isProcessing = true;
            $scope.AllergyAPI.addAllergy($scope.$ctrl.patientId,allergyData).then(function(resp){
                fetchPatientAllergy();
            },function(err){
                $scope.serverErrorModal = $modal({
                    scope: $scope,
                    templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                    show: true,
                    title: "CatTrax has encountered an error",
                });
                console.log(`${JSON.stringify(err)}`)
            }).then(function () {
                $scope.AllergyAPI.isProcessing = false;
            })

			$scope.$ctrl.allergyForm.causative_agents = null;
			$scope.$ctrl.allergyForm.severity = null;
			$scope.$ctrl.allergyForm.certainty = null;
			$scope.$ctrl.allergyForm.type_of_reaction = null;
			$scope.$ctrl.allergyForm.first_experienced = null;
			$scope.$ctrl.allergyForm.reaction_details = null;
            $scope.formData = {
				causative_agents: "",
                causative_agents_search_term: "",
				causative_agents_object: null,
                reaction_type: "",
                reaction_type_search_term: "",
				reaction_type__object: null,
			};
			$scope.allergyForm.$setPristine();
		}
	},
]);