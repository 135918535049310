app.component("preOpHistory", {
	templateUrl: "app/src/js/components/preOpHistory/preOpHistory.html",
	controller: "preOpHistoryController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		eyeTitleLowercase: "@",
		eyeName: "@",
	},
});

app.controller("preOpHistoryController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		this.$onInit = function () {
			this.PreOp = PreOp;
		};
	},
]);
