app.component("appointmentsList", {
	templateUrl: "app/src/js/directives/appointmentsList.html",
	controller: "appointmentsListController",
	bindings: {
        data: "<",
		trigger: "&",
	},
});

app.controller("appointmentsListController", [
	"$scope",
	"$filter",
	"$modal",
	"CurrentPatient",
	function ($scope, $filter,$modal,CurrentPatient) {
		

		this.$onInit = function () {
		   $scope.CP = CurrentPatient;
	       $scope.appointments = $scope.$ctrl.data;
           $scope.pastAppointments = $scope.appointments.filter(t=>moment(t.start_date_utc).isBefore(moment())).sort((b,a)=>moment(a.start_date_utc) - moment(b.start_date_utc));
           $scope.futureAppointments = $scope.appointments.filter(t=>!$scope.pastAppointments.some(m=>m.patient_appointment_id === t.patient_appointment_id)).sort((b,a)=>moment(a.start_date_utc) - moment(b.start_date_utc));
		}

        $scope.formatText = function (data){
			const category = data.category_name ? data.category_name : 'UNKNOWN';
			const dateTime =  $filter("date")(moment.utc(data.start_date_utc).toDate(), "dd/MM/yyyy HH:mm");
			let seen = '';
			if(data.patient_seen){
				seen = getProgressString(data.patient_seen)
			}

			return `${category}: ${dateTime} ${seen}`
        }

        $scope.editAppointment = function(appointment){
			$scope.$ctrl.trigger({appointment});
        }

		function getProgressString(seen) {
			if (!seen) {
				return "";
			} 

			let timestamp = null;
			let seen_latest = null;

			if(seen.doctor_started){
				timestamp = new Date(seen.doctor_started_timestamp);
				seen_latest = 'Doctor started';
			}

			if(seen.doctor_finished){
				if(!timestamp || moment(seen.doctor_finished_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.doctor_finished_timestamp);
					seen_latest = 'Doctor finished';
				}
			}

			if(seen.technician_started) {
				if(!timestamp || moment(seen.technician_started_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.technician_started_timestamp);
					seen_latest = 'Technician started';
				}
			}

			if(seen.technician_finished) {
				if(!timestamp || moment(seen.technician_finished_timestamp).isAfter(moment(timestamp)) ){
					timestamp = new Date(seen.technician_finished_timestamp);
					seen_latest = 'Technician finished';
				}
			}

			if(seen.nurse_started) {
				if(!timestamp || moment(seen.nurse_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Nurse started';
					timestamp = new Date(seen.nurse_started_timestamp);
				}
			}

			if(seen.nurse_finished) {
				if(!timestamp || moment(seen.nurse_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Nurse finished';
					timestamp = new Date(seen.nurse_finished_timestamp);
				}
			}


			if(seen.optometrist_started) {
				if(!timestamp || moment(seen.optometrist_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Optometrist started';
					timestamp = new Date(seen.optometrist_started_timestamp);
				}
			}

			if(seen.optometrist_finished) {
				if(!timestamp || moment(seen.optometrist_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Optometrist finished';
					timestamp = new Date(seen.optometrist_finished_timestamp);
				}
			}

			if(seen.orthoptist_started) {
				if(!timestamp || moment(seen.orthoptist_started_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Orthoptist started';
					timestamp = new Date(seen.orthoptist_started_timestamp);
				}
			}

			if(seen.orthoptist_finished) {
				if(!timestamp || moment(seen.orthoptist_finished_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Orthoptist finished';
					timestamp = new Date(seen.orthoptist_finished_timestamp);
				}
			}

			if(seen.arrived) {
				if(!timestamp || moment(seen.arrived_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Arrived';
					timestamp = new Date(seen.arrived_timestamp);
				}
			}

			if(seen.departed) {
				if(!timestamp || moment(seen.departed_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Departed';
					timestamp = new Date(seen.departed_timestamp);
				}
			}

			if(seen.could_not_attend) {
				if(!timestamp || moment(seen.could_not_attend_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'CNA';
					timestamp = new Date(seen.could_not_attend_timestamp);
				}
			}

			if(seen.did_not_attend) {
				if(!timestamp || moment(seen.did_not_attend_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'DNA';
					timestamp = new Date(seen.did_not_attend_timestamp);
				}
			}

			if(seen.rebooked) {
				if(!timestamp || moment(seen.rebooked_timestamp).isAfter(moment(timestamp)) ){
					seen_latest = 'Rebooked';
					timestamp = new Date(seen.rebooked_timestamp);
				}
			}

			if(seen_latest && timestamp){
				return `(${seen_latest}: ${$filter("date")(new Date(timestamp), "HH:mm")})`
			}
			return "";
		}
	},
]);
