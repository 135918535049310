app.component("medicationForm", {
	templateUrl: "app/src/js/components/medications/MedicationForm.html",
	controller: "medicationFormController",
	bindings: {
		patientId: "=",
		data: "=",
		onMedicationUpdate: "<",
	},
});

app.controller("medicationFormController", [
	"$scope",
	"$modal",
	"DOSE",
	"ROUTE",
	"POSTOPERATIVEMAP",
	"MedicationAPI",
	function ($scope, $modal, DOSE, ROUTE, POSTOPERATIVEMAP, MedicationAPI) {
		$scope.MEDICATIONS = []; // MEDICATIONS.map((t) => t);
		$scope.DOSE = DOSE;
		$scope.ROUTE = ROUTE;
		$scope.warn = false;
		$scope.data = [];
		$scope.MA = MedicationAPI;
		$scope.medicationOptions = [];
		$scope.medicationData = [];
		
		fetchMedicationOptions();
		

		function fetchMedicationOptions() {
			$scope.MA.isProcessing = false;
			$scope.MA.getMedicationOptions().then(function (resp) {
				$scope.medicationOptions = resp.data;
				$scope.MEDICATIONS = $scope.medicationOptions.map(t => t.medication);
			},
				function (error) {
					console.error(error);
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}).then(function () {
					$scope.MA.isProcessing = false;
				})
		}

		function deleteMedication(medicationId) {
			$scope.MA.isProcessing = false;
			$scope.MA.deleteMedication($scope.$ctrl.patientId, medicationId).then(function (resp) {
				// $scope.medicationOptions = resp.data;
				// $scope.MEDICATIONS = $scope.medicationOptions.map(t=>t.medication);
				$scope.$ctrl.onMedicationUpdate();
			},
				function (error) {
					console.error(error);
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}).then(function () {
					$scope.MA.isProcessing = false;
				})
		}

		$scope.removeMedication = function (obj) {
			// if (obj.medication_id) {
			// 	deleteMedication(obj.medication_id);
			// }else{
			const index = $scope.$ctrl.data.findIndex(t => t === obj);
			$scope.$ctrl.data.splice(index, 1);
			// }
		};
		$scope.addMedication = function (medication, dose_quantity,dose_unit, frequency, route, duration, comments) {
			if (medication == null || dose_quantity == null || dose_unit== null || frequency == null || route == null || duration == null) {
				return;
			}
			const data = {
				medication: medication.selected.medication,
				electronic_prescription_available: medication.selected.electronic_prescription_available,
				dose_quantity,
				dose_unit,
				frequency,
				route,
				duration,
				medication_type: 'REGULAR',
				snomed_medication_id: medication.selected.snomed_medication_id,
				comments: [{comment: comments}]
			}
        
			if(!medication.selected.electronic_prescription_available){
				$scope.alertMessage = `${medication.selected.medication} is a Class C controlled drug and is not available on e-prescription. If you wish to prescribe codeine please print and sign your prescription`;
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "",
				});
			}

			$scope.$ctrl.data.push(data);

			$scope.$ctrl.form.medication = null;
			// $scope.$ctrl.form.dose = null;
			$scope.$ctrl.form.dose_quantity = null;
			$scope.$ctrl.form.dose_unit = null;
			$scope.$ctrl.form.frequency = null;
			$scope.$ctrl.form.route = null;
			$scope.$ctrl.form.duration = null;
			$scope.$ctrl.form.comments = null;
			$scope.warn = false;
			$scope.medicationForm.$setPristine();
		};

		$scope.removePopover = {
			content: "Click 'x' to remove this medication from the current prescription. Checking 'Regular' will save this as a regular medication."
		};

		$scope.getRouteName = function (value) {
			return $scope.ROUTE.find((t) => t.value === value).name;
		};

		$scope.showWarn = function () {
			$scope.warn = true;
		};

		$scope.onMedicationChange = function () {
			if ($scope.$ctrl.form.medication.selected) {
				$scope.$ctrl.form.dose_quantity = $scope.$ctrl.form.medication.selected.dose_quantity;
				$scope.$ctrl.form.dose_unit = $scope.$ctrl.form.medication.selected.dose_unit;
				$scope.$ctrl.form.frequency = $scope.$ctrl.form.medication.selected.frequency;
				$scope.$ctrl.form.duration = $scope.$ctrl.form.medication.selected.duration;
				$scope.$ctrl.form.comments = "";
				if($scope.$ctrl.form.medication.selected.route){
					$scope.$ctrl.form.route = $scope.$ctrl.form.medication.selected.route.toUpperCase();
					$scope.showWarn();
					$scope.medicationForm.$setPristine();
				}else{
					$scope.medicationForm.$setPristine();
					$scope.$ctrl.form.route = null;
					$scope.warn = false;
				}
			}
		};

		$scope.uncheckRegular = function (item){
			// if(item.medication_type === 'REGULAR'){
			// 	return;
			// }
			// $scope.MA.isProcessing = false;
			// $scope.MA.changeRegularMedication($scope.$ctrl.patientId, item.medication_id).then(function (resp) {
			// 	$scope.$ctrl.onMedicationUpdate();
			// },
			// 	function (error) {
			// 		console.error(error);
			// 		$scope.serverErrorModal = $modal({
			// 			scope: $scope,
			// 			templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
			// 			show: true,
			// 			title: "CatTrax has encountered an error",
			// 		});
			// 	}).then(function () {
			// 		$scope.MA.isProcessing = false;
			// 	})
		}
	},
]);