app.service('PatientAPI', ['$http', 'ENV', 'Session', function($http, ENV, Session) {

	var service = {
		isProcessing: false,

		search: function (lastName, dob, searchTerm, nhi) {
			if(searchTerm){
				const parameter_dob = dob ? "&dob=" + dob : "";
				return $http({
					method: "GET",
					url: ENV.API + "/api/patients?" + parameter_dob + "&searchTerm=" + searchTerm + '&searchType=ADVANCED',
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			}

			if(nhi){
				return $http({
					method: 'GET',
					url: ENV.API + '/api/patients?nhi=' + nhi + '&searchType=NHI',
					headers: {
						'Authorization': 'Bearer ' + Session.token,
					}
				}) 
			}
			
			return $http({
				method: 'GET',
				url: ENV.API + '/api/patients?lastName=' + lastName + '&dob=' + dob+'&searchType=BASIC',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
				}
			})
		},
		transfer: function (patientId, referrer, practice) {
			return $http({
				method: "POST",
				url: ENV.API + "/api/patients/" + patientId + "/transfer",
				headers: {
					Authorization: "Bearer " + Session.token,
					"Content-Type": "application/json",
				},
				data: {
					referrer: {
						id: referrer,
					},
					practice: {
						id: practice,
					},
				},
			});
		},

		create: function (patient, sendEmr) {
			const _sendEmr = sendEmr == undefined ? false : sendEmr;
			return $http({
				method: "POST",
				url: ENV.API + "/api/patients?sendEmr=" + _sendEmr,
				headers: {
					Authorization: "Bearer " + Session.token,
					"Content-Type": "application/json",
				},
				data: patient,
			});
		},

		update: function (patient, sendEmr) {
			const _sendEmr = sendEmr == undefined ? false : sendEmr;
			return $http({
				method: "PUT",
				url: ENV.API + "/api/patients/" + patient.id + "?sendEmr=" + _sendEmr,
				headers: {
					Authorization: "Bearer " + Session.token,
				},
				data: patient,
			});
		},

		fetchDocument: function (patientId) {
			return $http({
				method: "GET",
				url: ENV.API + "/api/documents/patient/" + patientId,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},

		downloadDocument: function (patientId, documentId, docType) {
			return $http({
				method: "GET",
				url: ENV.API + `/api/documents/patient/${patientId}/document/${docType}/${documentId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				responseType: "arraybuffer",
			});
		},
		addToFavourite: function (patientId) {
			return $http({
				method: "PATCH",
				url: ENV.API + `/api/users/${Session.userId}/features/favorite_patients/` + patientId,
				headers: {
					Authorization: "Bearer " + Session.token,
				},
			});
		},
		removeFavourite: function (patientId) {
			return $http({
				method: "DELETE",
				url: ENV.API + `/api/users/${Session.userId}/features/favorite_patients/` + patientId,
				headers: {
					Authorization: "Bearer " + Session.token,
				},
			});
		},
		getPatient: function (patientId) {
			return $http({
				method: "GET",
				url: ENV.API + "/api/patients/" + patientId,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		getVisionChart: function (patientId, chartName) {
			return $http({
				method: "GET",
				url: ENV.API + `/api/chart/patient/${patientId}/vision?chartName=${chartName}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		addToRecentPatients: function (patientId) {
			return $http({
				method: "PATCH",
				url: ENV.API + `/api/users/${Session.userId}/features/recent_patients/` + patientId,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		getPractitioners: function (patientId) {
			return $http({
				method: "GET",
				url: ENV.API + "/api/hpi/patient/" + patientId + "/general-practitioners",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		patientVerification: function (token) {
			return $http({
				method: "GET",
				url: ENV.API + `/api/patient-verification/${token}`,
				// headers: {
				// 	Authorization: "Bearer " + Session.token,
				// 	"X-PRACTICE-ID": `${Session.practice.id}`,
				// },
			});
		},
		patientConsent: function (token, dob,research=false,ml=false) {
			const formData = new FormData();
			formData.append('dob', dob);
			formData.append('consent_data_research', research);
			formData.append('consent_data_ml', ml);


			return $http({
				method: 'POST',
				url: ENV.API + `/api/patient-verification/${token}`,
				headers: {
					'Content-Type': undefined,
				},
				data:formData,
				transformRequest: angular.identity
			})
		}
	};

	return service;
}])
