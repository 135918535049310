app.service("PlaceAPI", [
	"$http",
	function ($http) {
		var service = {
			getPlaceDetail: function (element, address, callback) {
                const displaySuggestions = function (predictions, status) {
					console.log(`====PlaceAPI: ${google.maps.places.PlacesServiceStatus.OK}`)
					if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
						console.error(status);
						callback(null);
						return;
					}

					let length = predictions.length;

					predictions.forEach((prediction) => {
						var service = new google.maps.places.PlacesService(element);
						const nz = "New Zealand"
						const country = prediction.description.substring(prediction.description.length - nz.length);
						if(country === nz){
							service.getDetails({
								placeId: prediction.place_id,
								fields: ['address_component', 'geometry', 'adr_address','formatted_address', 'type', 'place_id','url']
							}, function (place, status) {
								callback(place);
							});
						}else{
							length--;
						}
					});
					if(length === 0){
						callback(null);
					}
				};
				const service = new google.maps.places.AutocompleteService();
				service.getQueryPredictions({ input: address, componentRestrictions: { country: 'nz' } }, displaySuggestions);	
			},
		};

		return service;
	},
]);
