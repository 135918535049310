app.component("examinationStatus", {
	templateUrl: "app/src/js/components/examination/examinationStatus.html",
	controller: "examinationStatusController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		hasDifference: "=",
		isAcuityVisible: "=",
		isLinkHidden: "=",
		preset: "=",
		isVisionModule: "=",
	},
});

app.controller("examinationStatusController", [
	"$scope",
	"LETTER",
	"CYL",
	"DEVICE",
	"$timeout",
	"LENS_STATUS",
	"NUCLEAR_OPALESCENCE",
	"NUCLEAR_COLOUR",
	"CORTICAL",
	"POST_SUBCAPSULAR",
	"PUPIL_SIZE",
	"VISUAL_POTENTIAL",
	"LINE",
	"CORRECTION_TYPE",
	function ($scope, LETTER, CYL, DEVICE, $timeout,LENS_STATUS, NUCLEAR_OPALESCENCE, NUCLEAR_COLOUR, CORTICAL, POST_SUBCAPSULAR, PUPIL_SIZE, VISUAL_POTENTIAL, LINE,CORRECTION_TYPE) {
		$scope.visualacuityline =  LINE;
		$scope.letter = LETTER;
		$scope.cyl = CYL;
		$scope.device = DEVICE;
		$scope.lens_status = LENS_STATUS;
		$scope.nuclear_opalescence = NUCLEAR_OPALESCENCE;
		$scope.nuclear_colour = NUCLEAR_COLOUR;
		$scope.cortical = CORTICAL;
		$scope.post_subcapsular = POST_SUBCAPSULAR;
		$scope.pupil_size = PUPIL_SIZE;
		$scope.visual_potential = VISUAL_POTENTIAL;
		$scope.correction_type = CORRECTION_TYPE;
		

		this.$onInit = function () {
			$scope._preset = $scope.$ctrl.preset;
		};

		$scope.$watch("$ctrl.isAcuityVisible", function (newValue, oldValue) {
			if (!newValue) {
				$scope.$ctrl.data.bcvaLineMedium = null;
				$scope.$ctrl.data.bcvaLetterMedium = null;
				$scope.$ctrl.data.bcvaLineNear = null;
				$scope.$ctrl.data.bcvaLetterNear = null;
			}
		}, true);

		$scope.$watch("$ctrl.data.ucvaLine", function (newValue, oldValue) {
			if (!newValue && $scope.$ctrl.data) {
				$scope.$ctrl.data.ucvaLetter = null;
			}
		}, true);

		$scope.$watchGroup(["$ctrl.data.sphere","$ctrl.data.cyl","$ctrl.data.axis"],function(newValues, oldValues, scope){
			if (!$scope.$ctrl.data) return;
			if($scope.isNumber(newValues[0]) && $scope.isNumber(newValues[1]) && $scope.isNumber(newValues[2])){
				// do nothing
			}else if($scope.isNumber(newValues[0]) || $scope.isNumber(newValues[1]) || $scope.isNumber(newValues[2])){
				$scope.$ctrl.data.auto_refraction = undefined
			}
		});

		$timeout(function () {
			if ($scope.$ctrl.data == undefined) {
				$scope.$ctrl.data = {};
			}
			// UI requires these fields to default to "0" if no value present.
			// $timeout used to wait and see if DB provides value (hack)
			// if ($scope.$ctrl.data.best_va_letter == null || $scope.$ctrl.data.best_va_letter == undefined) {
			// 	$scope.$ctrl.data.best_va_letter = 0;
			// }
			// if ($scope.$ctrl.data.uncorrected_va_letter == null || $scope.$ctrl.data.uncorrected_va_letter == undefined) {
			// 	$scope.$ctrl.data.uncorrected_va_letter = 0;
			// }
		}, 800);

		$scope.showOpalPopUp = function () {
			if (this.$ctrl.showOpal === false || this.$ctrl.showOpal === undefined) {
				this.$ctrl.showOpal = true;
			} else {
				this.$ctrl.showOpal = false;
			}
		};

		$scope.showColourPopUp = function () {
			if (this.$ctrl.showColour === false || this.$ctrl.showColour === undefined) {
				this.$ctrl.showColour = true;
			} else {
				this.$ctrl.showColour = false;
			}
		};

		$scope.showCorticalPopUp = function () {
			if (this.$ctrl.showCortical === false || this.$ctrl.showCortical === undefined) {
				this.$ctrl.showCortical = true;
			} else {
				this.$ctrl.showCortical = false;
			}
		};

		$scope.showSubPopUp = function () {
			if (this.$ctrl.showSub === false || this.$ctrl.showSub === undefined) {
				this.$ctrl.showSub = true;
			} else {
				this.$ctrl.showSub = false;
			}
		};

		$scope.showPupilPopUp = function () {
			if (this.$ctrl.showPupil === false || this.$ctrl.showPupil === undefined) {
				this.$ctrl.showPupil = true;
			} else {
				this.$ctrl.showPupil = false;
			}
		};

		$scope.$watch("$ctrl.data.noImprovement", function(newValue, oldValue){
			if(newValue && !$scope._preset){
				$scope.$ctrl.data.bcvaLine = $scope.$ctrl.data.ucvaLine || $scope.$ctrl.data.bcvaLine;
				$scope.$ctrl.data.bcvaLetter = $scope.$ctrl.data.ucvaLetter || $scope.$ctrl.data.bcvaLetter;
			}else{
				$scope._preset = false;
			}
		}, true);

		$scope.copyLine = function(){
			if($scope.$ctrl.data.noImprovement){
				$scope.$ctrl.data.bcvaLine = $scope.$ctrl.data.ucvaLine;
			}
		};

		$scope.copyLetter = function(){
			if($scope.$ctrl.data.noImprovement){
				$scope.$ctrl.data.bcvaLetter = $scope.$ctrl.data.ucvaLetter;
			}
		};

		$scope.addAcuity = function(){
			$scope.$ctrl.isAcuityVisible = true;
		}

		$scope.popoverIntermediate = {
			content:
				"Measure with ETDRS intermediate chart",
		};

		$scope.popoverNear = {
			content:
				"Measure with ETDRS near chart",
		};

		$scope.isNumber = function (value) {
			return (value === 0 || value !=null)
		}

		$scope.refractionCylinderOnChange = function () {
			if ($scope.$ctrl.data.cyl === 0){
				$scope.$ctrl.data.axis = 0;
			}
		}

		$scope.addPressure = function () {
            if (!$scope.$ctrl.data.additional_pressure_measurements) {
                $scope.$ctrl.data.additional_pressure_measurements = [];
            }
            $scope.$ctrl.data.additional_pressure_measurements.push({
                intraocular_pressure_iop: $scope.$ctrl.data._intraocular_pressure_iop,
                intraocular_pressure_device: $scope.$ctrl.data._intraocular_pressure_device
            });
            $scope.$ctrl.data._intraocular_pressure_iop = null;
            $scope.$ctrl.data._intraocular_pressure_device = null;
        }

        $scope.removePressure = function (index) {
            $scope.$ctrl.data.additional_pressure_measurements.splice(index, 1); 
        }
	},
]);
