app.component("invoiceItem", {
	templateUrl: "app/src/js/components/invoice/invoice.html",
	controller: "invoiceItemController",
	bindings: {
        invoice: "=",
        billingItems: "=",
    },
});

app.controller("invoiceItemController", [
	"$scope",
	function ($scope) {
        this.$onInit = function () {
            $scope.selectedInvoice = $scope.$ctrl.invoice;
        }

        $scope.getTaxRate = function(item_code) {
            if(!$scope.$ctrl.billingItems || $scope.$ctrl.billingItems.length === 0){
                return '';
            }
            const item = $scope.$ctrl.billingItems.find(t=>t.code === item_code);
            return `Tax rate: ${item.tax_rate}% GST on Income`;
        }

        $scope.calculateDiscount = function() {
            return $scope.selectedInvoice.line_items.reduce((acc, current) => acc + current.discount_rate * current.unit_amount * current.quantity * 0.01, 0);
        }
        
	},
]);