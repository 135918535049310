app.service("OperationNote", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,
			setData: function (data) {
				service.data = data;
			},
			setNoteHistory: function (data) {
				service.noteList = data;
			},
			setDefaultSetting: function (data) {
				service.setting = data;
			},
			data: {},
			setting: {},
			noteList: []
		};

		return service;
	},
]);
