app.component("patientGraphs", {
   templateUrl: "app/src/js/components/patientGraphs/patientGraphs.html",
   controller: "patientGraphsController",
   bindings: {
		data: "<",
	},
});

app.controller("patientGraphsController", [
   "$scope",
   "PatientAPI",
   "CurrentPatient",
   "$modal",
   function ($scope, PatientAPI, CurrentPatient, $modal) {

      function processAcuityData() {
			if ($scope.$ctrl.data && $scope.$ctrl.data.length > 0) {
				if ($scope.api_acuity) {
					let minY = Number.MAX_SAFE_INTEGER;
					let maxY = Number.MIN_SAFE_INTEGER;

					let minX = Number.MAX_SAFE_INTEGER;
					let maxX = Number.MIN_SAFE_INTEGER;

					$scope.data_acuity.forEach((group) => {
						group.values.forEach((t) => {
							if (t.y < minY) {
								minY = t.y;
							}
							if (t.y > maxY) {
								maxY = t.y;
							}
							if (t.x < minX) {
								minX = t.x;
							}
							if (t.x > maxX) {
								maxX = t.x;
							}
						});
					});

					$scope.$ctrl.data.forEach((t) => {
						const date_created = parseDate(t.date_created).getTime();

						if (date_created < minX) {
							minX = date_created;
						}
						if (date_created > maxX) {
							maxX = date_created;
						}

						$scope.data_acuity.push({
							values: [
								{
									x: date_created,
									y: minY - 1,
									xy: [`Cataract Sugery ${t.eye === "OD/OS" ? "OU" : t.eye}, Surgeon: ${t.surgeon} on ${t.date_created}`],
								},
								{
									x: date_created,
									y: maxY + 1,
									xy: [`Cataract Sugery ${t.eye === "OD/OS" ? "OU" : t.eye}, Surgeon: ${t.surgeon} on ${t.date_created}`],
								},
							],
							color: "#000000",
							classed: "dashed",
							key: `${t.operation_type} ${t.eye === "OD/OS" ? "OU" : t.eye} ${t.date_created}`,
						});
					});

					$scope.data_acuity.push({
						values: [
							{ x: minX - 1000000000, y: 0.3, xy: null },
							{ x: maxX + 1000000000, y: 0.3, xy: null },
						],
						key: "Driving Standard",
						color: "#000000",
						classed: "dashed",
					});

					$scope.api_acuity.updateWithData($scope.data_acuity);

					const disabled = $scope.data_acuity.map((t) => {
						if (t.key === "OD (LogMAR UCVA)" || t.key === "OS (LogMAR UCVA)" || t.key === "Driving Standard") {
							return 1;
						}
						return 0;
					});
					$scope.api_acuity.getScope().chart.dispatch.changeState({ disabled });
					$scope.api_acuity.updateWithOptions({
						chart: { ...$scope.options_acuity_small.chart, yDomain: [minY - 0.1, maxY + 0.1], xDomain: [minX, maxX] },
						title: $scope.options_acuity_small.title,
					});
					$scope.options_acuity_big.chart.yDomain = [minY - 0.1, maxY + 0.1];
					$scope.options_acuity_big.chart.xDomain = [minX, maxX];
				}
			} else if ($scope.$ctrl.data && $scope.$ctrl.data.length === 0) {
				if ($scope.api_acuity) {
					let minY = Number.MAX_SAFE_INTEGER;
					let maxY = Number.MIN_SAFE_INTEGER;

					let minX = Number.MAX_SAFE_INTEGER;
					let maxX = Number.MIN_SAFE_INTEGER;

					$scope.data_acuity.forEach((group) => {
						group.values.forEach((t) => {
							if (t.y < minY) {
								minY = t.y;
							}
							if (t.y > maxY) {
								maxY = t.y;
							}
							if (t.x < minX) {
								minX = t.x;
							}
							if (t.x > maxX) {
								maxX = t.x;
							}
						});
					});

					$scope.data_acuity.push({
						values: [
							{ x: minX - 1000000000, y: 0.3, xy: null },
							{ x: maxX + 1000000000, y: 0.3, xy: null },
						],
						key: "Driving Standard",
						color: "#000000",
						classed: "dashed",
					});

					$scope.api_acuity.updateWithData($scope.data_acuity);
					const disabled = $scope.data_acuity.map((t) => {
						if (t.key === "OD (LogMAR UCVA)" || t.key === "OS (LogMAR UCVA)" || t.key === "Driving Standard") {
							return 1;
						}
						return 0;
					});
					$scope.api_acuity.getScope().chart.dispatch.changeState({ disabled });
					$scope.api_acuity.updateWithOptions({
						chart: { ...$scope.options_acuity_small.chart, yDomain: [minY - 0.1, maxY + 0.1], xDomain: [minX, maxX] },
						title: $scope.options_acuity_small.title,
					});
					$scope.options_acuity_big.chart.yDomain = [minY - 0.1, maxY + 0.1];
					$scope.options_acuity_big.chart.xDomain = [minX, maxX];
				}
			}
		}
   
     this.$onInit = function () {
        this.PA = PatientAPI;
        this.CP = CurrentPatient;
        initOption();


        this.PA.getVisionChart(this.CP.patient.id, 'IOP').then(function (resp) {
           $scope.data_iop = $scope.parseData(resp.data[0], 'IOP');
        }, function (error) {
           if (error) {
              //TODO
           }
        });
        this.PA.getVisionChart(this.CP.patient.id, 'REFRACTION').then(function (resp) {
           $scope.data_refraction = $scope.parseData(resp.data[0], 'REFRACTION');
        }, function (error) {
           if (error) {
              //TODO
           }
        });
        this.PA.getVisionChart(this.CP.patient.id, 'VISUAL_ACUITY').then(function (resp) {
           $scope.data_acuity = $scope.parseData(resp.data[0], 'VISUAL_ACUITY');
           processAcuityData();
        }, function (error) {
           if (error) {
              //TODO
           }
        });

        this.PA.getVisionChart(this.CP.patient.id, 'AXIAL_LENGTH').then(function (resp) {
         $scope.data_axial_length = $scope.parseData(resp.data[0], 'AXIAL_LENGTH');
      }, function (error) {
         if (error) {
            //TODO
         }
      });

      this.PA.getVisionChart(this.CP.patient.id, 'K_VALUES').then(function (resp) {
         $scope.data_k_values = $scope.parseData(resp.data[0], 'K_VALUES');
      }, function (error) {
         if (error) {
            //TODO
         }
      });

      this.PA.getVisionChart(this.CP.patient.id, 'ASTIGMATISM').then(function (resp) {
         $scope.data_astigmatism = $scope.parseData(resp.data[0], 'ASTIGMATISM');
      }, function (error) {
         if (error) {
            //TODO
         }
      });
      

     }

     function getColor(index) {
        switch(index){
           case 0:
              return '#ff7f0eb3';
           case 1: 
              return '#2ca02cb3';
           case 2:
              return '#7777ffb3';
           case 3:
              return '#9117ffb3';
           default:
              return '#f0fc03b3';
        }
     }

      function getColor(chartName, chartDataName, chartDataType, index) {
         if (chartName === 'VISUAL_ACUITY') {
            if (chartDataType === 'OD' && chartDataName === 'LOG_MAR_BCVA') {
               return '#5266A2b3';
            } else if (chartDataType === 'OS' && chartDataName === 'LOG_MAR_BCVA') {
               return '#51C3DAb3'; 
            } else if (chartDataType === 'OD' && chartDataName === 'LOG_MAR_UCVA') {
               return '#BC5F95b3';
            } else if (chartDataType === 'OS' && chartDataName === 'LOG_MAR_UCVA') {
               return '#F694CBb3';
            }
         }else if(chartName === 'REFRACTION'){
            if (chartDataType === 'OD' && chartDataName === 'SPHERICAL_EQUIVALENT') {
               return '#5266A2b3';
            } else if (chartDataType === 'OS' && chartDataName === 'SPHERICAL_EQUIVALENT') {
               return '#51C3DAb3';
            } else if (chartDataType === 'OD' && chartDataName === 'CYLINDER') {
               return '#BC5F95b3';
            } else if (chartDataType === 'OS' && chartDataName === 'CYLINDER') {
               return '#F694CBb3';
            }
         }else if(['IOP','AXIAL_LENGTH','K_VALUES', 'ASTIGMATISM'].includes(chartName)){
            if (chartDataType === 'OD') {
               return '#5266A2b3';
            } else if (chartDataType === 'OS') {
               return '#51C3DAb3';
            }
         }
         return getColor(index);
      }

     function parseDate(dateStr) {
        const array = dateStr.split('/');
        const date = new Date();
        date.setUTCFullYear(+array[2] + 2000);
        date.setUTCMonth(+array[1] - 1)
        date.setUTCDate(+array[0]);
        return date;
     }

      function trimDataName(chart_data_name) {
         switch (chart_data_name) {
            case 'LOG_MAR_BCVA': return 'LogMAR BCVA';
            case 'LOG_MAR_UCVA': return 'LogMAR UCVA';
            case 'SPHERICAL_EQUIVALENT': return 'SPHERICAL EQUIVALENT';
            default: return chart_data_name;
         }
      }

      function generateValueName(type, xy) {
         if(type.includes('LogMAR UCVA')){
            return 'LogMAR UCVA';
         }else if(type.includes('LogMAR BCVA')){
            return 'LogMAR BCVA';
         }else if(type.includes('CYLINDER')){
            return 'Cylinder';
         }else if(type.includes('SPHERICAL EQUIVALENT')){
            return 'Sph eq';
         }else if(type.includes('IOP')){
            return 'IOP';
         }
         return 'Value';
      }

      function generateValue(type, xy, y) {
         if (type.includes('CYLINDER')) {
            return `${Number(+y).toFixed(2)}D x ${Number(+xy[2]).toFixed(2)}deg`;
         } else if (type.includes('SPHERICAL EQUIVALENT')) {
            return `${Number(+y).toFixed(2)}D`;
         } else if (type.includes('IOP')) {
            return `${Number(+y).toFixed(2)}mmHg`;
         }
         return null;
      }

      function transformXY(type, xy) {
         if (type.includes('CYLINDER') || type.includes('SPHERICAL EQUIVALENT')) {
            xy[3] = xy[3].charAt(0).toUpperCase() + xy[3].slice(1);
            xy.splice(2, 1);
         }else if(type.includes('IOP')){
            xy[0] = `Device: ${xy[0]}`;
         }
         return xy;
      }

      function getTypeName(item, dataName) {
         if (dataName === "VISUAL_ACUITY" || dataName === "REFRACTION") {
				return `${item.chart_data_type} (${trimDataName(item.chart_data_name)})`;
			} else if (dataName === "IOP") {
				return `${item.chart_data_type} (IOP)`;
			} else if (dataName === "K_VALUES") {
				const point = item.chart_data_points[0];
				if (point && point.xy[0].startsWith("K1")) {
					return `${item.chart_data_type} K1`;
				} else if (point && point.xy[0].startsWith("K2")) {
					return `${item.chart_data_type} K2`;
				}
			} else if (dataName === "ASTIGMATISM") {
				return `${item.chart_data_type} (${item.chart_data_name})`;
			}
         return item.chart_data_type;
      }

      function getX(value, dataName){
         if(dataName === 'AXIAL_LENGTH'){
            return Number(value);
         }
         return parseDate(value).getTime();
      }

      $scope.parseData = function (data, dataName) {
         const length = data.chart_data.length;
         const result = [];

         let minX = Number.MAX_SAFE_INTEGER;
         let maxX = Number.MIN_SAFE_INTEGER;

         let minY = Number.MAX_SAFE_INTEGER;
         let maxY = Number.MIN_SAFE_INTEGER;
      
         for (let i = 0; i < length; i++) {
            const type = getTypeName(data.chart_data[i], dataName);
            const array = []
            data.chart_data[i].chart_data_points.forEach(t => {
               if (t.x && t.y) {
                  const tempX = getX(t.x, dataName); // parseDate(t.x).getTime();
                  const tempY = +t.y;

                  if(tempX < minX){
                     minX = tempX;
                  }
                  if(tempX > maxX){
                     maxX = tempX;
                  }
                  
                  if(tempY < minY){
                     minY = tempY;
                  }
                  if(tempY > maxY){
                     maxY = tempY;
                  }
                  array.push({ value: generateValue(type, t.xy, t.y), x: tempX, y: tempY, xy: transformXY(type,t.xy), valueName: generateValueName(type, t.xy)});
               }
            });
            result.push({
               values: array,
               key: type,
               color: getColor(dataName, data.chart_data[i].chart_data_name, data.chart_data[i].chart_data_type, i),
               strokeWidth: 5,
               // classed: 'dashed'
            })
         }

         if (dataName === 'REFRACTION') {
            $scope.api_refraction.updateWithOptions({ chart: { ...$scope.options_refraction_small.chart, yDomain: [minY - 0.5, maxY + 0.5] }, title: $scope.options_refraction_small.title });
            $scope.options_refraction_big.chart.yDomain = [minY - 0.5, maxY + 0.5];
         }

         if (dataName === 'IOP') {
            $scope.api_iop.updateWithOptions({ chart: { ...$scope.options_iop_small.chart, yDomain: [minY - 0.5, maxY + 0.5] },title: $scope.options_iop_small.title });
            $scope.options_iop_big.chart.yDomain = [minY - 0.5, maxY + 0.5];
         }

         if(dataName === 'AXIAL_LENGTH'){
            $scope.api_axial_length.updateWithOptions({ chart: { ...$scope.options_axial_length_small.chart, yDomain: [minY - 0.5, maxY + 0.5] },title: $scope.options_axial_length_small.title });
            $scope.options_axial_length_big.chart.yDomain = [minY - 0.5, maxY + 0.5];
         }

         if(dataName === 'K_VALUES') {
            $scope.api_k_values.updateWithOptions({ chart: { ...$scope.options_k_values_small.chart, yDomain: [minY - 0.5, maxY + 0.5] },title: $scope.options_k_values_small.title });
            $scope.options_k_values_big.chart.yDomain = [minY - 0.5, maxY + 0.5];
         }

         if(dataName === 'ASTIGMATISM') {
            $scope.api_astigmatism.updateWithOptions({ chart: { ...$scope.options_astigmatism_small.chart, yDomain: [minY - 0.5, maxY + 0.5] },title: $scope.options_astigmatism_small.title });
            $scope.options_astigmatism_big.chart.yDomain = [minY - 0.5, maxY + 0.5];
         }

         return result;
      }

      $scope.showGraph = function (chart) {
         let title = '';
         if (chart === 'acuity') {
            $scope.api_acuity.refresh();
            $scope.chart_big_data = $scope.data_acuity;
            $scope.chart_option_big_data = $scope.options_acuity_big;
            title = 'Visual Acuity';
         } else if (chart === 'refraction') {
            $scope.api_refraction.refresh();
            $scope.chart_big_data = $scope.data_refraction;
            $scope.chart_option_big_data = $scope.options_refraction_big;
            title = 'Refraction';
         } else if (chart === 'axial_length') {
            $scope.api_axial_length.refresh();
            $scope.chart_big_data = $scope.data_axial_length;
            $scope.chart_option_big_data = $scope.options_axial_length_big;
            title = 'Axial Length';
         } else if(chart === 'k_values'){
            $scope.api_k_values.refresh();
            $scope.chart_big_data = $scope.data_k_values;
            $scope.chart_option_big_data = $scope.options_k_values_big;
            title = 'K Values';
         } else if(chart === 'astigmatism'){
            $scope.api_astigmatism.refresh();
            $scope.chart_big_data = $scope.data_astigmatism;
            $scope.chart_option_big_data = $scope.options_astigmatism_big;
            title = 'Astigmatism';
         } else {
            $scope.api_iop.refresh();
            $scope.chart_big_data = $scope.data_iop;
            $scope.chart_option_big_data = $scope.options_iop_big;
            title = 'IOP';
         }

         if ($scope.chartViewerModal) {
            $scope.chartViewerModal.hide();
         }
         $scope.chartViewerModal = $modal({
            scope: $scope,
            templateUrl: "app/src/views/templates/modal.chart-viewer.tpl.html",
            show: true,
            title: title,
            backdrop: true,
            keyboard: false,
         });
      }

      function initOption() {
         const options = {
            chart: {
                type: 'lineChart',
                disabled: true,
                showLegend: false,
                height: 250,
                width: 360,
                margin : {
                  //   top: 2,
                    right: 40,
                  //   bottom: 4,
                    left: 40,
                },
                x: function (d) { return d.x; },
                y: function (d) { return d.y; },
                // yDomain: [-0.1,1],
                useInteractiveGuideline: false,
                dispatch: {
                    stateChange: function (e) { console.log("stateChange"); },
                    changeState: function (e) { console.log("changeState"); },
                    tooltipShow: function (e) { console.log("tooltipShow"); },
                    tooltipHide: function (e) { console.log("tooltipHide"); },
                    elementClick: function(e)  { console.log("elementClick"); }
                },
                xAxis: {
                    axisLabel: '',
                    rotateLabels: 30,
                    tickFormat: function(d){
                        return d3.time.format('%m/%y')(new Date(d));
                    }
                },
                  yAxis: {
                     axisLabel: '',
                     tickFormat: function (d) {
                        return Number(d).toFixed(2); //d3.format('.02f')(d);
                     },
                     // axisLabelDistance: -10
                  },
                useInteractiveGuideline: false,
                tooltip: {
                    enabled: false,
                }
            }
        };
 
        $scope.options_big = {
          chart: {
              type: 'lineChart',
              showLegend: true,
              legendPosition: 'top',
              height: 450,
              margin : {
                  top: 40,
                  right: 40,
                  bottom: 40,
              },
              x: function (d) { return d.x; },
              y: function (d) { return d.y; },
              useInteractiveGuideline: true,
              dispatch: {
                  stateChange: function (e) { console.log("stateChange"); },
                  changeState: function (e) { console.log("changeState"); },
                  tooltipShow: function (e) { console.log("tooltipShow"); },
                  tooltipHide: function (e) { console.log("tooltipHide"); },
                  elementClick: function(e)  { console.log("elementClick"); },
              },
              xAxis: {
                  axisLabel: '',
                  rotateLabels: 30,
                  tickFormat: function(d){
                      return d3.time.format('%m/%y')(new Date(d));
                  }
              },
                yAxis: {
                   axisLabel: '',
                   tickFormat: function (d) {
                      return Number(d).toFixed(2); //d3.format('.02f')(d);
                   },
                   // axisLabelDistance: -10
                },
              useInteractiveGuideline: false,
              tooltip: {
                  contentGenerator: function (e) {
                      var series = e.series[0];
                      if (series.value === null) return;
                    if (!e.point.xy) {
                       return "<div></div>"
                    }
                    if (e.point.xy.length === 1) {
                       return `<div>${e.point.xy[0]}</div>`;
                    }
                    var rows =
                       "<tr>" +
                       "<td class='x-value'>" + e.point.xy[0] + "</td>" +
                       "</tr>" +
                       "<tr>" +
                       "<td class='x-value'>" + e.point.xy[1] + "</td>" +
                       "</tr>" +
                       "<tr>" +
                       "<td class='x-value'>" + e.point.xy[2] + "</td>" +
                       "</tr>" +
                       "<tr>" +
                       "<td class='x-value'>" + (e.point.xy[3] ? e.point.xy[3] : '') + "</td>" +
                       "</tr>" +
                       "<tr>" +
                       "<td class='key'>" + `${e.point.valueName}: ` + "</td>" +
                       "<td class='x-value'><strong>" + (e.point.value ? e.point.value : (series.value ? series.value.toFixed(2) : 0)) + "</strong></td>" +
                       "</tr>";
    
                      var header = 
                        "<thead>" + 
                          "<tr>" +
                            "<td class='legend-color-guide'><div style='background-color: " + series.color + ";'></div></td>" +
                            "<td class='key'><strong>" + series.key + "</strong></td>" +
                          "</tr>" + 
                        "</thead>";
                        
                      return "<table>" +
                          header +
                          "<tbody>" +
                          rows +
                          "</tbody>" +
                          "</table>";
                    } 
              },
              zoom: {
                  "enabled": true,
                  "useFixedDomain": false,
                  "useNiceScale": true,
                  "horizontalOff": false,
                  "verticalOff": true,
                  "unzoomEventType": "dblclick.zoom"
              },
              lines: {
                dispatch: {
                    elementClick: function(e){ console.log('click') },
                    elementMouseover: function(e){ console.log('mouseover') },
                    elementMouseout: function(e){ console.log('mouseout') },
                    renderEnd: function(e) {
                      //for each text
                      d3.selectAll(".nv-legend text")[0].forEach(function(d){
                        //get the data
                      //   var t= d3.select(d).data()[0];
                        //set the new data in the innerhtml
                      //   d3.select(d).html('ksjdfjs');
                        d3.select(d).style('text-decoration', 'underline');
                      });
                      
                      // const array = d3.selectAll(".nv-legend circle")[0];
                      // console.log(`${array.length}`)
                      // if(array.length > 0){
                      //    const d = array[array.length - 1];
                      //    d3.select(d).style('display', 'none');
                      // }
                      
                    }
                }
            },
        
            // legend events
            legend: {
                align: false,
                dispatch: {
                    legendClick: function(e) {console.log('legendClick')},
                    legendDblclick: function(e) {console.log('legendDblclick')},
                    legendMouseover: function(e) {console.log('legendMouseover')},
                    legendMouseout: function(e) {console.log('legendMouseout')},
                    stateChange: function(e) {console.log('stateChange')}
                }
            }
          }
      };

      $scope.options_acuity_big = angular.copy($scope.options_big);
      $scope.options_refraction_big = angular.copy($scope.options_big);
      $scope.options_iop_big = angular.copy($scope.options_big);
      $scope.options_axial_length_big =angular.copy($scope.options_big);
      $scope.options_k_values_big = angular.copy($scope.options_big);
      $scope.options_astigmatism_big = angular.copy($scope.options_big);

      $scope.options_axial_length_big.chart.xAxis= {
         axisLabel: '',
         rotateLabels: 30,
     },
      
      // $scope.options_acuity_big.chart.type = 'cumulativeLineChart';
      // $scope.options_acuity_big.chart.xDomain = [-1,-0.5,0.5,1];
      $scope.options_acuity_big.chart.zoom = {
         enabled: true,
         useFixedDomain: false,
         useNiceScale: true,
         horizontalOff: false,
         verticalOff: true,
         unzoomEventType: "dblclick.zoom"
     },


      $scope.options_iop_small = {
         ...options, title: {
            enable: true,
            text: 'IOP',
            className: 'small-title'
         }
      };

      $scope.options_refraction_small = {
         chart: {
            ...options.chart, callback:
               function (chart) {
                  chart.dispatch.changeState({ disabled: [0, 1,0,1] });
               }
         }, title: {
            enable: true,
            text: 'REFRACTION',
            className: 'small-title'
         }
      };
      $scope.options_acuity_small = {
         ...options,
         title: {
            enable: true,
            text: 'VISUAL ACUITY',
            className: 'small-title'
         }
      };

      $scope.options_axial_length_small = {
         chart: {
            ...options.chart,  xAxis: {
               axisLabel: '',
               rotateLabels: 30
           },
         }, title: {
            enable: true,
            text: 'AXIAL LENGTH',
            className: 'small-title'
         }
      };

      $scope.options_k_values_small = {
         ...options, title: {
            enable: true,
            text: 'K VALUES',
            className: 'small-title'
         }
      };

      $scope.options_astigmatism_small = {
         ...options, title: {
            enable: true,
            text: 'ASTIGMATISM',
            className: 'small-title'
         }
      };
      }
   }])
