app.component("preOpHistoryGlaucomaMedication", {
	templateUrl:
		"app/src/js/components/preOpHistory/preOpHistoryGlaucoma/preOpHistoryGlaucomaMedication/preOpHistoryGlaucomaMedication.html",
	controller: "preOpHistoryGlaucomaMedicationController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("preOpHistoryGlaucomaMedicationController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		this.$onInit = function () {
			this.PreOp = PreOp;
		};
	},
]);
