app.component("patientFileUploader", {
    templateUrl: "app/src/js/components/patientFileUploader/patientFileUploader.html",
    controller: "patientFileUploaderController",
    bindings: {
        filesCallback: "&",
    },
});

app.controller("patientFileUploaderController", [
    "$scope",
    "Session",
    "PatientAPI",
    "CurrentPatient",
    "$modal",
    "FileUploader",
    "ENV",
    "UploadFileAPI",
    "$filter",
    "$sce",
    "FILE_TYPES",
    function ($scope, Session, PatientAPI, CurrentPatient, $modal, FileUploader, ENV, UploadFileAPI,
        $filter, $sce, FILE_TYPES) {
        $scope.API = UploadFileAPI;
        $scope.uploadedFileList = [];
        $scope.queueSize = 0;

        $scope.IMAGE_PDF_OPTIONS = FILE_TYPES.image;
        $scope.WORD_OPTIONS = FILE_TYPES.word;
        $scope.VIDEO_OPTIONS = FILE_TYPES.video;

        
        $scope.newFileItem = undefined;
        $scope.fileOptions = [];

        $scope.fileType = null;
        $scope.onTypeChange = function () {

        }

        $scope.closeFileUploadErrorModal = function() {
            $scope.alertMessage = null; 
            $scope.errorModal.hide();
        }

        $scope.downloadFile = function (fileId,fileName) {
            $scope.API.isProcessing = true;
            $scope.API.downloadFile(CurrentPatient.patient.id, fileId).then(function (resp) {
                openFile(resp.data, fileName);
            }, function (error) {
                // TODO
            }).then(function(){
                $scope.API.isProcessing = false;
            });
        }

        $scope.deleteFileItem = null;

        $scope.deleteFileConfirm = function() {
            $scope.errorModal.hide();
            $scope.API.isProcessing = true;
            $scope.API.deleteFile(CurrentPatient.patient.id, $scope.deleteFileItem).then(function (resp) {
                // $scope.uploadedFileList = resp.data;
            }, function (error) {
                // TODO
            }).then(function(){
                $scope.API.isProcessing = false;
                $scope.deleteFileItem = null;
            });
        }

        $scope.clearFileDeleteConfirm = function(){
            $scope.deleteFileItem = null;
            $scope.errorModal.hide();
        }

        $scope.deleteFile = function (fileId) {
            if ($scope.deleteFileItem == null) {
                $scope.deleteFileItem = fileId;
                $scope.errorModal = $modal({
                    scope: $scope,
                    templateUrl: "app/src/views/templates/modal.file-upload-delete.tpl.html",
                    show: true,
                });
                return;
            }
          
        }

        function openFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData);
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

        var uploader = $scope.uploader = new FileUploader({
            queueLimit: 5,
            // url: ENV.API + `/api/files/patient/${CurrentPatient.patient.id}`,
			// headers: {
			// 	Authorization: "Bearer " + Session.token,
            //     "Content-Type": "multipart/form-data",
			// 	"X-PRACTICE-ID": `${Session.practice.id}`, 
			// },
            // formData: [{'fileName': 'test.png'},{'confidential': false},{'comment':'first file'}]
            //,timeout: 2000
        });
        // application/vnd.openxmlformats-officedocument.wordprocessingml.document
        const isFileTypeSupported = function (item) {
            if(item.type.includes('text/plain')){
                return true;
            }
            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|avi|mp4|quicktime|jpg|png|jpeg|bmp|gif|pdf|vnd.openxmlformats-officedocument.wordprocessingml.document|'.indexOf(type) !== -1;
        }

        const isFileTooBig = function (item) {
            return item.size > 1000*1000*200;
        }

        const isFileNameValid = function (item) {
            const regex = new RegExp(/^[a-zA-Z0-9 ()_.\-\,;'="+$%&@#!<>^?\[\]]+\.[A-Za-z0-9]{2,}$/gm);
            return regex.test(item.name);
        }

        $scope.isImageFile = function (item) {
            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type.toLowerCase()) !== -1;
        }

        $scope.isPdfFile = function (item) {
            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|pdf|'.indexOf(type.toLowerCase()) !== -1;
        }

        $scope.isWordFile = function (item) {
            if(item.type.includes('text/plain')){
                return true;
            }

            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1).toLowerCase() + '|';
            return '|vnd.openxmlformats-officedocument.wordprocessingml.document|'.indexOf(type) !== -1;
        }

        $scope.isVideoFile = function (item) {
            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
            return '|avi|mp4|quicktime|'.indexOf(type.toLowerCase()) !== -1;
        }

		 // FILTERS
      
        // a sync filter
        uploader.filters.push({
            name: 'fileFilter',
            fn: function(item /*{File|FileLikeObject}*/, options) {
                if(!isFileTooBig(item) && isFileNameValid(item)){
                    return isFileTypeSupported(item);
                }
                return false;
            }
        });

        $scope.closePdfViewer = function () {
			if($scope.pdfViewerModal){
				$scope.pdfViewerModal.hide();
			}
            if($scope.pdfUrl){
                URL.revokeObjectURL($scope.pdfUrl);
                $scope.pdfUrl = undefined;
            }
		};

        $scope.closeWordViewer = function () {
			if($scope.wordViewerModal){
				$scope.wordViewerModal.hide();
			}
            // if($scope.wordUrl){
            //     URL.revokeObjectURL($scope.wordUrl);
            // }
		}
        
        $scope.previewPDF = function (item) {
			$scope.pdfUrl = URL.createObjectURL(item);
			$scope.scroll = 0;
			$scope.pdfViewerModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.pdf-viewer.tpl.html",
				show: true,
				title: "DOCUMENT PREVIEW",
				backdrop: true,
				keyboard: false,
			});
		};

        function handleFileSelect(file) {
            if (file.type.includes('text/plain')) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    const content = document.querySelector(".content");
                    content.innerText = loadEvent.target.result;
                };
                reader.readAsText(file);
                return;
            }

            readFileInputEventAsArrayBuffer(file, function(arrayBuffer) {
                mammoth.convertToHtml({arrayBuffer: arrayBuffer})
                    .then(displayResult, function(error) {
                        console.error(error);
                    });
            });
        }
    
        function displayResult(result) {
            document.getElementById("output").innerHTML = result.value;
    
            var messageHtml = result.messages.map(function(message) {
                return '<li class="' + message.type + '">' + escapeHtml(message.message) + "</li>";
            }).join("");
    
            document.getElementById("messages").innerHTML = "<ul>" + messageHtml + "</ul>";
        }
    
        function readFileInputEventAsArrayBuffer(file, callback) {
            var reader = new FileReader();
    
            reader.onload = function(loadEvent) {
                var arrayBuffer = loadEvent.target.result;
                callback(arrayBuffer);
            };
    
            reader.readAsArrayBuffer(file);
        }
    
        function escapeHtml(value) {
            return value
                .replace(/&/g, '&amp;')
                .replace(/"/g, '&quot;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');
        }

        $scope.previewWord = function (item) {
            $scope.wordViewerModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.word-viewer.tpl.html",
                show: true,
                title: "DOCUMENT PREVIEW",
                backdrop: true,
                keyboard: false,
            });
			
            setTimeout(()=>{
                handleFileSelect(item);
            },500);
		};

        $scope.previewVideo = function (item) {
            $scope.videoUrl =  URL.createObjectURL(item); // 'https://drive.google.com/uc?id=1NmkSdk08ivJEIJ6NOTo6LqLVsw_PmlVU&export=download&authuser=0';
            $scope.config = {
                preload: "none",
                sources: [
                  {src: $scope.videoUrl, type: "video/mp4"},
                ],
                tracks: [],
                theme: {
                  url: "https://unpkg.com/videogular@2.1.2/dist/themes/default/videogular.css"
                },
                plugins: {
					poster: "https://www.cattrax.co.nz/app/build/img/cattrax_logo.png"
				}
              };
            $scope.videoViewerModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.video-viewer.tpl.html",
				show: true,
				title: "VIDEO PREVIEW",
				backdrop: true,
				keyboard: false,
			});
        }

        $scope.closeVideoViewer = function () {
            if($scope.videoViewerModal){
				$scope.videoViewerModal.hide();
			}
            if($scope.videoUrl){
                URL.revokeObjectURL($scope.videoUrl);
                $scope.videoUrl = undefined;
            }
        }

        // function sleep(ms) {
        //     return new Promise(resolve => setTimeout(resolve, ms));
        // }

        function sleep(seconds) {
            var e = new Date().getTime() + (seconds * 1000);
            while (new Date().getTime() <= e) { }
        }

        $scope.uploadAll = async function(){
            $scope.uploader.uploadAll();
            // $scope.queueSize = $scope.uploader.queue.length;
            // $scope.uploader.queue.forEach(async (item,index) => {
            //     await sleep(index*1000);
            //     item.upload();
            // });
        };

        // CALLBACKS

        $scope.$watch("uploader.queue.length", function(newValue, oldValue){
			if(newValue > 0){
				$scope.$ctrl.filesCallback({unsaved: true});
			}else{
				$scope.$ctrl.filesCallback({unsaved: false});
			}
		}, true);

        uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
            console.info('onWhenAddingFileFailed', item, filter, options);
            $scope.alertMessage = '';
            if ($scope.uploader.queue.length === 5) {
                $scope.alertMessage = 'Maximum queue size is 5';
            } else if(!isFileNameValid(item)) {
                $scope.alertMessage = 'File name must only contains alphanumeric, hyphen, underscore and dot character .';
            } 
            else {
                $scope.alertMessage = 'Maximum file size is 25MB.  Supported file formats include:';
            }
            $scope.errorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.file-upload-error.tpl.html",
                show: true,
                title: "File cannot be added",
            });
        };

        $scope.fileItem_fileType = null;

        uploader.onAfterAddingFile = function (fileItem) {
            $scope.newFileItem = fileItem;
            if ($scope.isImageFile(fileItem._file) || $scope.isPdfFile(fileItem._file)) {
                $scope.fileOptions = $scope.IMAGE_PDF_OPTIONS;
            } else if ($scope.isWordFile(fileItem._file)) {
                $scope.fileOptions = $scope.WORD_OPTIONS;
            } else if ($scope.isVideoFile(fileItem._file)) {
                $scope.fileOptions = $scope.VIDEO_OPTIONS;
            }
            fileItem.fileType = $scope.fileOptions[0].id;
            $scope.fileItem_fileType = {id: $scope.fileOptions[0].id, name: $scope.fileOptions[0].name};
            fileItem.security_level = "GLOBAL"; 
            fileItem.operative_eye = "OU";
            fileItem._confidential = false;
            fileItem.comment = '';
            fileItem.dateOfConsultation = $filter("date")(new Date(), "dd/MM/yyyy");
            fileItem.url = ENV.API + `/api/files/patient/${CurrentPatient.patient.id}`;
            fileItem.headers = {
                Authorization: "Bearer " + Session.token,
                "X-PRACTICE-ID": `${Session.practice.id}`,
            };
            // fileItem.removeAfterUpload = true;
            $scope.detailsModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.file-upload-details.tpl.html",
                controller: "fileDetailController",
                show: true,
                backdrop: "static",
                title: "File Details",
                resolve: {
                    newFileItem: () => $scope.newFileItem,
                    fileType: () => $scope.fileItem_fileType,
                    fileOptions: () => $scope.fileOptions,
                    roles: () => Session.user.roles,
                  }
            });
            // fileItem.remove();
            console.info('onAfterAddingFile', fileItem);
            document.getElementById("uploadCaptureInputFile").value = "";
            
            // setTimeout(()=>{
            //     $scope.uploader.addToQueue(fileItem.file);
            // },2000);
        };
        uploader.onAfterAddingAll = function(addedFileItems) {
            console.info('onAfterAddingAll', addedFileItems);
        };

        function convertDateOfConsultation(src) {
            const array = src.split('/');
            return `${array[2]}-${array[1]}-${array[0]}`;
        }

        function trimFileName(fileName) {
            return fileName;
            // BE supports brackets
            // return fileName.replaceAll('(','_').replaceAll(')','_').trim();
        }

        function transformDate(value) {
            // dd/MM/yyyy
            const array = value.split('/');
            return array[2] + '-' + array[1] + '-' + array[0];
        }

        uploader.onBeforeUploadItem = async function(item) {
            $scope.errorModal = null;
            // item.dateOfConsultation format is: dd/MM/yyyy, need to change to yyyy-MM-dd
            item.formData = [{ 'fileName': trimFileName(item._file.name)},
            { 'confidential': false },
            { 'comment': item.comment },
            { 'securityLevel': item.security_level},
            { 'eye': item.operative_eye },
            { 'fileType': item.fileType},
            { 'dateOfConsultation': convertDateOfConsultation(item.dateOfConsultation)},
            ];
            if (item.fileType === "REFERRAL") {
				const referralData = {
					referredToUserId: item.referredToUserId.toString(),
					referredToPracticeId: item.referredToPracticeId.toString(),
					dateOfReferralUTC: moment(transformDate(item.dateOfReferral)).utc().toISOString(),
                    referredByPractitionerId: item.practitioner,
                    referredByOrganisationId: item.org,
                    reason: item.referral_reason,
                    urgency: item.referral_urgency
				};
				item.formData.push({ referralData: JSON.stringify(referralData) });
			}
            console.info('onBeforeUploadItem', item);
        };
        uploader.onProgressItem = function(fileItem, progress) {
            console.info('onProgressItem', fileItem, progress);
        };
        uploader.onProgressAll = function(progress) {
            console.info('onProgressAll', progress);
        };
        uploader.onSuccessItem = function(fileItem, response, status, headers) {
            console.info('onSuccessItem', fileItem, response, status, headers);
        };
        uploader.onErrorItem = function(fileItem, response, status, headers) {
            console.info('onErrorItem', fileItem, response, status, headers);
        };
        uploader.onCancelItem = function(fileItem, response, status, headers) {
            console.info('onCancelItem', fileItem, response, status, headers);
        };
        uploader.onCompleteItem = function(fileItem, response, status, headers) {
            sleep(1.5);
            console.info('onCompleteItem', fileItem, response, status, headers);
        };

        uploader.onTimeoutItem = function(fileItem) {
            console.info('onTimeoutItem', fileItem);
        };

        uploader.onCompleteAll = function() {
            console.info('onCompleteAll');
            const failureItems = [];
            $scope.uploader.queue.forEach(t => {
                if (t.isError) {
                    failureItems.push(t);
                }
            });

            if(failureItems.length > 0) {
                $scope.errorModal = $modal({
                    scope: $scope,
                    templateUrl: "app/src/views/templates/modal.file-upload-error.tpl.html",
                    show: true,
                    title: "File cannot be uploaded",
                });
                $scope.uploader.queue = failureItems;
            }else{
                $scope.uploader.clearQueue();
                $scope.successModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.generic-success.tpl.html",
					show: true,
					title: "Success",
                    content: "Document uploaded successfully.",
                    onHide: function () {
                        $scope.$ctrl.filesCallback({close: true});
                    },
				});
            }
        };

        // console.info('uploader', uploader);
    }])
