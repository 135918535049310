app.component("taskLabelsSetting", {
	templateUrl: "app/src/views/templates/tasks-labels.tmpl.html",
	controller: "taskLabelsController",
});

app.controller('taskLabelsController',['$scope', '$mdDialog', 'TasksAPI', "Session","$modal",
function($scope, $mdDialog, TasksAPI,Session,$modal){
   $scope.myColor = '#FF0000';
   $scope.API = TasksAPI;
   $scope.task_labels = [];
   $scope.$mdDialog = $mdDialog;
   $scope.newType = {colour: 'FFFFFF', name: ''};
   $scope.Session = Session;

   // options - if a list is given then choose one of the items. The first item in the list will be the default
$scope.options = {
	hue: true,
	saturation: false,
	lightness: false,
	alpha: false,
	inline: true,
	format: "hex",
	round: false,
	inline: false,
	inputClass: "form-control",
	dynamicHue: false,
	dynamicLightness: false,
	dynamicSaturation: false,
	dynamicAlpha: false,
};

function querySetting() {
    $scope.API.queryTaskLabels()
    .then(
        function (resp) {
            $scope.task_labels = resp.data.filter(t=>t.practice_id === $scope.Session.practice.id);
        },
        function (error) {
            console.error(error);
        }
    );
}
querySetting();

$scope.errors = [];

$scope.renderErrorMessage = function (name='', task_label_id) {
    const val = name && $scope.task_labels.find(t=>t.name === name.trim() && t.task_label_id !== task_label_id);
    if(val && !$scope.errors.some(t=> t === task_label_id)){
        $scope.errors.push(task_label_id);
    }else if(!val){
        $scope.errors = $scope.errors.filter(t=> t !== task_label_id);
    }
    return {
        duplicate: val
    };
}

// api event handlers
$scope.eventApi = {
    onBlur:  function(api, color, $event) {
        const labelId = api.getElement()[0].id;
        if(labelId){
            updateLabel(labelId)
        }
        
    },
};

function updateLabel (labelId) {
    $scope.API.updateTaskLabel(labelId,$scope.task_labels.find(t=>t.task_label_id === labelId)).then(
        function (resp) {
            // querySetting();
            // $scope.$mdDialog.hide();
        },
        function (error) {
            console.error(error);
            $scope.serverErrorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                show: true,
                title: "CatTrax has encountered an error",
            });
        }
    )
}

$scope.addSetting = function(){
    $scope.API.addTaskLabel($scope.newType)
    .then(
        function (resp) {
            $scope.newType = {colour: 'FFFFFF', name: ''};
            querySetting();
        },
        function (error) {
            console.error(error);
            $scope.serverErrorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                show: true,
                title: "CatTrax has encountered an error",
            });
        }
    );
}


}]);