var app = angular.module("app", [
	"ui.router",
	"angularSpinner",
	"mgcrea.ngStrap",
	"ngAnimate",
	"google.places",
	"base64",
	"LocalStorageModule",
	"jcs-autoValidate",
	"ui.bootstrap",
	"rzSlider",
	"ui.select",
	'ngSanitize',
	"pdf",
	"ngTable",
	"angularjs-dropdown-multiselect",
	"angularjsToast",
	"ja.qr",
	"nvd3",
	"720kb.datepicker",
	"angularFileUpload",
	"com.2fdevs.videogular",
	"com.2fdevs.videogular.plugins.controls",
	"com.2fdevs.videogular.plugins.overlayplay",
	"com.2fdevs.videogular.plugins.poster",
	"com.2fdevs.videogular.plugins.buffering",
	"ui.calendar",
	'ngMaterial',
	'ngMessages',
	'color.picker',
	'ngCookies',
	'signature',
	'xeditable',
	'ngMdBadge',
	'lvl.directives.dragdrop',
	'summernote'
]);

app.config([
	"$locationProvider",
	function ($locationProvider) {
		$locationProvider.hashPrefix("");
	},
]);

app.config(function (localStorageServiceProvider) {
	localStorageServiceProvider.setPrefix("cattrax");
});

app.config([
	"usSpinnerConfigProvider",
	function (usSpinnerConfigProvider) {
		usSpinnerConfigProvider.setDefaults({
			lines: 13,
			length: 12,
			width: 7,
			radius: 25,
			scale: 2,
			corners: 0.7,
			color: "#2A307F",
			opacity: 0,
			rotate: 0,
			direction: 1,
			speed: 1,
			trail: 70,
			fps: 20,
			zIndex: 2e9,
			className: "spinner",
			top: "50%",
			left: "50%",
			shadow: false,
			hwaccel: true,
			position: "absolute",
		});
	},
]);

app.config(function ($httpProvider) {
	$httpProvider.interceptors.push([
		"$injector",
		function ($injector) {
			return $injector.get("AuthInterceptor");
		},
	]);
});

app.config(["toastProvider",function(toastProvider){
	toastProvider.configure({
		maxToast: 1,
		timeout: 5 * 1000,
		containerClass: 'toast-container',
		defaultToastClass: 'alert-info',
		dismissible: true,
		insertFromTop: true,
		position: 'center'
	  });
}]);

app.factory("AuthToken", [
	"$http",
	"$q",
	"ENV",
	"Session",
	function ($http, $q,ENV,Session) {
		var authToken = {};
		//gets the token and sets it on the windows session storage
		authToken.getToken = function (refresh_token) {
			var deferred = $q.defer();
			/**	your app's route to get a token,
			 *	note that the user must be logged in,
			 *	or must send their credentials in
			 *	this request
			 */

			 $http({
				method: "POST",
				url: ENV.API + "/api/identity/refresh_token",
				data: {
					grant_type: "refresh_token",
					refresh_token,
				},
			}).then(
				function (resp) {
					Session.create(resp.data.access_token, resp.data.expires_in, resp.headers('x-user-id'), resp.data.idp_access_token, resp.data.refresh_token);
					deferred.resolve(resp.data.access_token);
				},
				function (err) {
					Session.destroy();
					deferred.reject();
				}
			)
			return deferred.promise;
		};
		return authToken;
	},
]);

app.factory("AuthInterceptor", function ($rootScope, $q, $injector, Session) {
	return {
		responseError: function (resp) {
			// '/api/identity/authenticate'
			// '/api/identity/authenticate/mfa/login'
			// /api/identity/authenticate/mfa/init
			// /api/identity/authenticate/mfa/verify
			// /api/identity/refresh_token
			// /api/billing/
			if(resp.config.url.includes('/api/identity/')){
				return $q.reject(resp);
			}
			if(!Session.practice.practiceFeatures.billingFeature && resp.config.url.includes('/api/billing/')){
				return $q.reject(resp);
			}
			const userObj = Session;
			if (resp.status === 401 && userObj && userObj.refresh_token) {
				/**
				if (window.location.hash !== "#/login") {
					window.location.href = window.location.origin;
				}  */
				var $http = $injector.get("$http"),
					deferred = $q.defer(),
					authToken = $injector.get("AuthToken");

				authToken.getToken(userObj.refresh_token).then(function (token) {
;					//this repeats the request with the original parameters
					resp.config.headers = resp.config.headers || {};
					resp.config.headers.Authorization = "Bearer " + token;
					return deferred.resolve($http(resp.config));
				},function(){
					// error
					if (window.location.hash !== "#/login") {
						window.location.href = window.location.origin;
					}
					return deferred.reject(resp);
					
				});
				return deferred.promise;
			}
			//TODO
			if (resp.status === 500 && resp.config && resp.config.url && resp.config.url.indexOf("operation_note/settings/") !== -1) {
				console.log(`500 server error ${JSON.stringify(resp)}`);

				var $modal = $injector.get("$modal");
				$rootScope.serverErrorModal = $modal({
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}

			return $q.reject(resp);
		},
	};
});

// This adds a ?v=xxxxxxxxxx param to a request for any file within the app folder.
// This will not effect any files the are called from a script tag in the index.html.
// The VERSION constant is defined in constants.js and should be changed before each deployment
//  to specficially stop angular from caching template html files, such as modals.
app.factory("preventTemplateCache", function (VERSION) {
	return {
		request: function (config) {
			if (config.url.indexOf("app/") !== -1) {
				config.url = config.url + "?v=" + VERSION;
			}
			return config;
		},
	};
}).config(function ($httpProvider) {
	$httpProvider.interceptors.push("preventTemplateCache");
});

app.run(function ($rootScope) {
	$rootScope.$on("$stateChangeError", console.log.bind(console));

	$rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
		$(".modal").modal("hide");
	});
	$rootScope.$on("$stateChangeSuccess", function () {
		$("html,body").animate({ scrollTop: 0 }, 800);
	});
});

app.run(function (defaultErrorMessageResolver) {
	defaultErrorMessageResolver.getErrorMessages().then(function (errorMessages) {
		errorMessages["badPassword"] =
			"Password must contain at least one uppercase letter, one lowercase letter, 1 number and be at least 8 characters long.";
		errorMessages["badPasswordMatch"] = "Passwords must match";
		errorMessages["badPhoneNumber"] = "No spaces, no dashes. Valid example: 073091122";
		errorMessages["badMobileNumber"] = "No spaces, no dashes. Valid example: 0215551234";
		errorMessages["invalidNHI"] = "Invalid format of NHI number";
		errorMessages["badNHI"] = "Invalid NHI number";
		errorMessages["required"] = "Required field";
		errorMessages["step"] = "Please use the arrows to round to the nearest accepted unit";
		errorMessages["parse"] = "Not a valid address format";
		errorMessages["invalidName"] = "Only letters, hyphen (-) and/or apostrophe (') can be used for names";
		errorMessages['wrongMFA'] = 'Invalid 2FA code. Please check your authenticator app and try again.';
		errorMessages['editable'] = 'Please select one option';
		errorMessages['badDate'] = 'Not a valid date format';
	});
});

// app.run([
// 	"localStorageService",
// 	"Session",
// 	"AuthService",
// 	"$state",
// 	"UserService",
// 	function (localStorageService, Session, AuthService, $state, UserService) {
// 		var userObj = localStorageService.get("userObj");
        
// 		// On app.run check localstorage to see if userObj exists, if it does check the validity of the token and create local session
// 		if (localStorageService.get("userObj") !== null) {
// 					} else {
// 			$state.go("login");
// 		}
// 	},
// ]);

app.run(function (AuthService, Session, $rootScope, $state) {
	$rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
		if (toState.name === "login" || toState.name === "reset-password" || toState.name === 'patient-verification') {
		} else if (!AuthService.isAuthenticated()) {
			event.preventDefault();
			$state.go("login");
		}
	});
});

app.controller("applicationController", [
	"$scope",
	"$sce",
	"ENV",
	"USER_ROLES",
	"AuthService",
	function ($scope, $sce, ENV, USER_ROLES, AuthService) {
		$scope.currentUser = null;
		$scope.userRoles = USER_ROLES;
		$scope.isAuthorized = AuthService.isAuthorized;

		$scope.setCurrentUser = function (user) {
			$scope.currentUser = user;
		};

		$scope.html =
			'(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start": new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","' +
			ENV.TAG +
			'");';
		$scope.googleTagManager = $sce.trustAsHtml($scope.html);
	},
]);

app.controller("patientVerificationController", [
	"$scope",
	"PatientAPI",
	"$stateParams",
	"$state",
	"$modal",
	function ($scope, PatientAPI, $stateParams, $state,$modal) {
		$scope.page = "login";
		$scope.PatientAPI = PatientAPI;
		$scope.PatientAPI.isProcessing = true;
		$scope.PatientAPI.patientVerification($stateParams.token).then(
			function () {
				$scope.patientVerificationModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.patient-verification.tpl.html",
					show: true,
					title: "Verify and Consent",
					keyboard: false,
					backdrop: "static",
				});
			},
			function () {
				$scope.invalidTokenString = 'This link to verify email is invalid or has expired.'
				$scope.invalidTokenModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.invalid-token.tpl.html",
					show: true,
					title: "Expired link",
					keyboard: false,
					backdrop: "static",
					onHide: function () {
						$state.go("login", {}, { inherit: false });
					},
				}); 
			}
		).then(function(){
			$scope.PatientAPI.isProcessing = false;
		});

		$scope.verify = function(dob,research,ml){
			$scope.PatientAPI.isProcessing = true;
			$scope.PatientAPI.patientConsent($stateParams.token,dob,research,ml).then(function(){
				$scope.patientVerificationModal.hide();
				$state.go("login", {}, { inherit: false });
			},function(){
				$scope.PatientAPI.patientVerification($stateParams.token).then(function(){
					//keep form
					$scope.patientVerificationModal.hide();
					$scope.patientVerificationModal.show();
				},function(error){
					$scope.patientVerificationModal.hide();
					$scope.invalidTokenString = 'This link to verify email is invalid or has expired.'
					$scope.invalidTokenModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.invalid-token.tpl.html",
						show: true,
						title: "Expired link",
						keyboard: false,
						backdrop: "static",
						onHide: function () {
							$state.go("login", {}, { inherit: false });
						},
					});
				})
			}).then(function(){
				$scope.PatientAPI.isProcessing = false;
			})
		}
	},
]);

app.controller("resetController", [
	"$scope",
	"$modal",
	"$state",
	"$stateParams",
	"AuthService",
	"$base64",
	function ($scope, $modal, $state, $stateParams, AuthService, $base64) {
		$scope.page = "login";
		$scope.base64 = $base64;
		$scope.AS = AuthService;

		$scope.AS.validatePasswordToken($stateParams.token).then(
			function () {
				// When token is valid show reset modal and prompt for new password...
				$scope.resetPasswordModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.confirm-password-change.tpl.html",
					show: true,
					title: "Password Reset",
					keyboard: false,
					backdrop: "static",
				});
			},
			function () {
				// When token is absent or invalid...
				$scope.invalidTokenModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.invalid-token.tpl.html",
					show: true,
					title: "Expired link",
					keyboard: false,
					backdrop: "static",
					onHide: function () {
						$state.go("login", {}, { inherit: false });
					},
				});
			}
		);

		$scope.passwordChange = function (password) {
			$scope.AS.resetPassword($stateParams.token, password).then(
				function () {
					// show success message
					$scope.resetPasswordModal.hide();
					$scope.successPwChangeModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.success-pw-change.tpl.html",
						show: true,
						title: "Password Reset",
						keyboard: false,
						backdrop: "static",
						onHide: function () {
							$state.go("login", {}, { inherit: false });
						},
					});
				},
				function () {
					// show failure message
					$scope.serverErrorModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
						show: true,
						title: "CatTrax has encountered an error",
					});
				}
			);
		};
	},
]);

app.filter("titlecase", function () {
	return function (input) {
		if (input === undefined) {
			return undefined;
		}

		var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

		input = input.toLowerCase();
		return input.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function (match, index, title) {
			if (
				index > 0 &&
				index + match.length !== title.length &&
				match.search(smallWords) > -1 &&
				title.charAt(index - 2) !== ":" &&
				(title.charAt(index + match.length) !== "-" || title.charAt(index - 1) === "-") &&
				title.charAt(index - 1).search(/[^\s-]/) < 0
			) {
				return match.toLowerCase();
			}

			if (match.substr(1).search(/[A-Z]|\../) > -1) {
				return match;
			}

			return match.charAt(0).toUpperCase() + match.substr(1);
		});
	};
});

app.filter("createDateFromString", function () {
	return function (input) {
		var myArray = [];
		var dateArray = [];
		myArray = input.split(" ")[0].split("-");

		angular.forEach(myArray, function (num, idx) {
			dateArray[idx] = parseInt(num);
		});
		return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
	};
});

app.directive("capitalize", function () {
	return {
		require: "ngModel",
		link: function (scope, element, attrs, modelCtrl) {
			var capitalize = function (inputValue) {
				if (inputValue == undefined) inputValue = "";
				var capitalized = inputValue.toUpperCase();
				if (capitalized !== inputValue) {
					var selection = element[0].selectionStart;
					modelCtrl.$setViewValue(capitalized);
					modelCtrl.$render();
					element[0].selectionStart = selection;
					element[0].selectionEnd = selection;
				}
				return capitalized;
			};
			modelCtrl.$parsers.push(capitalize);
			capitalize(scope[attrs.ngModel]);
		},
	};
});

app.directive("formatDate", function () {
	return {
		require: "ngModel",
		link: function (scope, elem, attr, modelCtrl) {
			modelCtrl.$parsers.push(function (modelValue) {
				if (modelValue) {
					return modelValue.toISOString().slice(0, 10);
				} else {
					return null;
				}
			});
			modelCtrl.$formatters.push(function (modelValue) {
				if (modelValue) {
					return new Date(modelValue);
				} else {
					return null;
				}
			});
		},
	};
});

app.filter("reactionpretty", function () {
	return function (text) {
		if (text === "RASH") {
			return "Rash";
		}
		if (text === "GI_UPSET") {
			return "GI upset";
		}
		if (text === "ANAPHYLAXIS") {
			return "Anaphylaxis";
		}
		return text;
	};
});

app.filter('reverse', function() {
	return function(items) {
	  return items.slice().reverse();
	};
  });

app.directive('slideToggle', function () {
	return {
		restrict: 'A',
		scope: {
			isOpen: "=slideToggle"
		},
		link: function (scope, element, attr) {
			var slideDuration = parseInt(attr.slideToggleDuration, 10) || 200;
			scope.$watch('isOpen', function (newVal, oldVal) {
				if (newVal !== oldVal) {
					element.stop().fadeToggle(slideDuration);
				}
			});
		}
	};
});

app.directive("disableRightClick", function () {
	return {
		restrict: 'A',
		link: function (scope, element, attr) {
			element.bind('contextmenu', function (e) {
				e.preventDefault();
			})
		},
	};
});

app.directive('ngBrowserVisibility', ['$window', function ($window) {
	function isMobileDevice(){
		const userAgent = JSON.stringify($window.navigator.userAgent);
		if (userAgent.indexOf('iPhone OS') !== -1 || userAgent.indexOf('Apple-iPhone') !== -1) {
			return true;
		} else if (userAgent.indexOf('Android') !== -1) {
			return true;
		} 
		return false;
	}
	return {
		restrict: 'A',
		link: function (scope, element, attributes) {
			var params = scope.$eval(attributes.ngBrowserVisibility);
			if(params.isMobileVisible === 'false' && isMobileDevice()){
				element.hide();
				return;
			}else if(params.isDesktopVisible === 'false' && !isMobileDevice()){
				element.hide();
				return;
			}
		}
	}
}]);

app.run(function(editableOptions) {
	editableOptions.theme = 'bs3';
  });

app.directive('ngThumb', ['$window', '$injector',function($window, $injector) {
	var helper = {
		support: !!($window.FileReader && $window.CanvasRenderingContext2D),
		isFile: function(item) {
			return angular.isObject(item) && item instanceof $window.File;
		},
		isImage: function(file) {
			var type =  '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
			return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
		}
	};

	return {
		restrict: 'A',
		template: '<canvas/>',
		link: function(scope, element, attributes) {
			if (!helper.support) return;

			var params = scope.$eval(attributes.ngThumb);

			if (!helper.isFile(params.file)) return;
			if (!helper.isImage(params.file)) return;

			var canvas = element.find('canvas');
			var reader = new FileReader();

			reader.onload = onLoadFile;
			reader.readAsDataURL(params.file);
			
			function onLoadFile(event) {
				var img = new Image();
				img.onload = onLoadImage;
				img.src = event.target.result;
			// 	scope.pdfUrl = URL.createObjectURL(params.file);
			// 	scope.scroll = 0;
			// scope.loading = 'loading';
			// var $modal = $injector.get("$modal");
		    // $modal({
			// 	scope: scope,
			// 	templateUrl: "app/src/views/templates/modal.pdf-viewer.tpl.html",
			// 	show: true,
			// 	title: "DOCUMENT PREVIEW",
			// 	backdrop: true,
			// 	keyboard: false,
			// });
			}

			function onLoadImage() {
				var width = params.width || this.width / this.height * params.height;
				var height = params.height || this.height / this.width * params.width;
				canvas.attr({ width: width, height: height });
				canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
			}
		}
	};
}]);