app.controller("invoiceEditController", [
	"$scope",
	"accounts",
	"CurrentPatient",
	"BillingAPI",
	"$modal",
	"$filter",
	"REFERENCE_TYPE",
	"$mdDialog",
	function ($scope, accounts, CurrentPatient, BillingAPI, $modal, $filter, REFERENCE_TYPE,$mdDialog) {
		this.$onInit = function () {
			$scope.REFERENCE_TYPE = REFERENCE_TYPE;
			$scope.CP = CurrentPatient;
			$scope.$mdDialog = $mdDialog;
			$scope.BillingAPI = BillingAPI;
			$scope.billingItems = [];
			$scope.invoices = [];
			$scope.accounts = accounts;
			$scope.showEdit = false;
			$scope.showPay = false;
			$scope.viewOnly = false;
			$scope.selectedInvoice = null;
			$scope.tempForm = { item: null, unit: 1, discount: 0 };
			$scope.today = $filter("date")(new Date(), "dd-MMMM-yyyy");
			fetchInvoices();
			fetchBillingItems();
		};

		$scope.popoverPreview = {
			content: "View",
		};

		$scope.popoverEdit = {
			content: "Edit",
		};

		$scope.popoverSend = (email) => ({
			content: email
				? "Finalise and send invoice"
				: "Finalise and download invoice. This patient does not have an email address saved. To send an invoice to the patient you must add an email address for this patient before sending",
		});

		$scope.popoverCancel = {
			content: "Delete invoice",
		};

		$scope.popoverPay = {
			content: "Finalise and take payment",
		};

		$scope.popoverDownload = {
			content: "Finalise and download invoice",
		};

		$scope.back = function () {
			$scope.showEdit = false;
			$scope.showPay = false;
			$scope.selectedInvoice = null;
		};

		$scope.editInvoice = function (invoice,invoiceId) {
			if(!$scope.isEditable(invoice)){
				return;
			}
			$scope.showEdit = true;
			$scope.showPay = false;
			setTimeout(() => {
				const invoice = $scope.invoices.find((t) => t.invoice_id === invoiceId);
				if (invoice) {
					$scope.selectedInvoice = angular.copy(invoice);
					$scope.selectedInvoice.date = $filter("date")(new Date($scope.selectedInvoice.date), "dd-MMM-yyyy");
					$scope.selectedInvoice.due_date = $filter("date")(new Date($scope.selectedInvoice.due_date), "dd-MMM-yyyy");
				}
				$scope.$apply();
			}, 100);
		};

		function _sendInvoice(invoice) {
			const invoiceId = invoice.invoice_id;
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.sendInvoice($scope.CP.patient.id, invoiceId)
				.then(
					function (resp) {
						fetchInvoices();
					},
					function (error) {
						console.error(error);
						showErrorDialog();
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
					if(!$scope.CP.patient.email){
						_downloadInvoice(invoiceId);
					}
				});
		}

		$scope.sendInvoice = function (status, invoice) {
			if(!$scope.isSendable(status)){
				return;
			}
			if(!$scope.CP.patient.email){
				$scope.negativeButton = 'Enter Email';
				$scope.invoiceToBeSent = invoice;
				$scope.invoiceToBeCancelled = null;
				$scope.generalModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.general.tpl.html",
					show: true,
					title: "Send invoice",
					content: 'There is no email address saved for this patient. If you finalise the invoice, it will not be sent. You can download and print the invoice.',
					backdrop: true,
					keyboard: false,
				});
				return;
			}
		    _sendInvoice(invoice);
		};

		$scope.isCancelDisabled = function (invoice) {
			if (invoice.status === "DRAFT") {
				return false;
			} else if (invoice.status === "SENT" && invoice.payments.length === 0) {
				return false;
			}
			return true;
		};

		function _cancelInvoice (invoice) {
			const invoiceId = invoice.invoice_id;
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.cancelInvoice($scope.CP.patient.id, invoiceId)
				.then(
					function (resp) {
						fetchInvoices();
					},
					function (error) {
						console.error(error);
						showErrorDialog();
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
		}

		$scope.onGeneralClick = function () {
			if($scope.generalModal){
				$scope.generalModal.hide();
				$scope.negativeButton = null;
			}
			if($scope.invoiceToBeCancelled){
				_cancelInvoice(angular.copy($scope.invoiceToBeCancelled));
				$scope.invoiceToBeCancelled = null;
			}else if($scope.invoiceToBeSent){
               _sendInvoice(angular.copy($scope.invoiceToBeSent));
			   $scope.invoiceToBeSent = null;
			}
		}

		$scope.onNegativeButtonClick = function () {
			if($scope.generalModal){
				$scope.generalModal.hide();
				$scope.negativeButton = null;
				$scope.invoiceToBeSent = null;
				angular.element(document.querySelector("#enterEmailPopover")).trigger("click");
			}
		}

		$scope.cancelInvoice = function (invoice) {
			if ($scope.isCancelDisabled(invoice)) {
				return;
			}
            $scope.invoiceToBeCancelled = invoice;
			$scope.negativeButton = null;
			$scope.invoiceToBeSent = null;
			$scope.generalModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.general.tpl.html",
				show: true,
				title: "Delete invoice",
				content: 'Are you sure you want to delete this invoice? It can’t be undone.',
				backdrop: true,
				keyboard: false,
			});
		};

		$scope.deleteItem = function (index) {
			$scope.selectedInvoice.line_items.splice(index, 1);
		};

		$scope.addItem = function () {
			const added = $scope.tempForm.item;
			const line_amount = $scope.tempForm.unit * added.unit_price * (100 - $scope.tempForm.discount) * 0.01;
			const discount_amount = $scope.tempForm.unit * added.unit_price * $scope.tempForm.discount * 0.01;
			const tax_amount = line_amount * added.tax_rate * 0.01;
			$scope.selectedInvoice.line_items.push({
				description: added.description,
				quantity: $scope.tempForm.unit,
				unit_amount: added.unit_price,
				item_code: added.code,
				tax_type: added.tax_type,
				tax_amount,
				line_amount,
				account_code: added.account_code,
				discount_rate: $scope.tempForm.discount,
				discount_amount,
			});
			$scope.tempForm = { item: null, unit: 1, discount: 0 };
		};

		$scope.getItemPrice = function (item_code) {
			const item = $scope.billingItems.find((t) => t.code === item_code);
			return item.unit_price;
		};

		$scope.getTaxRate = function (item_code) {
			const item = $scope.billingItems.find((t) => t.code === item_code);
			return item.tax_rate;
		};

		$scope.updateValue = function (item) {
			const unit_price = item.unit_amount;
			item.line_amount = item.quantity * unit_price * (100 - item.discount_rate) * 0.01;
		};

		$scope.resetInvoice = function () {
			const invoice = $scope.invoices.find((t) => t.invoice_id === $scope.selectedInvoice.invoice_id);
			if (invoice) {
				$scope.selectedInvoice = angular.copy(invoice);
				$scope.selectedInvoice.date = $filter("date")(new Date($scope.selectedInvoice.date), "dd-MMM-yyyy");
				$scope.selectedInvoice.due_date = $filter("date")(new Date($scope.selectedInvoice.due_date), "dd-MMM-yyyy");
			}
		};
		$scope.part_payment = { value: 0, account: null, reference_type: null };
		$scope.isPaymentValid = () => {
			if(!$scope.selectedInvoice){
				return false;
			}
			return $scope.part_payment.value > 0 &&
			$scope.part_payment.value <= $scope.selectedInvoice.amount_due &&
			$scope.part_payment.account &&
			$scope.part_payment.reference_type;
		}
			

		$scope.updatePaidAmount = function () {
			if ($scope.part_payment.value > 0 && $scope.part_payment.account && $scope.part_payment.reference_type) {
				$scope.BillingAPI.isProcessing = true;
				const body = {
					amount: $scope.part_payment.value,
					account_id: $scope.part_payment.account,
					reference_type: $scope.part_payment.reference_type,
					date: $filter("date")(new Date(), "yyyy-MM-dd"),
				};
				$scope.BillingAPI.updatePayment($scope.CP.patient.id, $scope.selectedInvoice.invoice_id, body).then(
					function (resp) {
						fetchInvoices();
					},
					function (error) {
						console.error(error);
						showErrorDialog();
						$scope.BillingAPI.isProcessing = false;
					}
				);
			}
		};

		$scope.updateInvoice = function () {
			const body = {
				line_items: $scope.selectedInvoice.line_items,
				invoice_id: $scope.selectedInvoice.invoice_id,
				reference: $scope.selectedInvoice.reference,
				due_date: $filter("date")(new Date($scope.selectedInvoice.due_date), "yyyy-MM-dd"),
				date: $filter("date")(new Date($scope.selectedInvoice.date), "yyyy-MM-dd"),
			};
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.updateInvoice($scope.CP.patient.id, body).then(
				function (resp) {
					// updatePaidAmount();
					fetchInvoices();
				},
				function (error) {
					console.error(error);
					showErrorDialog();
					$scope.BillingAPI.isProcessing = false;
				}
			);
		};

		function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}

		function fetchInvoices() {
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.getInvoices($scope.CP.patient.id)
				.then(
					function (resp) {
						const list = resp.data.sort(function compareFn(a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime() });
						$scope.invoices = angular.copy(list);
					},
					function (error) {
						console.error(error);
						showErrorDialog();
					}
				)
				.then(function () {
					$scope.back();
					$scope.BillingAPI.isProcessing = false;
				});
		}

		function fetchBillingItems() {
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.fetchBillingItems()
				.then(
					function (resp) {
						$scope.billingItems = angular.copy(resp.data);
					},
					function (error) {
						console.error(error);
						showErrorDialog();
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
		}

		$scope.filterByItem = function (typedValue) {
			return $scope.billingItems.filter(
				(t) =>
					t.name.toLowerCase().indexOf(typedValue.toLowerCase()) !== -1 ||
					t.code.toLowerCase().indexOf(typedValue.toLowerCase()) !== -1
			);
		};

		$scope.payInvoice = function (invoiceId) {
			$scope.showEdit = true;
			$scope.showPay = true;
			$scope.viewOnly = false;
			setTimeout(() => {
				const invoice = $scope.invoices.find((t) => t.invoice_id === invoiceId);
				$scope.part_payment = { value: 0, account: null, reference_type: null };
				if (invoice) {
					$scope.selectedInvoice = angular.copy(invoice);
					$scope.selectedInvoice.date = $filter("date")(new Date($scope.selectedInvoice.date), "dd-MMM-yyyy");
					$scope.selectedInvoice.due_date = $filter("date")(new Date($scope.selectedInvoice.due_date), "dd-MMM-yyyy");
				}
				$scope.$apply();
			}, 100);
		};

		$scope.viewInvoice = function (invoiceId) {
			$scope.showEdit = true;
			$scope.showPay = true;
			$scope.viewOnly = true;
			setTimeout(() => {
				const invoice = $scope.invoices.find((t) => t.invoice_id === invoiceId);
				$scope.part_payment = { value: 0, account: null };
				if (invoice) {
					$scope.selectedInvoice = angular.copy(invoice);
					$scope.selectedInvoice.date = $filter("date")(new Date($scope.selectedInvoice.date), "dd-MMM-yyyy");
					$scope.selectedInvoice.due_date = $filter("date")(new Date($scope.selectedInvoice.due_date), "dd-MMM-yyyy");
				}
				$scope.$apply();
			}, 100);
		};

		function _downloadInvoice (invoiceId) {
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.downloadInvoice($scope.CP.patient.id, invoiceId)
				.then(
					function (resp) {
						downloadFile(resp.data, `${$scope.CP.patient.firstName}_${$scope.CP.patient.lastName}_${invoiceId}`);
						fetchInvoices();
					},
					function (error) {
						console.error(error);
						showErrorDialog();
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
		}

		$scope.downloadInvoice = function (invoiceStatus, invoiceId) {
			if(!$scope.isDownloadable(invoiceStatus)){
				return;
			}
			_downloadInvoice(invoiceId);
		};

		function downloadFile(data, filename) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		$scope.isDownloadable = function (status) {
			return true; //status !== 'DRAFT';
		}

		$scope.isEditable = function(invoice) {
			return invoice.status !== 'PAID' && invoice.payments.length === 0;
		}

		$scope.isSendable = function (status) {
			return true; // status !== 'DRAFT';
		}

		$scope.transformStatusLabel = function (status) {
			return status === 'SENT' ? 'SUBMITTED' : status;
		}


	},
]);
