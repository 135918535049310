app.directive('mainNavigation', function () {
	return {
		restrict: 'E',
		templateUrl: 'app/src/js/directives/nav.html',
		replace: false,
		controller: 'mainNavigationController'
	};
});

app.controller('mainNavigationController', ['$scope', 'AuthService', 
'EmailAPI', 'Session', '$timeout', '$modal', 
'$state', '$location', 'CurrentPatient', "TasksAPI","$mdDialog","UserService","AppointmentTimerService",
function ($scope, AuthService, 
	EmailAPI, Session, $timeout, $modal, 
	$state, $location, CurrentPatient,TasksAPI,$mdDialog,UserService,AppointmentTimerService) {
	$scope.AS = AuthService;
	$scope.EA = EmailAPI;
	$scope.CP = CurrentPatient;
	$scope.US = UserService;
	$scope.ATS = AppointmentTimerService;
	$scope.TasksAPI = TasksAPI;
	$scope.message = {};
	$scope.Session = Session;
	$scope.showPatientSearch = false;
	$scope.practices = $scope.Session.practiceOptions;
	$scope.selectedPractice = $scope.Session.practice;
	$scope.recentPatientsFeature = false;
	$scope.isTaskFeatureEnabled = false;

	if($scope.Session.practice && $scope.Session.practice.practiceFeatures){
		$scope.isTaskFeatureEnabled = $scope.Session.practice.practiceFeatures.sendTasksWithinPracticeFeature;
	}

	if($scope.isTaskFeatureEnabled){
		fetchTaskList();
	}

	$scope.badgeCount = 0;

	if(Session.user.features && Session.user.features.recentPatientsFeature){
		$scope.recentPatientsFeature = true;
	}

	// fetchTaskList();

	$scope.changeEmail = function () {
		$scope.popoverOpened1=false;
		$scope.requestSent = false;
		$scope.verifySuccess = false;
		$scope.changeEmailModal = $modal({
			scope: $scope,
			templateUrl: "app/src/views/templates/modal.change-email.tpl.html",
			show: true,
			backdrop: "static",
			keyboard: false,
			title: "Current email: "+$scope.Session.user.userName,
		});
	};

	$scope.verifyEmail = function (email) {
		$scope.AS.isProcessing = true;
		$scope.AS.updateEmail(email).then(function (resp) {
			$scope.requestSent = true;
		}, function (error) {
			console.log(error);
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}).then(function () {
			$scope.AS.isProcessing = false;
		})
	}

	$scope.verifyCode = function (code) {
		$scope.AS.isProcessing = true;
		$scope.AS.verifyEmail(code).then(function (resp) {
			$scope.requestSent = false;
			$scope.verifySuccess = true;
		}, function (error) {
			console.log(error);
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}).then(function () {
			$scope.AS.isProcessing = false;
		})
		
	}
	$scope.$watch('Session', function (newValue, oldValue) {
		if ($scope.Session.user.firstName !== undefined) {
			var lname = $scope.Session.user.lastName || ''
			var fname = $scope.Session.user.firstName || ''
			$scope.fullName = fname + ' ' + lname;
		}
		if(!newValue.practice){
			//do nothing
		}else if (!oldValue.practice || newValue.practice.id !== oldValue.practice.id) {
			$scope.showPatientSearch = true;
			$scope.selectedPractice = $scope.Session.practice;
			$scope.isTaskFeatureEnabled = $scope.Session.practice.practiceFeatures.sendTasksWithinPracticeFeature;
			if($scope.isTaskFeatureEnabled){
				fetchTaskList();
			}
		} else {
			// $scope.showPatientSearch = false;
			// $scope.selectedPractice = null;
		}
		if ($scope.Session.practiceOptions.length > 0) {
			$scope.practices = $scope.Session.practiceOptions;
		}
	}, true)

	$scope.isLogin = ($location.path().indexOf('login') > -1) ? false : true;

	$scope.indicatorWidth = 'zero';

	if ($state.current.name === 'refer.search') { $scope.indicatorWidth = 'search'; }

	$scope.$on('$stateChangeSuccess', function (event) {
		$scope.isLogin = ($location.path().indexOf('login') > -1) ? false : true;

		if ($state.current.name === 'refer.search') { $scope.indicatorWidth = 'search'; }
		if ($state.current.name === 'refer.impact') { $scope.indicatorWidth = 'impact'; }
		if ($state.current.name === 'refer.examination') { $scope.indicatorWidth = 'examination'; }
		if ($state.current.name === 'refer.complete') { $scope.indicatorWidth = 'complete'; }
	})

	$scope.showHelpModal = function () {
		$scope.helpModal = $modal({
			scope: $scope,
			templateUrl: 'app/src/views/templates/modal.help.tpl.html',
			show: true,
			title: 'HELP/FAQ',
		})

		$timeout(function () {
			angular.element('#q1-icon-wrap').triggerHandler('click');
		}, 700);

	}
	$scope.showQuestion = function ($event) {
		angular.element($event.currentTarget).toggleClass('rotate');
		angular.element($event.currentTarget).parents('.q-wrap').find('.q-body-wrap').slideToggle();
	}

	$scope.showFeedBackModal = function () {
		$scope.message = {};
		$scope.feedBackModal = $modal({
			scope: $scope,
			templateUrl: 'app/src/views/templates/modal.feedback.tpl.html',
			show: true,
			title: 'FEEDBACK'
		})
	}
	$scope.sendFeedBackDetails = function (valid) {
		// send feedback form data here... then show success
		if (valid) {
			$scope.EA.isProcessing = true;
			$scope.EA.feedback($scope.message.feedback, $scope.message.subject).then(function (resp) {
				$scope.feedBackModal.hide();
				$scope.EA.isProcessing = false;
				$scope.successFeedBackModal = $modal({
					scope: $scope,
					templateUrl: 'app/src/views/templates/modal.success-feedback.tpl.html',
					show: true,
					title: 'FEEDBACK SUBMITTED'
				})
			})
		} else { console.log('invalid form'); }
	}

	$scope.showContactModal = function () {
		$scope.message = {};
		$scope.contactModal = $modal({
			scope: $scope,
			templateUrl: 'app/src/views/templates/modal.contact.tpl.html',
			show: true,
			title: 'CONTACT US'
		})
	}
	$scope.sendContactDetails = function () {
		$scope.EA.isProcessing = true;
		$scope.EA.contact($scope.message.contact).then(function (resp) {
			$scope.contactModal.hide();
			$scope.EA.isProcessing = false;
			$scope.successContactModal = $modal({
				scope: $scope,
				templateUrl: 'app/src/views/templates/modal.success-contact.tpl.html',
				show: true,
				title: 'MESSAGE SENT'
			})
		})
	}

	$scope.closeChangeEmailModal = function () {
		if ($scope.changeEmailModal) {
			$scope.changeEmailModal.hide();
		}
		if ($scope.verifySuccess) {
			$scope.logout();
		}
	}

	function delay(milliseconds){
		return new Promise(resolve => {
			setTimeout(resolve, milliseconds);
		});
	}

	$scope.logout = async function () {
		if ($scope.changeEmailModal) {
			$scope.changeEmailModal.hide();
		}
		$scope.CP.clearPatient();
		await delay(500);
		$scope.ATS.stopTimer();
		$scope.AS.logout();
		Session.destroy();
		$state.go('login');
	}

	$scope.changePractice = function (selectedPractice) {
		Session.setPractice(selectedPractice);
		if ($state.current.url === '/search') {
			$scope.CP.clearPatientResult();
			// $state.reload();
		} else {
			$scope.CP.clearPatientResult();
			// $state.go("refer.search");
		}
		$scope.US.getUser().then(function (resp) {
			Session.setCurrentUser(resp.data);
		}, function(error){
			console.log(error);
		})
		$state.go("welcome");
	}

	$scope.searchPatient = function () {
		$scope.CP.clearPatientResult();
		if ($state.current.url === '/search') {
			$state.reload();
		} else {
			$state.go("refer.search");
		}
	}

	function showTaskModal() {
		$mdDialog.show({
			controller: "createTaskController",
			templateUrl: "app/src/views/templates/task-list.tmpl.html",
			parent: angular.element(document.body),
			clickOutsideToClose: false,
			scope: $scope.$new(true),
			preserveScope: false,
			onComplete: (scope, element) => {
				element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer"></span>');
				$("#modal-close-btn").click(function (event) {
					$mdDialog.hide();
				});
			},
			resolve: {
				// accounts: () => $scope.accounts,
				patientName: () => '',
				dob: () => '',
				patientId: () => null,
				taskList: () => true,
				createNewTask: () => $scope.createTask,
				updateBadge: () => $scope.updateBadge,
				fetchTask: () => fetchTaskList,
				defaultAssigner: () => null
			},
		});
	}

	$scope.updateBadge = function (count) {
		$scope.badgeCount = count;
	}

	$scope.showTasksModal = function () {
		showTaskModal();
		// const start = moment().add(-100,'d').utc().toISOString();
		// const end = moment().add(100,'d').utc().toISOString();
		// $scope.TasksAPI.queryAssignedTasks(start,end);
		// $scope.TasksAPI.queryCreatedTasks(start,end);
	};

	$scope.createTask = function (patient) {
		$mdDialog.show({
			controller: "createTaskController",
			templateUrl: "app/src/views/templates/create-task.tmpl.html",
			parent: angular.element(document.body),
			clickOutsideToClose: false,
			scope: $scope.$new(true),
			preserveScope: false,
			onComplete: (scope, element) => {
				element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer"></span>');
				$("#modal-close-btn").click(function (event) {
					$mdDialog.hide();
				});
			},
			resolve: {
				// accounts: () => $scope.accounts,
				patientName: () => patient.patientName,
				dob: () => patient.dob,
				patientId: () => patient.patientId,
				taskList: () => false,
				createNewTask: () => null,
				updateBadge: () => $scope.updateBadge,
				fetchTask: () => fetchTaskList,
				defaultAssigner: () => null
			},
		});
	};

	function fetchTaskList() {
		const start = moment().add(-100, "d").utc().toISOString();
		const end = moment().add(100, "d").utc().toISOString();
		const p1 = $scope.TasksAPI.queryAssignedTasks(start, end);
		const p2 = $scope.TasksAPI.queryCreatedTasks(start, end);
        let allTasks = [];
		Promise.all([p1, p2])
			.then(
				(values) => {
					values[0].data.tasks.forEach((t) => allTasks.push(t));
					values[1].data.tasks.forEach((t) => {
						allTasks = allTasks.filter(m=>m.task_id !== t.task_id);
						allTasks.push(t);
					} );
				 $scope.badgeCount = allTasks.filter(t=>!t.is_completed && t.assigned_to_user_id === Session.user.id).length;
				},
				(error) => console.log(error)
			)
			.then(() => {
				$scope.$apply();
			});
		
	}

	$scope.openSetting = function() {
		$mdDialog.show({
			controller: "settingTabsController",
			templateUrl: "app/src/views/templates/modal.settings.tmpl.html",
			// Appending dialog to document.body to cover sidenav in docs app
			// Modal dialogs should fully cover application to prevent interaction outside of dialog
			parent: angular.element(document.body),
			clickOutsideToClose: false,
			fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
			openFrom: "#anchor",
			closeTo: "#anchor",
			scope: $scope.$new(true),
			preserveScope: false,
			onComplete: (scope, element) => {
				element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer modal-close"></span>');
				$("#modal-close-btn").click(function (event) {
					$mdDialog.hide();
				});
			},
		});
	}

	$scope.triggerCalendarModal = function(appointment) {
		$scope.selectedAppointment = appointment;
		$scope.openCalendar(null);
	}

	$scope.openCalendar = function(ev) {
		if(ev){
			$scope.selectedAppointment = null;
		}
		$mdDialog.show({
			controller: "calendarController",
			templateUrl: "app/src/views/templates/calendar.tmpl.html",
			// Appending dialog to document.body to cover sidenav in docs app
			// Modal dialogs should fully cover application to prevent interaction outside of dialog
			parent: angular.element(document.body),
			targetEvent: ev,
			clickOutsideToClose: true,
			fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
			openFrom: "#anchor",
			closeTo: "#anchor",
			scope: $scope.$new(true),
			preserveScope: false,
			resolve: {
				patientId: () => $scope.CP.patient.id,
				patientName: () => $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName,
				selectedAppointment: () => $scope.selectedAppointment,
			},
		});

	}

	$scope.faqs = [{
		q: "I work at more than one practice but they are not showing in the welcome page.",
		a: "Contact the CatTrax administrators using the Contact/Feedback form with the details of your multiple practices to be added to your profile. "
	}, {
		q: "What type of communication should be sent through the Contact/Feedback form?",
		a: "The form is useful for any communication that needs to be made to the CatTrax team. Just select the type of communication in the subject window (feedback, support, urgent, questions on referrals, passwords, update details) to ensure that it is routed to the correct agent."
	}, {
		q: "I have searched for a patient that should be in the system and but they have not been found or the details are incorrect.",
		a: "The patient’s last name and date of birth are required to perform a patient search. If multiple patients share the same last name and date of birth, all of these patients will be displayed allowing for you to select the correct one. Please double check the spelling of the patient’s name and the date of birth before creating a new patient record.  Incorrect details can be updated by clicking ‘edit patient’ after selecting a patient."
	}, {
		q: "My patient has alerted me to an error in their record or their details have changed.",
		a: "Ensure that their details are correct before searching for them. If that fails to find their record, create a new entry by clicking on the “Register a new patient” button. Ensure that their details are correct before saving."
	}, {
		q: "I am unable to edit the patient’s details or progress further with their referral.",
		a: "All patient contact details are updatable through the “Edit patient” window. If the patient is has been registered by another practice then you will need to transfer the patient to your practice using the ‘transfer patient button’ before you can edit their details or complete a referral. Completing a ‘transfer’ will automatically inform the previous optometrist that the patient has been transferred."
	}, {
		q: "If my patient has already completed the Impact on Life form, when should they redo it?",
		a: "As this form only takes moments to complete the patient should complete it for every visit unless they feel that they are certain nothing has changed since they last completed the form. If the form is submitted multiple times in too short a period, the results may be subject to auditing."
	}, {
		q: "Why can’t I complete the clinical examination details or submit a referral?",
		a: "These tasks can only be completed by optometrists and ophthalmologists.  Practice administration staff can register and edit patient’s details only."
	}, {
		q: "Can I enter visual data for my patient after cataract surgery?",
		a: "Yes you can! This data is essential for auditing visual outcomes after surgery so please do this when you see your patients after surgery."
	}, {
		q: "Can I enter visual data for my patient without making a referral for cataract surgery?",
		a: "Yes you can! Complete and submit the examination form then return to the main menu without completing a referral."
	}, {
		q: "I have completed a referral but the patient has changed their mind and does not want cataract surgery (at all or at the selected provider). What should I do?",
		a: "Please call the provider and quote the referral reference number. You can then redo the CatTrax referral with another provider selected."
	}, {
		q: "Can my reception staff enter patient data to streamline the referral process during the consultation?",
		a: "Yes. As long as they have been registered on CatTrax as a Practice Administrator they can perform tasks that include adding or editing patient details, patient practice transfers, and Impact on Life inputs. Only registered clinicians can perform the more advanced visual data inputs and patient referrals."
	}
	];
}])