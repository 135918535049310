app.service('SurgeryAgreementAPI', ['$http', 'ENV', 'Session', function($http, ENV, Session) {

	var service = {
		isProcessing: false,
		create: function(patientId, data) {
			return $http({
				method: 'POST',
				url: ENV.API + `/api/surgery-agreement/patient/${patientId}`,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				},
                // responseType: "arraybuffer",
				data,
				transformResponse: [
					function (data) {
						return data;
					},
				],
			})
		},

		getSettings: function() {
			return $http({
				method: 'GET',
				url: ENV.API + '/api/surgery-agreement/settings',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				}
			})
		},
        saveSettings: function(data) {
			return $http({
				method: 'PUT',
				url: ENV.API + '/api/surgery-agreement/settings',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data
			})
		},

		sign: function(surgeryAgreementId, patientId,translatorName,guardian,tissueReturn,sendPatientEmail,patientEmail,patientEmailCC,userBlob,patientBlob,translatorBlob){
			const formData = new FormData();
			formData.append('tissueReturn', tissueReturn);

			if(translatorName){
				formData.append('translatorName', translatorName);
			}

			if(guardian){
				formData.append('epoaName', guardian);
			}

			if(sendPatientEmail){
				formData.append("sendPatientEmail", true);
				formData.append("patientEmail", patientEmail);
				if (patientEmailCC) {
					formData.append("patientEmailCC", patientEmailCC);
				}
			}

			if(userBlob){
				formData.append('userSignature', userBlob, 'userSignature.png');
			}
			if(patientBlob){
				formData.append('patientSignature', patientBlob, 'patientSignature.png');
			}
			if(translatorBlob){
				formData.append('translatorSignature', translatorBlob, 'translatorSignature.png');
			}
			
			return $http({
				method: 'PUT',
				url: ENV.API + `/api/surgery-agreement/${surgeryAgreementId}/patient/${patientId}/sign`,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': undefined,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				},
				responseType: "arraybuffer",
				data:formData,
				transformRequest: angular.identity
			})
		},
		createMedicalCertificate: function (patientId,data) {
			return $http({
				method: 'POST',
				url: ENV.API + `/api/patient-certificate/patient/${patientId}`,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data,
				responseType: "arraybuffer",
			})
		},
		fetchMedicalCertificateSetting: function (userId) {
			return $http({
				method: 'GET',
				url: ENV.API + `/api/patient-certificate/user/${userId}/settings`,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				}
			})
		},
		uploadSignature: function (userId, blob) {
			const formData = new FormData();
			formData.append('signature', blob, 'userSignature.png');
			return $http({
				method: 'POST',
				url: ENV.API + `/api/user-files/${userId}/signature`,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': undefined,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				},
				responseType: "arraybuffer",
				data:formData,
				transformRequest: angular.identity
			})
		}
	};

	return service;
}])
