app.component("eyeSection", {
	templateUrl: "app/src/js/components/operationNote/eyeSection/eyeSection.html",
	controller: "eyeSectionController",
	bindings: {
		data: "<",
		eyeTitle: "@",
		otherTypeSetting: "<",
		setDefaultMedications: "&",
		refreshProcedure: "&",
	},
});

app.controller("eyeSectionController", [
	"$scope",
	"OPERATIONPROCEDURE",
	"OPERATIONANAESTHETIC",
	"INCISIONSIZE",
	"OVD",
	"$timeout",
	function ($scope, OPERATIONPROCEDURE, OPERATIONANAESTHETIC, INCISIONSIZE, OVD, $timeout) {
		$scope.operationProcedure = OPERATIONPROCEDURE;
		$scope.anaesthetic = OPERATIONANAESTHETIC;
		$scope.incisionSize = INCISIONSIZE.map((t) => t);
		$scope.warn = false;
		$scope.otherOvdArray = [];
		$scope.warn_ovd = false;

		$scope.removeIncision = function (index) {
			$scope.$ctrl.data.incisions.splice(index, 1);
		};

		$scope.removeOther = function (index) {
			$scope.otherOvdArray.splice(index, 1);
		};

		
		$scope.selection = [];
		// if($scope.$ctrl.data.ovd){
		// 	$scope.selection = $scope.$ctrl.data.ovd;
		// }

		$scope.onProcedureChange = function() {
			if($scope.$ctrl.data.procedure_type !== 'OTHER'){
				$scope.$ctrl.data.primaryProcedure = null;
			}
			if ($scope.$ctrl.refreshProcedure) {
				$scope.$ctrl.refreshProcedure();
			}
		}

		this.$onChanges = function (changesObj) {
			if (changesObj.data.currentValue) {
				if (changesObj.data.currentValue.ovd && changesObj.data.currentValue.ovd.length > 0) {
					changesObj.data.currentValue.ovd.forEach((element) => {
						const obj = $scope.OVD.find((t) => t.value === element);
						if (obj) {
							obj.selected = true;
						} else {
							$scope.OVD[5].selected = true;
							$scope.otherOvdArray = element.split(",");
							// $scope.otherOvd = element;
						}
					});
				}
				// else if(changesObj.data.currentValue.ovd){
				// 	$scope.OVD = OVD.map(t => ({...t, selected: false}));
				// 	$scope.otherOvdArray = [];
				// }
			}
		};

		$scope.$watch(
			"OVD|filter:{selected:true}",
			function (newValue) {
				$scope.selection = newValue.map(function (item) {
					return item.value;
				});
				if ($scope.$ctrl.data) {
					$scope.$ctrl.data.ovd = $scope.selection.map(function (item) {
						if(item === "Other") {
							$scope.warn_ovd = false;
						}
						if (item === "Other" && $scope.otherOvdArray.length > 0) return getOtherOvdArrayString($scope.otherOvdArray);
						return item;
					});
				}
			},
			true
		);

		function getOtherOvdArrayString(array) {
			let str = '';
			array.forEach( (element,index) => {
				str = str + element;
				if(index !== array.length-1){
					str = str + ','
				}
			})
			return str;
		}

		$scope.form_other = {value: ''};

		$scope.addOther = function(){
			if($scope.form_other.value && $scope.form_other.value.length > 2 && $scope.form_other.value.length < 41){
				$scope.otherOvdArray.push($scope.form_other.value);
				$scope.$ctrl.data.ovd = $scope.selection.map(function (item) {
					if (item === "Other") return getOtherOvdArrayString($scope.otherOvdArray);
					return item;
				});
				$scope.warn_ovd = false;
				$scope.form_other.value = '';
			}
		}

		$scope.OVD = OVD.map(t => ({...t, selected: false}));
		
		$scope.addIncision = function () {
			if (
				!$scope.$ctrl.form.incision_size.val ||
				!$scope.$ctrl.form.incision_enlargement ||
				(!$scope.$ctrl.form.incision_location_degree && $scope.$ctrl.form.incision_location_degree !== 0) ||
				!$scope.$ctrl.form.incision_location
			) {
				return;
			}
			let size, enlargement, location_degree, location;
			try {
				size = $scope.$ctrl.form.incision_size.val;
				enlargement = $scope.$ctrl.form.incision_enlargement;
				location_degree = $scope.$ctrl.form.incision_location_degree;
				location = $scope.$ctrl.form.incision_location;
			} catch (error) {
				return;
			}

			if (!$scope.$ctrl.data.incisions) {
				$scope.$ctrl.data.incisions = [];
			}
			$scope.$ctrl.data.incisions.push({
				size: size ? Number.parseFloat(size.id).toFixed(1) : null,
				enlargement,
				location_degree,
				location_type: location,
			});
			// reset fields to empty
			this.$ctrl.form.incision_size.val = null;
			// this.$ctrl.form.incision_enlargement = null;
			this.$ctrl.form.incision_location_degree = null;
			$scope.warn = false;
			// this.$ctrl.form.incision_location = null;
		};
		$scope.onChange = function () {
			if (angular.isString($scope.$ctrl.form.incision_size.val)) {
				$scope.$ctrl.form.incision_size.val = {
					id: $scope.$ctrl.form.incision_size.val,
					name: $scope.$ctrl.form.incision_size.val,
				};
				if (!$scope.incisionSize.find((t) => t.name === $scope.$ctrl.form.incision_size.name)) {
					$scope.incisionSize.push($scope.$ctrl.form.incision_size.val);
				}
			}
			$scope.showWarn();
			// $scope.$ctrl.data.ccc.pupil_expander = $scope.$ctrl.freeTextDemo.val.id;
		};

		$scope.showWarn = function () {
			$scope.warn = true;
		};

		$scope.showWarn_ovd = function () {
			$scope.warn_ovd = true;
		};

		$scope.primaryProcedureCallback = function (form){
			if(!$scope.$ctrl.data.other_procedure){
				$scope.$ctrl.data.other_procedure = {};
			}
			if(!form.diagnosis_object){
				$scope.$ctrl.data.other_procedure.primary_procedure = null;
				return;
			}
			$scope.$ctrl.data.other_procedure.primary_procedure = {...form.diagnosis_object, concept_id: form.diagnosis_object.conceptId};
			const obj = $scope.$ctrl.otherTypeSetting.find(t => t.primary_procedure.concept_id === form.diagnosis_object.conceptId);
			if(obj){
				$scope.$ctrl.data.other_procedure.secondary_procedure_list = angular.copy(obj.secondary_procedure_list);
				$scope.$ctrl.data.other_procedure.description = obj.description;
				$scope.$ctrl.data.anaesthetic = obj.anaesthetic;
				$scope.$ctrl.data.instructions_for_patient = obj.instructions_for_patient;
				$scope.$ctrl.data.intraoperative_medications = obj.intraoperative_medications;

				if($scope.$ctrl.setDefaultMedications){
				   $scope.$ctrl.setDefaultMedications({value: obj.postop_medications, eyes: $scope.$ctrl.eyeTitle});
				}
			} else {
				$scope.$ctrl.data.other_procedure.secondary_procedure_list = [];
				$scope.$ctrl.data.other_procedure.description = null;
				$scope.$ctrl.data.anaesthetic = null;
				$scope.$ctrl.data.instructions_for_patient = null;
				$scope.$ctrl.data.intraoperative_medications = null;
			}
		}

		$scope.secondaryProcedureCallback = function (form){
			if (!$scope.$ctrl.data.other_procedure) {
				$scope.$ctrl.data.other_procedure = { secondary_procedure_list: [] };
			}else if(!$scope.$ctrl.data.other_procedure.secondary_procedure_list){
				$scope.$ctrl.data.other_procedure.secondary_procedure_list = [];
			}
			if (form) {
				const array = form.map(t => ({ ...t, concept_id: t.conceptId }));
				$scope.$ctrl.data.other_procedure.secondary_procedure_list = array;
			}
			
		}
	},
]);
