app.component("preOpLensStatus", {
	templateUrl: "app/src/js/components/preOpStatus/preOpLensStatus/preOpLensStatus.html",
	controller: "preOpLensStatusController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("preOpLensStatusController", [
	"$scope",
	"PreOp",
	"LENS_STATUS",
	"NUCLEAR_OPALESCENCE",
	"NUCLEAR_COLOUR",
	"CORTICAL",
	"POST_SUBCAPSULAR",
	"PUPIL_SIZE",
	function ($scope, PreOp, LENS_STATUS, NUCLEAR_OPALESCENCE, NUCLEAR_COLOUR, CORTICAL, POST_SUBCAPSULAR, PUPIL_SIZE) {
		$scope.lens_status = LENS_STATUS;
		$scope.nuclear_opalescence = NUCLEAR_OPALESCENCE;
		$scope.nuclear_colour = NUCLEAR_COLOUR;
		$scope.cortical = CORTICAL;
		$scope.post_subcapsular = POST_SUBCAPSULAR;
		$scope.pupil_size = PUPIL_SIZE;

		$scope.showOpalPopUp = function () {
			if (this.$ctrl.showOpal === false || this.$ctrl.showOpal === undefined) {
				this.$ctrl.showOpal = true;
			} else {
				this.$ctrl.showOpal = false;
			}
		};

		$scope.showColourPopUp = function () {
			if (this.$ctrl.showColour === false || this.$ctrl.showColour === undefined) {
				this.$ctrl.showColour = true;
			} else {
				this.$ctrl.showColour = false;
			}
		};

		$scope.showCorticalPopUp = function () {
			if (this.$ctrl.showCortical === false || this.$ctrl.showCortical === undefined) {
				this.$ctrl.showCortical = true;
			} else {
				this.$ctrl.showCortical = false;
			}
		};

		$scope.showSubPopUp = function () {
			if (this.$ctrl.showSub === false || this.$ctrl.showSub === undefined) {
				this.$ctrl.showSub = true;
			} else {
				this.$ctrl.showSub = false;
			}
		};

		$scope.showPupilPopUp = function () {
			if (this.$ctrl.showPupil === false || this.$ctrl.showPupil === undefined) {
				this.$ctrl.showPupil = true;
			} else {
				this.$ctrl.showPupil = false;
			}
		};
	},
]);
