app.controller("createTaskController", [
	"$scope",
	"CurrentPatient",
	"TasksAPI",
	"$modal",
	"$filter",
	"$mdDialog",
    "Session",
    'taskList',
    'PatientAPI',
    '$timeout',
    "$state",
    "NgTableParams",
    'patientId',
    'patientName',
    'dob',
    'createNewTask',
    'updateBadge',
    'fetchTask',
    'defaultAssigner',
    'ShareData',
	function ($scope, CurrentPatient, TasksAPI, $modal, $filter,$mdDialog,Session,taskList,PatientAPI,$timeout,$state,NgTableParams,patientId,patientName,dob,createNewTask,updateBadge,fetchTask,defaultAssigner,ShareData) {
		this.$onInit = function () {
            $scope.patientName = patientName;
            $scope.dob = dob;
            $scope.createNewTask = createNewTask;
            $scope.updateBadge = updateBadge;
            $scope.notifyNav = fetchTask;
			$scope.CP = CurrentPatient;
            $scope.PA = PatientAPI;
            $scope.SD = ShareData;
			$scope.$mdDialog = $mdDialog;
			$scope.TasksAPI = TasksAPI;
            // $scope.US = AppointmentAPI;
			$scope.today = $filter("date")(new Date(), "dd-MMMM-yyyy");
            $scope.practiceOptions = Session.practiceOptions.filter(t=>t.practiceFeatures.sendTasksWithinPracticeFeature);
            // $scope.selectedPractice = $scope.practiceOptions.find(t=>t.id === Session.practice.id);
            if($scope.SD.taskFilters.selectedPractice && $scope.SD.taskFilters.selectedPractice.length > 0 ){
                $scope.selectedPractice = $scope.SD.taskFilters.selectedPractice;
            }else{
                $scope.selectedPractice = [$scope.practiceOptions.find(t=>t.id === Session.practice.id)];
            }
            
            $scope.users = [];
            $scope.ALL_LABELS = [];
            $scope.labels = [];
            $scope.taskForm = {
                assigned_to_practice_id: Session.practice.id,
                assigned_to_user_id: defaultAssigner ? Number(defaultAssigner): null,
            };
            $scope.allTasks = [];
            //TOTAL_TASKS: total tasks with all practices
            $scope.TOTAL_TASKS = [];
            $scope.completedTasks = [];
            $scope.uncompletedTasks = [];
            $scope.spanStyle={'background-color':'white','width': '36px', 'height': '36px'};
            if (taskList) {
				fetchTaskList();
			} else {
				fetchLabels();
			}
           
		};

        $scope.usersForPractice = (practiceId) => $scope.users.filter(t=>t.practiceId === practiceId);

        function fetchTaskList() {
            $scope.allTasks = [];
            $scope.TOTAL_TASKS = [];
            const start = moment().add(-100, "d").utc().toISOString();
            const end = moment().add(100, "d").utc().toISOString();
            $scope.TasksAPI.isProcessing = true;
            // $scope.US.isProcessing = true;
            const p1 = $scope.TasksAPI.queryAssignedTasks(start, end);
            const p2 = $scope.TasksAPI.queryCreatedTasks(start, end);
            const p3 = $scope.TasksAPI.getUsers($scope.selectedPractice[0].id);
            const p4 = $scope.TasksAPI.queryTaskLabels();

            Promise.all([p1, p2, p3,p4])
				.then(
					(values) => {
						values[0].data.tasks.forEach((t) => $scope.allTasks.push(t));
						values[1].data.tasks.forEach((t) => {
                            $scope.allTasks = $scope.allTasks.filter(m=>m.task_id !== t.task_id);
                            $scope.allTasks.push(t);
                        } );
                        $scope.TOTAL_TASKS = angular.copy($scope.allTasks);
						$scope.users = [];
						values[2].data.forEach(function (doc) {
                            if(Session.user.id === doc.id){
                                $scope.users.push({ name: "Me (" + doc.firstName + " " + doc.lastName +")", value: doc.id, practiceId: doc.practiceId });
                            }else{
                                $scope.users.push({ name: doc.firstName + " " + doc.lastName, value: doc.id, practiceId: doc.practiceId });
                            }
						});
                        $scope.users.sort(function (a, b) {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        });
                        $scope.ALL_LABELS = angular.copy(values[3].data); // filter(t=>t.practice_id === Session.practice.id)
                        mapTasks();
                        filterLabels($scope.selectedPractice.map(t=>t.id));
                        refreshTables();
					},
					(error) => console.log(error)
				)
				.then(() => {
                    $scope.TasksAPI.isProcessing = false;
                    // $scope.US.isProcessing = false;
                    $scope.$apply();
                });
            
        }

        function mapTasks() {
            // filter(a=>a.practice_id === Session.practice.id)
            $scope.allTasks = $scope.TOTAL_TASKS.filter((a) => $scope.selectedPractice.some((m) => m.id === a.practice_id)).map((t) => {
				const label = $scope.ALL_LABELS.find((m) => m.task_label_id === t.task_label_id);
				if (label) {
					return { ...t, bgColor: "#" + label.colour, labelName: label.name };
				}
				return { ...t };
			});
        }

        $scope.showAssignedUser = function (item) {
			var selected = $scope.users.find((t) => t.value === item.assigned_to_user_id);
			return $scope.users && selected ? selected.name : "Not set";
		};

        $scope.updateDescription = function (item, description) {
            const updatedItem = {...item,description};
            $scope.TasksAPI.isProcessing = true;
            $scope.TasksAPI.updateTask(item.task_id,item.practice_id,updatedItem).then(function(response){},function(error){}).then(function(){
                $scope.TasksAPI.isProcessing = false;
            })
        }

        function updateBadgeCount () {
            if($scope.updateBadge){
                // $scope.updateBadge($scope.uncompletedTasks.filter(t=>t.assigned_to_user_id === Session.user.id).length);
                $scope.updateBadge($scope.TOTAL_TASKS.filter(t=>t.assigned_to_user_id === Session.user.id && !t.is_completed).length);
            }
        }

        function getName(id){
            const target = $scope.users.find((t) => t.value === id);
            return target ? target.name : '';
        }

        function refreshTables() {
            $scope.uncompletedTasks = $scope.allTasks.filter(t=>!t.is_completed).map(u=>({...u,_assigned_user_name:getName(u.assigned_to_user_id)}));
            $scope.completedTasks = $scope.allTasks.filter(t=>t.is_completed).map(u=>({...u,_assigned_user_name:getName(u.assigned_to_user_id)}));
            $scope.tableUncompleteParams = new NgTableParams(
				{
					count: 10,
				},
				{
					counts: [],
					dataset: $scope.uncompletedTasks
				}
			);
            $scope.tableCompleteParams = new NgTableParams({
                count: 10
            }, {
                counts: [],
                dataset: $scope.completedTasks
            });
            
            $scope.userFilter_uncompleted = $scope.uncompletedTasks.reduce(function (accumulator, currentValue) {
                const name = getName(currentValue.assigned_to_user_id);
                if (!accumulator.some(t => t.id === name)) {
                    accumulator.push({ id: name, title: name })
                }
                return accumulator
            }, [{ id: '', title: 'All' }])


            $scope.userFilter_completed = $scope.completedTasks.reduce(function (accumulator, currentValue) {
                const name = getName(currentValue.assigned_to_user_id);
                if (!accumulator.some(t => t.id === name)) {
                    accumulator.push({ id: name, title: name })
                }
                return accumulator
            }, [{ id: '', title: 'All' }])

            $scope.tableUncompleteParams.filter()['labelName'] = $scope.SD.taskFilters.label_uncompleted;
            $scope.tableCompleteParams.filter()['labelName'] = $scope.SD.taskFilters.label_completed;

            $scope.tableUncompleteParams.filter()['_assigned_user_name'] = $scope.SD.taskFilters.assignedUser_uncompleted;
            $scope.tableCompleteParams.filter()['_assigned_user_name'] = $scope.SD.taskFilters.assignedUser_completed;

            updateBadgeCount();
        }

        $scope.updateTaskStatus = function (item) {
            item.is_completed = !item.is_completed;
            const updatedItem = {...item};
            const target = $scope.TOTAL_TASKS.find(t=>t.task_id === item.task_id);
            target.is_completed = item.is_completed;
            const target1 = $scope.allTasks.find(t=>t.task_id === item.task_id);
            target1.is_completed = item.is_completed;
            $scope.TasksAPI.isProcessing = true;
            $scope.TasksAPI.updateTask(item.task_id,item.practice_id,updatedItem).then(function(response){},function(error){}).then(function(){
                $scope.TasksAPI.isProcessing = false;

                $scope.uncompletedTasks = $scope.allTasks.filter(t=>!t.is_completed).map(u=>({...u,_assigned_user_name:getName(u.assigned_to_user_id)}));
                $scope.completedTasks = $scope.allTasks.filter(t=>t.is_completed).map(u=>({...u,_assigned_user_name:getName(u.assigned_to_user_id)}));

                $scope.tableUncompleteParams.settings({
					counts: [],
					dataset: $scope.uncompletedTasks
				})

                $scope.tableCompleteParams.settings({
					counts: [],
					dataset: $scope.completedTasks
				})

                $scope.tableUncompleteParams.reload();
                $scope.tableCompleteParams.reload();
               
                updateBadgeCount();
            })
        }

        $scope.deleteTask = function (item){
            $scope.TasksAPI.isProcessing = true;
			$scope.TasksAPI.deleteTask(item.task_id,item.practice_id)
				.then(
					function (response) {
                        fetchTaskList();
                    },
					function (error) {}
				)
				.then(function () {
					$scope.TasksAPI.isProcessing = false;
				});
        }

        $scope.updateAssignedUser = function(item,value,isUncomplete){
            const updatedItem = {...item,assigned_to_user_id: value,_assigned_user_name:getName(value)};

            const target = $scope.TOTAL_TASKS.find(t=>t.task_id === item.task_id);
            target.assigned_to_user_id = updatedItem.assigned_to_user_id;
            target._assigned_user_name = updatedItem._assigned_user_name;
            const target1 = $scope.allTasks.find(t=>t.task_id === item.task_id);
            target1.assigned_to_user_id = updatedItem.assigned_to_user_id;
            target1._assigned_user_name = updatedItem._assigned_user_name;

            $scope.TasksAPI.isProcessing = true;
            $scope.TasksAPI.updateTask(item.task_id,item.practice_id,updatedItem).then(function(response){},function(error){}).then(function(){
                $scope.TasksAPI.isProcessing = false;
                if(isUncomplete){
                    $scope.uncompletedTasks = $scope.allTasks.filter(t=>!t.is_completed).map(u=>({...u,_assigned_user_name:getName(u.assigned_to_user_id)}));
                    $scope.tableUncompleteParams.settings({
                        counts: [],
                        dataset: $scope.uncompletedTasks
                    })
                    $scope.tableUncompleteParams.reload();
                }else{
                    $scope.completedTasks = $scope.allTasks.filter(t=>t.is_completed).map(u=>({...u,_assigned_user_name:getName(u.assigned_to_user_id)}));
                    $scope.tableCompleteParams.settings({
                        counts: [],
                        dataset: $scope.completedTasks
                    })
                    $scope.tableCompleteParams.reload();
                }
                updateBadgeCount();
                
            })
            
        }

        $scope.updateTaskLabel = function(item,value,isUncomplete){
            const target = $scope.ALL_LABELS.find(m=>m.task_label_id === value);
            if(!target){
                return;
            }
            item.bgColor = '#'+target.colour;
            item.labelName = target.name;
            const updatedItem = {...item,task_label_id: value};

            const target1 = $scope.TOTAL_TASKS.find(t=>t.task_id === item.task_id);
            target1.task_label_id = updatedItem.task_label_id;
            target1.bgColor = '#'+target.colour;
            target1.labelName = target.name;
            const target2 = $scope.allTasks.find(t=>t.task_id === item.task_id);
            target2.task_label_id = updatedItem.task_label_id;
            target2.bgColor = '#'+target.colour;
            target2.labelName = target.name;

            $scope.TasksAPI.isProcessing = true;
            $scope.TasksAPI.updateTask(item.task_id,item.practice_id,updatedItem).then(function(response){},function(error){}).then(function(){
                $scope.TasksAPI.isProcessing = false;
                if(isUncomplete){
                    $scope.uncompletedTasks = $scope.allTasks.filter(t=>!t.is_completed).map(u=>({...u,_assigned_user_name:getName(u.assigned_to_user_id)}));
                    $scope.tableUncompleteParams.settings({
                        counts: [],
                        dataset: $scope.uncompletedTasks
                    })
                    $scope.tableUncompleteParams.reload();
                }
            })
        }



        function fetchUsers(practiceIds,callback) {
            const requests = practiceIds.map(t=>$scope.TasksAPI.getUsers(t))
            $scope.TasksAPI.isProcessing = true;
            Promise.all(requests)
				.then((values) => {
                    $scope.users = [];
                    values.forEach(v=>{
                        v.data.forEach(function (doc) {
                            if(Session.user.id === doc.id){
                                $scope.users.push({ name: "Me (" + doc.firstName + " " + doc.lastName +")", value: doc.id, practiceId: doc.practiceId });
                            }else{
                                $scope.users.push({ name: doc.firstName + " " + doc.lastName, value: doc.id,practiceId: doc.practiceId });
                            }
                        })
                    })
                    $scope.users.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                }, (error)=>showErrorDialog())
				.then(() => {
					$scope.TasksAPI.isProcessing = false;
                    if(callback){
                        callback();
                    }
					// $scope.US.isProcessing = false;
					$scope.$apply();
				});
            // $scope.TasksAPI.isProcessing = true;
            // $scope.TasksAPI.getUsers(practiceId).then(function(resp){
            //     $scope.users = [];
            //     resp.data.forEach(function (doc) {
            //         if(Session.user.id === doc.id){
            //             $scope.users.push({ name: "Me (" + doc.firstName + " " + doc.lastName +")", value: doc.id });
            //         }else{
            //             $scope.users.push({ name: doc.firstName + " " + doc.lastName, value: doc.id });
            //         }
			// 	});
                
            // },function(error){
            //     showErrorDialog();
            // }).then(function(){
            //     $scope.TasksAPI.isProcessing = false;
            // })
        }

        function fetchLabels() {
            // create task
            $scope.TasksAPI.isProcessing = true;
            $scope.TasksAPI.queryTaskLabels().then(function(resp){
                $scope.ALL_LABELS = angular.copy(resp.data.filter(t=>t.practice_id === Session.practice.id));
                $scope.onPracticeChange();
            },function(error){
                showErrorDialog();
            }).then(function(){
                $scope.TasksAPI.isProcessing = false;
            })

        }

        function filterLabels(practiceIds){
            $scope.labels = $scope.ALL_LABELS.filter(t=>practiceIds.some(m=>m === t.practice_id));
        }

        $scope.filterLabelsForPractice = function (practice_id) {
			return $scope.ALL_LABELS.filter((t) => t.practice_id === practice_id).sort((x, y) => {
				if (x.name < y.name) {
					return -1;
				}
				if (x.name > y.name) {
					return 1;
				}
				return 0;
			});
		};

        // function fetchData(){
        //     $scope.US.isProcessing = true;
		// 	$scope.TasksAPI.isProcessing = true;
		// 	const p1 = $scope.US.getDoctorsByPracticeId(Session.practice.id);
		// 	const p2 = $scope.TasksAPI.queryTaskLabels();

		// 	Promise.all([p1, p2])
		// 		.then((values) => {
		// 			$scope.users = angular.copy(values[0].data);
        //             $scope.labels = angular.copy(values[1].data);
        //             $scope.US.isProcessing = false;
		// 			$scope.TasksAPI.isProcessing = false;
        //             $scope.$apply();
		// 		})
		// 		.catch(() => {
        //             showErrorDialog();
        //             $scope.US.isProcessing = false;
		// 			$scope.TasksAPI.isProcessing = false;
        //         });
        // }

        $scope.positiveClick = function () {
            if ($scope.serverErrorModal) {
                $scope.serverErrorModal.hide();
                $scope.$mdDialog.hide();
            }
        }

        function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}

        $scope.onPracticeChange = function () {
            // $scope.users = [];
            filterLabels([$scope.taskForm.assigned_to_practice_id]);
            fetchUsers([$scope.taskForm.assigned_to_practice_id]);
        }

        $scope.onLabelChange = function(){
            const id = $scope.taskForm.task_label_id;
            if(id){
                const target = $scope.ALL_LABELS.find(t=>t.task_label_id === id);
                $scope.spanStyle={'background-color':`#${target.colour}`,'width': '36px', 'height': '36px'};
            }else{
                $scope.spanStyle={'background-color':'white','width': '36px', 'height': '36px'};
            }
        }

        function transformDate(value) {
			// dd/MM/yyyy
			const array = value.split('/');
			return array[2] + '-' + array[1] + '-' + array[0];
		}

        $scope.createTask = function() {
           //local to utc
           const date_due_UTC = moment(transformDate($scope.taskForm.date_due_UTC)).utc().toISOString();
			const data = {
				...$scope.taskForm,
				is_completed: false,
				date_due_UTC: date_due_UTC,
				patient_id: patientId,
				practice_id: Session.practice.id,
				user_id: Session.user.id,
			};
			$scope.TasksAPI.isProcessing = true;
			$scope.TasksAPI.createTask(data)
				.then(
					function (resp) {
						$scope.$mdDialog.hide();
                        if($scope.notifyNav){
                            $scope.notifyNav();
                        }
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(function () {
					$scope.TasksAPI.isProcessing = false;
				});
        }

        $scope.cancelTransfer = function () {
			$scope.CP.setPatient($scope.patientBackup);
		}

        function selectPatient(patient) {
			if($scope.CP.patientBackup){
				$scope.patientBackup = angular.copy($scope.CP.patientBackup);
			}
			$scope.CP.clearPatient();
			$timeout(function () {
				$scope.CP.setPatient(patient);
				if (!$scope.CP.patientIsMine) {
					//need to transfer
					$scope.patientDetailsModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
						show: true,
                        backdrop:"static",
						title: "PATIENT DETAILS",
					});
				}else{
                    $state.go("refer.search");
                    $mdDialog.hide();
                }
			});
		};

        $scope.showTransferModal = function () {
			$scope.patientDetailsModal.hide();
			$scope.previousPracticeName = $scope.CP.patient.practice.name;
			$scope.transferModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.transfer.tpl.html",
				show: true,
				title: "TRANSFER PATIENT",
			});
		};

        $scope.transferPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.PA.transfer($scope.CP.patient.id, Session.userId, Session.practice.id)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
					$state.go("refer.search");
                    $mdDialog.hide();
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.transferModal.hide();
				});
		};

        $scope.switchPatient = function (patientId) {
            if($scope.CP.patient.id === patientId){
                return;
            }
            $scope.PA.isProcessing = true;
            $scope.PA.getPatient(patientId).then(function (resp){
                selectPatient(resp.data);
            }, function (error) {
                $scope.serverErrorModal = $modal({
                    scope: $scope,
                    templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                    show: true,
                    title: "CatTrax has encountered an error",
                });
            }).then(function (){
                $scope.PA.isProcessing = false;
            });
        }

        $scope.formatDue = function (date) {
           //UTC to local
           return $filter("date")(moment.utc(date).toDate(), "dd/MM/yyyy");
        }

        $scope.isBeforeDue = function (date){
            return moment().isBefore(moment.utc(date));
        }

        $scope.createTaskForPatient = function() {
            if($scope.createNewTask){
                $mdDialog.hide();
                const patient = {patientName: $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName,
                    dob:$scope.CP.patient.dateOfBirth,
                    patientId: $scope.CP.patient.id,
                }
                $scope.createNewTask(patient);
            }
        }

        // $scope.changePractice = function(obj){
        //     $scope.selectedPractice = obj;
        //     fetchUsers($scope.selectedPractice.id);
        //     filterLabels($scope.selectedPractice.id);
        //     mapTasks();  
        //     refreshTables();
        // }

        function onPracticeUpdate () {
            if($scope.selectedPractice.length > 0){
                fetchUsers($scope.selectedPractice.map(t=>t.id), ()=>{
                    filterLabels($scope.selectedPractice.map(t=>t.id));
                    mapTasks();  
                    refreshTables();
                });
            }else{
                mapTasks();
                refreshTables();
            }
            $scope.SD.setTaskFilters({selectedPractice: $scope.selectedPractice});
            
            
        }
        $scope.practiceOptionEvents = {
            onSelectionChanged: onPracticeUpdate,
        }


        $scope.practiceOptionSettings = {
            displayProp: 'name',
            smartButtonMaxItems: 3,
            smartButtonTextConverter: function (itemText) {
                return itemText;
            },
            buttonClasses: "btn btn-dropdown-multiselect",
            showCheckAll: false,
            showUncheckAll: false,
            enableSearch: false,
            // scrollableHeight: '20em',
	        // scrollable: true
        };

        $scope.labelFilterChange = function (params, name) {
			if (params === $scope.tableUncompleteParams) {
                $scope.SD.setTaskFilters({label_uncompleted: name});
			} else if (params === $scope.tableCompleteParams) {
                $scope.SD.setTaskFilters({label_completed: name});
			}
		};

        $scope.userFilterChange = function (params, userId) {
			if (params === $scope.tableUncompleteParams) {
                $scope.SD.setTaskFilters({assignedUser_uncompleted: userId});
			} else if (params === $scope.tableCompleteParams) {
                $scope.SD.setTaskFilters({assignedUser_completed: userId});
			}
		};
	},
]);
