app.component("preOpInvoice", {
	templateUrl: "app/src/js/components/preOp/preOpInvoice/preOpInvoice.html",
	controller: "preOpInvoiceController",
	bindings: {
		hide: "&",
		connectPatient: "&",
		createInvoice: "&",
		viewInvoices: "&",
		setAccounts: "&",
		resolveConflicts: "&",
	},
});

app.controller("preOpInvoiceController", [
	"$scope",
	"$modal",
	"$window",
	"BillingAPI",
	"CurrentPatient",
	function ($scope, $modal, $window, BillingAPI, CurrentPatient) {
		this.$onInit = function () {
			$scope.CP = CurrentPatient;
			$scope.BillingAPI = BillingAPI;
			$scope.isLoggedIn = false;
			$scope.patientContactList = [];
			$scope.contact_terms = { value: "" };
			getLoggedIn();
		};

		$scope.connectPatient = function () {
			$scope.$ctrl.connectPatient();
		};

		$scope.createInvoice = function () {
			$scope.$ctrl.createInvoice({ contactId: $scope.hasLinkedContact.contact_id });
		};

		$scope.viewInvoice = function () {
			$scope.$ctrl.viewInvoices({ contactId: $scope.hasLinkedContact.contact_id });
		};

		$scope.loginXero = function () {
			if ($scope.loginInvoiceModal) {
				$scope.loginInvoiceModal.hide();
			}
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.getXeroLoginUrl()
				.then(
					function (resp) {
						const xeroUrl = resp.data;
						$window.open(
							xeroUrl,
							"_blank",
							"location=no,toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=500,height=600"
						);
					},
					function (error) {
						console.error(error);
                        showErrorDialog();
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
		};
		$scope.hasLinkedContact = null;
		function checkLinkedStatus() {
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.getContact($scope.CP.patient.id, "")
				.then(
					function (resp) {
						if (resp.data && resp.data.length > 0) {
							$scope.hasLinkedContact = resp.data[0];
						} else {
							$scope.hasLinkedContact = null;
						}
					},
					function (error) {
						console.error(error);
                        showErrorDialog();
						$scope.hasLinkedContact = null;
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
		}

		function getLoggedIn() {
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.getLoggedIn()
				.then(
					function (resp) {
						if (resp.data.error_organisation_conflict) {
							getXeroOrg();
						} else if (resp.data.logged_in_to) {
							$scope.isLoggedIn = true;
							$scope.$ctrl.setAccounts({ accounts: angular.copy(resp.data.accounts) });
							checkLinkedStatus();
						} else {
							popUpLogin();
						}
					},
					function (error) {
						console.error(error);
                        showErrorDialog();
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
		}

		function getXeroOrg() {
			$scope.BillingAPI.isProcessing = true;
			$scope.BillingAPI.getXeroOrg()
				.then(
					function (resp) {
						$scope.$ctrl.resolveConflicts({orgList: angular.copy(resp.data)});
					},
					function (error) {
						console.error(error);
                        showErrorDialog();
					}
				)
				.then(function () {
					$scope.BillingAPI.isProcessing = false;
				});
		}

		function popUpLogin() {
			$scope.loginInvoiceModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.invoice-login.tpl.html",
				show: true,
				backdrop: "static",
				keyboard: false,
			});
		}

        function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}

	},
]);
