app.service("UserService", [
	"$http",
	"ENV",
	"Session",
	"$base64",
	function ($http, ENV, Session,$base64) {
		var service = {
			isProcessing: false,

			getUser: function () {
				let headers =  {
					Authorization: "Bearer " + Session.token
				};
				if(Session.practice && Session.practice.id){
                     headers =  {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
					};
				}
				return $http({
					method: "GET",
					url: ENV.API + "/api/users/" + Session.userId,
					headers
				});
			},

			getUserPractices: function () {
				return $http({
					method: "GET",
					url: ENV.API + "/api/users/" + Session.userId + "/practices",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},

			setPassword: function (password,oldPassword,username) {
				var authString = 'Basic ' +  $base64.encode(username + ':' + oldPassword);
				return $http({
					method: "POST",
					url: ENV.API + "/api/security/updatePassword/",
					headers: {'Authorization': authString, },
					data: {
						newPassword: password,
						currentPassword: oldPassword,
						idp_access_token: Session.idp_access_token
					},
				});
			},

			getDoctors: function () {
				return $http({
					method: "GET",
					url: ENV.API + "/api/users/oph",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},

			getDoctorsByPracticeId: function (practiceId) {
				return $http({
					method: "GET",
					url: ENV.API + "/api/users/oph?practiceId="+practiceId,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},

			getNotifications: function () {
				return $http({
					method: "GET",
					url: ENV.API + "/api/notification?userId=" + Session.userId,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},

			getRecentPatients: function () {
				return $http({
					method: "GET",
					url: ENV.API + `/api/users/${Session.userId}/features/recent_patients`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
		};

		return service;
	},
]);
