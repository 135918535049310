app.component("injectionComplications", {
	templateUrl: "app/src/js/components/injectionNote/complicationsSection/injectionComplications.html",
	controller: "injectionComplicationsController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		submitted: "="
	},
});

app.controller("injectionComplicationsController", [
	"$scope",
	"$timeout",
	function ($scope,$timeout) {
		$scope.warn = false;
		$scope.removeOther = function (index) {
			$scope.$ctrl.data.complications.other.splice(index, 1);
		};

		$scope.addOther = function (text) {
			if (text == null) {
				return;
			}

			if (!$scope.$ctrl.data.complications) {
				$scope.$ctrl.data.complications = { other: [] };
			}

			if (!$scope.$ctrl.data.complications.other) {
				$scope.$ctrl.data.complications.other = [];
			}
			$scope.$ctrl.data.complications.other.push(text);
			// reset fields to empty
			this.$ctrl.form.other = null;
			$scope.warn = false;
		};

		$scope.disableComplications = function () {
			if (!$scope.$ctrl.data) {
				$scope.$ctrl.data = { complications: {} };
			}
			if (!$scope.$ctrl.data.complications) {
				$scope.$ctrl.data.complications = {};
			}

			$scope.$ctrl.data.complications.subconjunctival_haemorrhage = "NO";
			$scope.$ctrl.data.complications.corneal_abrasion = "NO";
			$scope.$ctrl.data.complications.raised_intraocular_pressure = "NO";
			$scope.$ctrl.data.complications.temporary_vision_loss = "NO";
			$scope.$ctrl.data.complications.pain_on_injection = "NO";
		};

		$scope.enableComplications = function () {
			$scope.complications_disable = "";
		};

		$scope.showWarn = function () {
			$scope.warn = true;
		}

		$scope.reset = function () {
			if (!$scope.$ctrl.data.complications) {
				$scope.$ctrl.data.complications = {};
			}
			if($scope.$ctrl.data.complications.subconjunctival_haemorrhage &&
			$scope.$ctrl.data.complications.corneal_abrasion &&
			$scope.$ctrl.data.complications.raised_intraocular_pressure &&
			$scope.$ctrl.data.complications.temporary_vision_loss &&
			$scope.$ctrl.data.complications.pain_on_injection){
				let element = angular.element(document.querySelector("#operation-form"));
				$timeout(
					function () {
						if ($scope.$ctrl.submitted) {
							element[0].dispatchEvent(new Event("submit"));
						}
					},
					0,
					false
				);
			}
		};
	},
]);
