app.config(function ($stateProvider, $urlRouterProvider) {
	$stateProvider
		.state("login", {
			url: "/login",
			views: {
				main: {
					templateUrl: "app/src/views/login.html",
					controller: "loginController",
				},
			},
		})

		.state("reset-password", {
			url: "/resetPassword?token",
			views: {
				main: {
					templateUrl: "app/src/views/reset-password.html",
					controller: "resetController",
				},
			},
		})

		.state("welcome", {
			url: "/welcome",
			views: {
				main: {
					templateUrl: "app/src/views/welcome.html",
					controller: "welcomeController",
				},
			},
		})

		.state("admin-console", {
			url: "/admin-console",
			params: {
				type: "",
			},
			views: {
				main: {
					templateUrl: "app/src/views/adminConsole.html",
					controller: "adminController",
				},
			},
		})

		.state("pre-op", {
			url: "/pre-op",
			views: {
				main: {
					templateUrl: "app/src/views/pre-op.html",
					controller: "preOpController",
				},
			},
		})

		.state("refer", {
			url: "/refer",
			views: {
				main: {
					templateUrl: "app/src/views/refer.html",
					controller: "referController",
				},
			},
		})

		.state("refer.search", {
			url: "/search",
			params: {
				isFromCalendar: false,
			},
			parent: "refer",
			templateUrl: "app/src/views/search.html",
			controller: "searchController",
		})

		.state("refer.impact", {
			url: "/impact",
			parent: "refer",
			templateUrl: "app/src/views/impact.html",
			controller: "impactController",
		})

		.state("examination", {
			url: "/examination",
			params: {
				isFromReferral: false,
			},
			views: {
				main: {
					templateUrl: "app/src/views/examination.html",
					controller: "examinationController",
				},
			},
		})
		.state("referral", {
			url: "/referral",
			views: {
				main: {
					templateUrl: "app/src/views/referralScreen.html",
					controller: "referralScreenController",
				},
			},
		})
		.state("refer.complete", {
			url: "/complete",
			parent: "refer",
			templateUrl: "app/src/views/complete.html",
			controller: "completeController",
		})

		.state("injection-note", {
			url: "/injectionNote",
			views: {
				main: {
					templateUrl: "app/src/views/injection-note.html",
					controller: "injectionNoteController",
				},
			},
		})

		.state("clinical-note", {
			url: "/clinicalNote",
			views: {
				main: {
					templateUrl: "app/src/views/clinical-note.html",
					controller: "clinicalNoteController",
				},
			},
		})

		.state("operation-note", {
			url: "/operationNote",
			views: {
				main: {
					templateUrl: "app/src/views/operation-note.html",
					controller: "operationNoteController",
				},
			},
		})
		.state("surgery-agreement", {
			url: "/surgeryAgreement",
			views: {
				main: {
					templateUrl: "app/src/js/components/surgeryAgreement/surgeryAgreement.html",
					controller: "surgeryAgreementController",
				},
			},
		})
		.state("medical-certificate", {
			url: "/medicalCertificate",
			params: {
				isReturnToWork: false,
			},
			views: {
				main: {
					templateUrl: "app/src/js/components/medicalCertificate/medicalCertificate.html",
					controller: "medicalCertificateController",
				},
			},
		})
		.state("patient-verification", {
			url: "/patientVerification?token",
			views: {
				main: {
					templateUrl: "app/src/views/reset-password.html",
					controller: "patientVerificationController",
				},
			},
		})



	$urlRouterProvider.otherwise("/login");
});
