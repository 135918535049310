app.component("emailList", {
	templateUrl: "app/src/js/components/surgeryAgreement/emailList.html",
	controller: "emailListController",
	bindings: {
		data: "<",
	},
});

app.controller("emailListController", [
	"$scope",
	function ($scope) {
        $scope.temp = {email:''}

        this.$onInit = function () {
            if($scope.$ctrl.data && $scope.$ctrl.data.patientEmailCC){
                $scope.emailList = $scope.$ctrl.data.patientEmailCC.split(',');
            }else{
                $scope.emailList = [];
            }
			
		};

        $scope.addEmail = function () {
            $scope.emailList.push($scope.temp.email);
            $scope.$ctrl.data.patientEmailCC = $scope.emailList.join(',');
            $scope.temp.email = '';
        }

        $scope.removeEmail = function (index) {
            $scope.emailList.splice(index, 1);
            $scope.$ctrl.data.patientEmailCC = $scope.emailList.join(',');
        }
    }])