app.component("preOpLifeQualityReferral", {
	templateUrl: "app/src/js/components/preOpLifeQuality/preOpLifeQualityReferral.html",
	controller: "preOpLifeQualityReferralController",
	bindings: {
		data: "=",
	},
});

app.controller("preOpLifeQualityReferralController", [
	"$scope",
	"LIFE_QUALITY_QUESTIONS_REFERRAL",
	function ($scope, LIFE_QUALITY_QUESTIONS_REFERRAL) {
		$scope.questions = LIFE_QUALITY_QUESTIONS_REFERRAL;

		// console.log(`!!! ${JSON.stringify($scope.data)}`)
		$scope.$watch("$ctrl.data", function (newValue, oldValue) {
			if (!newValue) {
				$scope.$ctrl.data = { q1_sight_satisfaction: null, q2_sight_difficulty: null };
				$scope.questions.forEach(item => { $scope.$ctrl.data[item.model] = null })
			}
		}, true);
	},
]);
