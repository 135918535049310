app.service("ShareData", [
	"localStorageService",
	function (localStorageService) {
		var service = {
			clinicianIdHome: null,
            allergies: {
                patientId: null,
                allergyList: [],
                reload: false,
            },
			taskFilters: {
				// practices: [],
				assignedUser_completed: '',
				label_completed: '',
				assignedUser_uncompleted: '',
				label_uncompleted: '',
				selectedPractice: null,
			},
			destroy: function () {
				service.allergies = {};
			},

			setAllergies: function (patientId, allergyList) {
				service.allergies = {patientId, allergyList: angular.copy(allergyList),reload: false};
			},
            reloadAllergies: function (patientId) {
				service.allergies = {patientId, allergyList: [],reload: true};
			},
            clearAllergiesReload:function (patientId) {
				service.allergies = {patientId, allergyList: [],reload: false};
			},
			setHomeclinicianId: function (clinicianId) {
				service.clinicianIdHome = clinicianId;
			},
			setTaskFilters: function (filter){
				service.taskFilters = {...service.taskFilters, ...filter};
			}
		};

		return service;
	},
]);
