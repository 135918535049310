app.component("visionModule", {
	templateUrl: "app/src/js/components/visionRefraction/visionModule.html",
	controller: "visionModuleController",
	bindings: {
		data: "=",
		copyData: "=",
		onSuccess: "<",
	},
});

app.controller("visionModuleController", [
	"$scope",
	"LETTER",
	"CYL",
	"DEVICE",
	"$timeout",
	"LENS_STATUS",
	"NUCLEAR_OPALESCENCE",
	"NUCLEAR_COLOUR",
	"CORTICAL",
	"POST_SUBCAPSULAR",
	"PUPIL_SIZE",
	"VISUAL_POTENTIAL",
	"LINE",
	"PatientVisionAPI",
	"CurrentPatient",
	"$modal",
	"$filter",
	function ($scope, LETTER, CYL, DEVICE, $timeout, LENS_STATUS, NUCLEAR_OPALESCENCE, NUCLEAR_COLOUR, CORTICAL, POST_SUBCAPSULAR, PUPIL_SIZE, VISUAL_POTENTIAL, LINE, PatientVisionAPI, CurrentPatient, $modal, $filter) {
		$scope.isLinkHidden = true;
		$scope.isAcuityVisible = false;
		$scope.preset = false;
		$scope.hasDifference = false;
		$scope.showOU = false;
		$scope.ou_best_show = false;
		$scope.PV = PatientVisionAPI;
		$scope.CP = CurrentPatient;
		$scope.today1 = $filter("date")(new Date(), "dd-MMMM-yyyy");
		$scope.today = $filter("date")(new Date(), "dd/MM/yyyy");
		$scope.currentTime = new Date();
		$scope.currentTime.setSeconds(0,0);

		$scope.life_quality_added = 'NO';

		$scope.clearLifeQuality = function() {
			$scope.$ctrl.data.quality_of_life = null;
		}

		$scope.$watch(
			"$ctrl.data.vision.od.ucvaLine",
			function (newValue, oldValue) {
				if (newValue) {
					$scope.isLinkHidden = false;
				} else if (!$scope.$ctrl.data.vision.os.ucvaLine) {
					$scope.isLinkHidden = true;
					$scope.isAcuityVisible = false;
				}
			},
			true
		);
		$scope.$watch(
			"$ctrl.data.vision.os.ucvaLine",
			function (newValue, oldValue) {
				if (newValue) {
					$scope.isLinkHidden = false;
				} else if (!$scope.$ctrl.data.vision.od.ucvaLine) {
					$scope.isLinkHidden = true;
					$scope.isAcuityVisible = false;
				}
			},
			true
		);

		$scope.$watch(
			"$ctrl.data.vision.od.ucvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.$ctrl.data.vision.os.ucvaLine) {
					$scope.showOU = true;
					setUncorrectedMin();
				} else if (newValue == null) {
					$scope.showOU = false;
				}
			},
			true
		);
		$scope.$watch(
			"$ctrl.data.vision.os.ucvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.$ctrl.data.vision.od.ucvaLine) {
					$scope.showOU = true;
					setUncorrectedMin();
				} else if (newValue == null) {
					$scope.showOU = false;
				}
			},
			true
		);

		$scope.$watch(
			"$ctrl.data.vision.od.bcvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.$ctrl.data.vision.os.bcvaLine) {
					$scope.ou_best_show = true;
					setBestMin();
				} else if (newValue == null) {
					$scope.ou_best_show = false;
					$scope.$ctrl.data.vision.od.bcvaLetter = null;
					$scope.$ctrl.data.vision.od.best_va_correction_type = null;
				}
			},
			true
		);
		$scope.$watch(
			"$ctrl.data.vision.os.bcvaLine",
			function (newValue, oldValue) {
				if (newValue && $scope.$ctrl.data.vision.od.bcvaLine) {
					$scope.ou_best_show = true;
					setBestMin();
				} else if (newValue == null) {
					$scope.ou_best_show = false;
					$scope.$ctrl.data.vision.os.bcvaLetter = null;
					$scope.$ctrl.data.vision.os.best_va_correction_type = null;
				}
			},
			true
		);

		$scope.$watch(
			"$ctrl.data.vision.os.intraocular_pressure_iop",
			function (newValue, oldValue) {
				if (newValue == null) {
					$scope.$ctrl.data.vision.os.intraocular_pressure_device = null;
				}
			},
			true
		);

		$scope.$watch(
			"$ctrl.data.vision.od.intraocular_pressure_iop",
			function (newValue, oldValue) {
				if (newValue == null) {
					$scope.$ctrl.data.vision.od.intraocular_pressure_device = null;
				}
			},
			true
		);

		function setUncorrectedMin() {
			const right = LINE.find((t) => t.value === $scope.$ctrl.data.vision.od.ucvaLine);
			const left = LINE.find((t) => t.value === $scope.$ctrl.data.vision.os.ucvaLine);
			const min = Math.min(parseFloat(right.name), parseFloat(left.name));
			if (min) {
				$scope.$ctrl.data.uncorrected_va_line = LINE.find((t) => t.name === "" + min).value;
			}
		}

		function setBestMin() {
			const right = transformBcvaLine(LINE.find((t) => t.value === $scope.$ctrl.data.vision.od.bcvaLine));
			const left = transformBcvaLine(LINE.find((t) => t.value === $scope.$ctrl.data.vision.os.bcvaLine));
			const min = Math.min(parseFloat(right.name), parseFloat(left.name));

			if (min) {
				let obj = LINE.find((t) => t.name === "" + min);
				let value = undefined;
				if (obj) {
					value = obj.value;
				} else {
					if (min === 1000) {
						value = "CF_30_CM";
					} else if (min === 1001) {
						value = "HM_30_CM";
					} else if (min === 1002) {
						value = "PL";
					} else if (min === 1003) {
						value = "NPL";
					}
				}
				$scope.$ctrl.data.bcvaLine_binocular = value;
			}
			if (min === parseFloat(right.name)) {
				$scope.$ctrl.data.bcvaLetter_binocular = $scope.$ctrl.data.vision.od.bcvaLetter;
			} else {
				$scope.$ctrl.data.bcvaLetter_binocular = $scope.$ctrl.data.vision.os.bcvaLetter;
			}
		}

		function transformBcvaLine(bcvaLine) {
			const obj = { ...bcvaLine };
			if (obj.name === 'CF') {
				obj.name = '1000';
				return obj;
			} else if (obj.name === 'HM') {
				obj.name = '1001';
				return obj;
			} else if (obj.name === 'POL') {
				obj.name = '1002';
				return obj;
			} else if (obj.name === 'NPL') {
				obj.name = '1003';
				return obj;
			}
			return bcvaLine;
		}

		$scope.isButtonDisabled = () => {
			for (const property in $scope.$ctrl.data.vision.od) {
				if ($scope.$ctrl.data.vision.od[property]) {
					return false;
				}
			}

			for (const property in $scope.$ctrl.data.vision.os) {
				if ($scope.$ctrl.data.vision.os[property]) {
					return false;
				}
			}

			if($scope.$ctrl.data.quality_of_life){
				return false;
			}

			return $scope.$ctrl.data.comments == null || $scope.$ctrl.data.comments.length === 0;
		}

		$scope.transformDate = function (date) {
			const array = date.split('/');
			return array[1]+'/'+array[0]+'/'+array[2];
		}

		$scope.submit = () => {
			const transform_data = { ...$scope.$ctrl.data };

			transform_data.vision.od.uncorrected_va_line = transform_data.vision.od.ucvaLine;
			transform_data.vision.od.uncorrected_va_letter = transform_data.vision.od.ucvaLine !== null ? transform_data.vision.od.ucvaLetter : null;
			transform_data.vision.os.uncorrected_va_line = transform_data.vision.os.ucvaLine;
			transform_data.vision.os.uncorrected_va_letter = transform_data.vision.os.ucvaLine !== null ? transform_data.vision.os.ucvaLetter : null;
			transform_data.vision.od.best_va_line = transform_data.vision.od.bcvaLine;
			transform_data.vision.os.best_va_line = transform_data.vision.os.bcvaLine;
			transform_data.vision.od.best_va_letter = transform_data.vision.od.bcvaLine !== null ? transform_data.vision.od.bcvaLetter : null;
			transform_data.vision.os.best_va_letter = transform_data.vision.os.bcvaLine !== null ? transform_data.vision.os.bcvaLetter : null;

			delete transform_data.vision.od.ucvaLine;
			delete transform_data.vision.os.ucvaLine;

			delete transform_data.vision.od.ucvaLetter;
			delete transform_data.vision.os.ucvaLetter;

			delete transform_data.vision.od.bcvaLine;
			delete transform_data.vision.os.bcvaLine;

			delete transform_data.vision.od.bcvaLetter;
			delete transform_data.vision.os.bcvaLetter;

			transform_data.vision.od.refraction_sphere = transform_data.vision.od.sphere;
			transform_data.vision.os.refraction_sphere = transform_data.vision.os.sphere;

			transform_data.vision.od.refraction_cylinder = transform_data.vision.od.cyl;
			transform_data.vision.os.refraction_cylinder = transform_data.vision.os.cyl;

			transform_data.vision.od.refraction_axis = transform_data.vision.od.axis;
			transform_data.vision.os.refraction_axis = transform_data.vision.os.axis;

			transform_data.vision.od.bestcorrected_near_va_line = transform_data.vision.od.bcvaLineNear;
			transform_data.vision.os.bestcorrected_near_va_line = transform_data.vision.os.bcvaLineNear;

			transform_data.vision.od.bestcorrected_near_va_letter = transform_data.vision.od.bcvaLineNear !== null ? transform_data.vision.od.bcvaLetterNear : null;
			transform_data.vision.os.bestcorrected_near_va_letter = transform_data.vision.os.bcvaLineNear !== null ? transform_data.vision.os.bcvaLetterNear : null;

			transform_data.vision.od.bestcorrected_medium_va_line = transform_data.vision.od.bcvaLineMedium;
			transform_data.vision.os.bestcorrected_medium_va_line = transform_data.vision.os.bcvaLineMedium;


			transform_data.vision.od.bestcorrected_medium_va_letter = transform_data.vision.od.bcvaLineMedium !== null ? transform_data.vision.od.bcvaLetterMedium : null;
			transform_data.vision.os.bestcorrected_medium_va_letter = transform_data.vision.os.bcvaLineMedium !== null ? transform_data.vision.os.bcvaLetterMedium : null;

			delete transform_data.vision.od.bcvaLineNear;
			delete transform_data.vision.os.bcvaLineNear;

			delete transform_data.vision.od.bcvaLetterNear;
			delete transform_data.vision.os.bcvaLetterNear;

			delete transform_data.vision.od.bcvaLineMedium;
			delete transform_data.vision.os.bcvaLineMedium;

			delete transform_data.vision.od.bcvaLetterMedium;
			delete transform_data.vision.os.bcvaLetterMedium;

			if (transform_data.vision.od.horizontal_prism > 0) {
				transform_data.vision.od.horizontal_prism_base = 'BASE_IN';
			}

			if (transform_data.vision.od.horizontal_prism < 0) {
				transform_data.vision.od.horizontal_prism = Math.abs(transform_data.vision.od.horizontal_prism);
				transform_data.vision.od.horizontal_prism_base = 'BASE_OUT';
			}

			if (transform_data.vision.os.horizontal_prism > 0) {
				transform_data.vision.os.horizontal_prism_base = 'BASE_IN';
			}

			if (transform_data.vision.os.horizontal_prism < 0) {
				transform_data.vision.os.horizontal_prism = Math.abs(transform_data.vision.os.horizontal_prism);
				transform_data.vision.os.horizontal_prism_base = 'BASE_OUT';
			}

			if (transform_data.vision.od.vertical_prism > 0) {
				transform_data.vision.od.vertical_prism_base = 'BASE_UP';
			}

			if (transform_data.vision.od.vertical_prism < 0) {
				transform_data.vision.od.vertical_prism = Math.abs(transform_data.vision.od.vertical_prism);
				transform_data.vision.od.vertical_prism_base = 'BASE_DOWN';
			}

			if (transform_data.vision.os.vertical_prism > 0) {
				transform_data.vision.os.vertical_prism_base = 'BASE_UP';
			}

			if (transform_data.vision.os.vertical_prism < 0) {
				transform_data.vision.os.vertical_prism = Math.abs(transform_data.vision.os.vertical_prism);
				transform_data.vision.os.vertical_prism_base = 'BASE_DOWN';
			}

			if (transform_data.vision.os.intraocular_pressure_iop == null) {
				transform_data.vision.os.additional_pressure_measurements = null;
			}

			if (transform_data.vision.od.intraocular_pressure_iop == null) {
				transform_data.vision.od.additional_pressure_measurements = null;
			}

			if (transform_data.vision.od.pinhole_va_line == undefined) {
				transform_data.vision.od.pinhole_va_letter = null;
			}

			if (transform_data.vision.os.pinhole_va_line == undefined) {
				transform_data.vision.os.pinhole_va_letter = null;
			}

			const time = `${transform_data.time_of_consult.getHours()}:${transform_data.time_of_consult.getMinutes()}`

			transform_data.date_of_consult = new Date(`${$scope.transformDate($scope.$ctrl.data.date_of_consult)} ${time} UTC`);
		
			delete transform_data.time_of_consult;
			
			if(transform_data.quality_of_life){
				transform_data.quality_of_life.q1_sight_satisfaction = transform_data.quality_of_life.qol_A;
				transform_data.quality_of_life.q2_sight_difficulty = transform_data.quality_of_life.qol_B;
				transform_data.quality_of_life.q3_sight_reading_text = transform_data.quality_of_life.qol_C_1;
				transform_data.quality_of_life.q4_sight_face_recognition = transform_data.quality_of_life.qol_C_2;
				transform_data.quality_of_life.q5_sight_seeing_prices = transform_data.quality_of_life.qol_C_3;
				transform_data.quality_of_life.q6_sight_walking = transform_data.quality_of_life.qol_C_4;
				transform_data.quality_of_life.q7_sight_handcraft = transform_data.quality_of_life.qol_C_5;
				transform_data.quality_of_life.q8_sight_reading_subtitles = transform_data.quality_of_life.qol_C_6;
				transform_data.quality_of_life.q9_sight_engage_activities = transform_data.quality_of_life.qol_C_7;
			}

			if(transform_data.vision.od.refraction_no_improvement == null && transform_data.vision.od.sphere){
				transform_data.vision.od.refraction_no_improvement = false;
			}

			if(transform_data.vision.os.refraction_no_improvement == null && transform_data.vision.os.sphere){
				transform_data.vision.os.refraction_no_improvement = false;
			}

			$scope.PV.isProcessing = true;
			$scope.PV.savePatientVision($scope.CP.patient.id, transform_data).then(function (resp) {
				$scope.$ctrl.onSuccess();
			}, function (error) {
				console.error(error);
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function () {
				$scope.PV.isProcessing = false;
			});
			
		}

	},
]);
