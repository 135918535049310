app.service("ProgressNoteAPI", [
    "$http",
    "ENV",
    "Session",
    function ($http, ENV, Session) {
       var service = {
          isProcessing: false,
          getProgressNotes: function (patientId) {
             return $http({
                method: "GET",
                url: ENV.API + `/api/progress-note/${patientId}`,
                headers: {
                   Authorization: "Bearer " + Session.token,
                   "X-PRACTICE-ID": `${Session.practice.id}`,
                },
             });
          },
          // addAutoTaskProgressNote=true&addAutoTaskWaitlist=true
          createProgressNote: function (progressNote, option) {
            let parameters = '';
            if(option && option.addAutoTaskProgressNote === 'true'){
               parameters = '&addAutoTaskProgressNote=true';
            }else if(option && option.addAutoTaskProgressNote === 'false'){
               parameters = '&addAutoTaskProgressNote=false';
            }

            if(option && option.addAutoTaskWaitlist === 'true'){
               parameters = parameters + '&addAutoTaskWaitlist=true';
            }else if(option && option.addAutoTaskWaitlist === 'false'){
               parameters = parameters + '&addAutoTaskWaitlist=false';
            }

            return $http({
               method: "POST",
               url: ENV.API + `/api/progress-note?sendHL7=true${parameters}`,
               headers: {
                  Authorization: "Bearer " + Session.token,
                  "X-PRACTICE-ID": `${Session.practice.id}`,
               },
               data: progressNote
            });
          },
          deleteProgressNote: function (patientId, noteId) {
            return $http({
               method: "DELETE",
               url: ENV.API + `/api/progress-note/${patientId}/${noteId}`,
               headers: {
                  Authorization: "Bearer " + Session.token,
                  "X-PRACTICE-ID": `${Session.practice.id}`,
               },
            });
          },
          restoreProgressNote: function (patientId, noteId) {
            return $http({
               method: "PUT",
               url: ENV.API + `/api/progress-note/${patientId}/${noteId}`,
               headers: {
                  Authorization: "Bearer " + Session.token,
                  "X-PRACTICE-ID": `${Session.practice.id}`,
               },
            });
          },
          updateVisibility: function (patientId, noteId,level) {
            return $http({
               method: "PUT",
               url: ENV.API + `/api/progress-note/${patientId}/${noteId}/security?securityLevel=${level}`,
               headers: {
                  Authorization: "Bearer " + Session.token,
                  "X-PRACTICE-ID": `${Session.practice.id}`,
               },
            });
          },
       }
        return service;
    }]);