app.service("PatientVisionAPI", [
    "$http",
    "ENV",
    "Session",
    function ($http, ENV, Session) {
        var service = {
			isProcessing: false,
            getPatientVision: function (patientId) {
               return $http({
                  method: "GET",
                  url: ENV.API + `/api/patient-vision/${patientId}/vision-module`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
               });
            },
            savePatientVision: function (patientId, data) {
               return $http({
                  method: "POST",
                  url: ENV.API + `/api/patient-vision/${patientId}/vision-module`,
                  headers: {
                     Authorization: "Bearer " + Session.token,
                     "X-PRACTICE-ID": `${Session.practice.id}`,
                  },
                  data
               });
            }
        }
        return service;
    }]);