app.service('ReferralsAPI', ['$http', 'ENV', 'Session', 'CurrentPatient', 'CurrentReferral', function($http, ENV, Session, CurrentPatient, CurrentReferral) {

	var CP = CurrentPatient;
	var CR = CurrentReferral;
	var service = {

		isProcessing: false,

		startReferral: function() {

			return $http({
				method: 'POST',
				url: ENV.API + '/api/referrals',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': 'application/json'
				},
				data: {
					"referrer": {
						"userId": Session.userId
					},
					"patient": {
						"id": CP.patient.id
					},
					"practice": {
						"id": Session.practice.id
					}
				}
			})
		},

		impact: function(formData) {
			formData.id = CR.referral.id;
			return $http({
				method: 'PUT',
				url: ENV.API + '/api/referrals/' + CR.referral.id  + '/impactOnLife',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': 'application/json'
				},
				data: formData
			})
		},

		clinical: function(formData) {
			formData.id = CR.referral.id;
			return $http({
				method: 'PUT',
				url: ENV.API + '/api/referrals/' + CR.referral.id  + '/clinicalExamination',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': 'application/json'
				},
				data: formData
			})
		},

		complete: function(formData) {
			return $http({
				method: 'PUT',
				url: ENV.API + '/api/referrals/' + CR.referral.id,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': 'application/json'
				},
				data: formData,
				responseType: 'arraybuffer'
			})
		},

		completeManual: function(formData) {
			return $http({
				method: 'PUT',
				url: ENV.API + '/api/referrals/' + CR.referral.id + '/manual',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': 'application/json',
				},
				data: formData,
				responseType: 'arraybuffer'
			})
		},
		saveReferralV2: function(referral) {
			return $http({
				method: 'POST',
				url: ENV.API + '/api/referral/v2?sendHL7=true',
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': 'application/json'
				},
				data: referral,
				responseType: 'arraybuffer'
			})
		},
	};

	return service;
}])