app.service('AppointmentAPI', ['$http', 'ENV', 'Session', function($http, ENV, Session) {

	var service = {
		isProcessing: false,

		bookAppointment: function (data,_practiceId) {
			let practiceId = _practiceId ? _practiceId : Session.practice.id;
			return $http({
				method: "POST",
				url: ENV.API + "/api/appointment",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${practiceId}`,
				},
				data,
			});
		},
		deleteAppointment: function (patientId, appointmentId,_practiceId) {
			let practiceId = _practiceId ? _practiceId : Session.practice.id;
			return $http({
				method: "DELETE",
				url: ENV.API + `/api/appointment/patient/${patientId}/appointment/${appointmentId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${practiceId}`,
				},
			});
		},
		updateAppointment: function (patientId, appointmentId, data,_practiceId) {
			let practiceId = _practiceId ? _practiceId : Session.practice.id;
			return $http({
				method: "PUT",
				url: ENV.API + `/api/appointment/patient/${patientId}/appointment/${appointmentId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${practiceId}`,
				},
				data,
			});
		},
		queryPatientAppointment: function (clinicianId, start, endDateUTC,_practiceId) {
			let practiceId = _practiceId ? _practiceId : Session.practice.id;
			return $http({
				method: 'GET',
				url: ENV.API + `/api/appointment/practice?clinicianId=${clinicianId}&startDateUTC=${start}&endDateUTC=${endDateUTC}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${practiceId}`,
				},
			});
		},
		downloadAppointmentList: function (data,_practiceId) {
			let practiceId = _practiceId ? _practiceId : Session.practice.id;
			return $http({
				method: "POST",
				url: ENV.API + '/api/appointment/practice/print',
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${practiceId}`,
				},
				responseType: "arraybuffer",
				data,
			});
		},
		getAppointmentUsers: function (practiceId){
			let id = practiceId ? practiceId : Session.practice.id;
			return $http({
				method: 'GET',
				url: ENV.API + `/api/users/appointment-users`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${id}`,
				},
			});
		},
		getAppointmentsForPatient: function (patientId) {
			return $http({
				method: 'GET',
				url: ENV.API + `/api/appointment/patient/${patientId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		getAppointmentReminderSetting: function(userId) {
			return $http({
				method: 'GET',
				url: ENV.API + `/api/appointment-reminder/user/${userId}/settings`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		updateAppointmentReminderSetting: function(userId,applyToAllUserPractices,data) {
			return $http({
				method: 'PUT',
				url: ENV.API + `/api/appointment-reminder/user/${userId}/settings?applyToAllUserPractices=${applyToAllUserPractices}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data
			});
		},
		downloadFile: function(userId,name,categoryId) {
			return $http({
				method: "GET",
				url: ENV.API + `/api/appointment-reminder/user/${userId}/settings/file?fileName=${name}&categoryId=${categoryId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				responseType: "arraybuffer",
			});
		},
		deleteFile: function(userId,name,categoryId) {
			return $http({
				method: "DELETE",
				url: ENV.API + `/api/appointment-reminder/user/${userId}/settings/file?fileName=${name}&categoryId=${categoryId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		uploadFile: function(userId,fileName,categoryId,blob) {
			const formData = new FormData();
			formData.append('fileName', fileName);
			formData.append('file', blob, fileName);

			return $http({
				method: 'POST',
				url: ENV.API + `/api/appointment-reminder/user/${userId}/settings/file?categoryId=${categoryId}`,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
					'Content-Type': undefined,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data:formData,
				transformRequest: angular.identity
			})
		},
		sendReminder: function(appointmentId, patientId) {
			return $http({
				method: 'POST',
				url: ENV.API + `/api/appointment-reminder/patient/${patientId}/appointment/${appointmentId}`,
				headers: {
					'Authorization': 'Bearer ' + Session.token,
                    "X-PRACTICE-ID": `${Session.practice.id}`,
				},
			})
		},
		getReminderAudit: function(appointmentId, _practiceId) {
			let practiceId = _practiceId ? _practiceId : Session.practice.id;
			return $http({
				method: 'GET',
				url: ENV.API + `/api/appointment-reminder/${appointmentId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${practiceId}`,
				},
			});
		}
	};

	return service;
}])
