app.component("calendarSetting", {
	templateUrl: "app/src/views/templates/calendar-setting.tmpl.html",
	controller: "calendarSettingController",
});



app.controller('calendarSettingController',['$scope', '$mdDialog', 'PracticeService', "Session","$modal","APPOINTMENT_TYPE",
function($scope, $mdDialog, PracticeService,Session,$modal,APPOINTMENT_TYPE){
   $scope.myColor = '#FF0000';
   $scope.APPOINTMENT_TYPE = APPOINTMENT_TYPE;
   $scope.practiceAPI = PracticeService;
   $scope.appointment_categories = [];
   $scope.$mdDialog = $mdDialog;
   $scope.newType = {colour: 'FFFFFF', duration: 10};
   $scope.Session = Session;

   // options - if a list is given then choose one of the items. The first item in the list will be the default
$scope.options = {
	hue: true,
	saturation: false,
	lightness: false,
	alpha: false,
	inline: true,
	format: "hex",
	round: false,
	inline: false,
	inputClass: "form-control",
	dynamicHue: false,
	dynamicLightness: false,
	dynamicSaturation: false,
	dynamicAlpha: false,
};

function querySetting() {
    $scope.practiceAPI.queryCalendarSetting()
    .then(
        function (resp) {
            $scope.appointment_categories = resp.data.appointment_categories;
        },
        function (error) {
            console.error(error);
        }
    );
}
querySetting();

$scope.errors = [];

$scope.renderErrorMessage = function (name, category_id) {
    const val = name && $scope.appointment_categories.find(t=>t.name === name && t.category_id !== category_id);
    if(val && !$scope.errors.some(t=> t === category_id)){
        $scope.errors.push(category_id);
    }else if(!val){
        $scope.errors = $scope.errors.filter(t=> t !== category_id);
    }
    return {
        duplicate: val
    };
}

$scope.updateSetting = function(){
    if($scope.appointment_categories.some(t=>!t.name) || $scope.errors.length > 0){
        return;
    }
    $scope.practiceAPI.updateCalendarSetting({appointment_categories: $scope.appointment_categories})
    .then(
        function (resp) {
            $scope.$mdDialog.hide();
        },
        function (error) {
            console.error(error);
            $scope.serverErrorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                show: true,
                title: "CatTrax has encountered an error",
            });
        }
    );
}

$scope.addSetting = function(){
    $scope.practiceAPI.updateCalendarSetting({appointment_categories: [$scope.newType]})
    .then(
        function (resp) {
            $scope.newType = {colour: 'FFFFFF', duration: 10};
            querySetting();
        },
        function (error) {
            console.error(error);
            $scope.serverErrorModal = $modal({
                scope: $scope,
                templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                show: true,
                title: "CatTrax has encountered an error",
            });
        }
    );
}


}]);