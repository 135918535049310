app.controller("settingTabsController", [
	"$scope",
	"AppointmentAPI",
	"PracticeService",
    "TasksAPI",
	function ($scope, AppointmentAPI, PracticeService,TasksAPI) {
		$scope.API = AppointmentAPI;
		$scope.PracticeAPI = PracticeService;
        $scope.TasksAPI = TasksAPI;
	},
]);