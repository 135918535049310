app.service("ClinicalNoteAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,
			submitClinicalNote: function (requestData) {
				return $http({
					method: "POST",
					url: ENV.API + "/api/clinical_note/",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: requestData,
				});
			}
		};
		return service;
	},
]);
