app.component("phacoSection", {
	templateUrl: "app/src/js/components/operationNote/phacoSection/phacoSection.html",
	controller: "phacoSectionController",
	bindings: {
		data: "<",
		eyeTitle: "@",
	},
});

app.controller("phacoSectionController", [
	"$scope",
	"PHACOMACHINE",
	"PHACOTECHNIQUE",
	"PHACOIRRIGATION",
	function ($scope, PHACOMACHINE, PHACOTECHNIQUE, PHACOIRRIGATION) {
		$scope.slider = {
			value: 5,
			options: {
			  floor: 2,
			  ceil: 10,
			  showTicksValues: true
			}
		  };
		$scope.PHACOMACHINE = PHACOMACHINE.map((t) => t);
		$scope.PHACOTECHNIQUE = PHACOTECHNIQUE.map((t) => t);
		$scope.PHACOIRRIGATION = PHACOIRRIGATION.map((t) => t);
		$scope.testvalue = true;

		this.$onChanges = function (changesObj) {
			if (changesObj.data.currentValue) {
				if (changesObj.data.currentValue.phaco) {
					if (
						changesObj.data.currentValue.phaco.technique &&
						angular.isString(changesObj.data.currentValue.phaco.technique)
					) {
						const obj = $scope.PHACOTECHNIQUE.find((t) => t.id === changesObj.data.currentValue.phaco.technique);

						if (obj) {
							$scope.$ctrl.technique = {
								val: {
									id: null,
									name: obj.name,
								},
							};
						} else {
							const appendObj = {
								id: changesObj.data.currentValue.phaco.technique,
								name: changesObj.data.currentValue.phaco.technique,
							};
							$scope.PHACOTECHNIQUE.push(appendObj);
							$scope.$ctrl.technique = {
								val: {
									id: null,
									name: appendObj.name,
								},
							};
						}
					}else if(!changesObj.data.currentValue.phaco.technique){
						// change 'Procedure' type: phaco object is empty
						$scope.$ctrl.technique = {
							val: null
						};
					}

					if (
						changesObj.data.currentValue.phaco.irrigation &&
						angular.isString(changesObj.data.currentValue.phaco.irrigation)
					) {
						const obj = $scope.PHACOIRRIGATION.find((t) => t.id === changesObj.data.currentValue.phaco.irrigation);

						if (obj) {
							$scope.$ctrl.irrigation = {
								val: {
									id: null,
									name: obj.name,
								},
							};
						} else {
							const appendObj = {
								id: changesObj.data.currentValue.phaco.irrigation,
								name: changesObj.data.currentValue.phaco.irrigation,
							};
							$scope.PHACOIRRIGATION.push(appendObj);
							$scope.$ctrl.irrigation = {
								val: {
									id: null,
									name: appendObj.name,
								},
							};
						}
					}else if(!changesObj.data.currentValue.phaco.irrigation){
						// change 'Procedure' type: phaco object is empty
						$scope.$ctrl.irrigation = {
							val: null
						};
					}
				}
			}
		};

		$scope.onChange_technique = function () {
			if (angular.isString($scope.$ctrl.technique.val)) {
				$scope.$ctrl.technique.val = {
					id: $scope.$ctrl.technique.val,
					name: $scope.$ctrl.technique.val,
				};
				if (!$scope.PHACOTECHNIQUE.find((t) => t.name === $scope.$ctrl.technique.val.name)) {
					$scope.PHACOTECHNIQUE.push($scope.$ctrl.technique.val);
				}
			}
			if($scope.$ctrl.data.phaco == null){
				$scope.$ctrl.data.phaco = {};
			}
			$scope.$ctrl.data.phaco.technique = $scope.$ctrl.technique.val.id;
		};

		$scope.onChange_irrigation = function () {
			if (angular.isString($scope.$ctrl.irrigation.val)) {
				$scope.$ctrl.irrigation.val = {
					id: $scope.$ctrl.irrigation.val,
					name: $scope.$ctrl.irrigation.val,
				};
				if (!$scope.PHACOIRRIGATION.find((t) => t.name === $scope.$ctrl.irrigation.val.name)) {
					$scope.PHACOIRRIGATION.push($scope.$ctrl.irrigation.val);
				}
			}
			if($scope.$ctrl.data.phaco == null){
				$scope.$ctrl.data.phaco = {};
			}
			$scope.$ctrl.data.phaco.irrigation = $scope.$ctrl.irrigation.val.id;
		};
	},
]);
