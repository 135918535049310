app.service("OperationAPI", [
	"$http",
	"ENV",
	"Session",
	"OperationNote",
	function ($http, ENV, Session, OperationNote) {
		const mockResponse = {
			workup_id: "40ad187f-548a-4e77-ae62-a0ae0a4dea6b",
			patient_id: 12,
			completed_by_user_id: 123,
			surgeon_user_id: null,
			assistant_user_id: null,
			status: "COMPLETED",
			date_created: "2020-09-08T20:12:05",
			date_updated: "2020-09-20T11:15:52",
			op_note_od: {
				operative_eye: "YES",
				procedure_type: "PHACO_IOL",
				anaesthetic: "TOPICAL_INTRACAMERAL",
				incisions: [
					{
						size: 1.1,
						enlargement: "NO",
						location_degree: 90,
						location_type: "CORNEAL",
					},
				],
				ovd: ["Duovisc", "Healon", "test"],
				ccc: {
					dilated_pupil_size: "_7_5mm",
					pupil_expander: "IRIS_HOOKS",
					technique_type: "Cystotome",
					hydrodissection_type: "ANAESTHETIC",
				},
				phaco: {
					machine: "Infinity",
					technique: "divide_and_conquer",
					irrigation: "unipak",
					ultrasound_energy: 90,
				},
				iol: {
					lens_type: "TORIC",
					lens_model: "My lens model",
					lens_power_spherical: -0.5,
					lens_placement: "sulcus",
				},
				intraoperative_medications: [
					{
						medication: "subconjunctival cefazolin",
					},
				],
				adjuvant_procedures: {
					vision_blue: "YES",
					intracameral_phenylephrine: "YES",
					triamcinolone: "NO",
					iris_hooks: "NO",
					malyugan_ring: "YES",
					planned_anterior_vitrectomy: "YES",
					unplanned_anterior_vitrectomy: "NO",
					unplanned_posterior_vitrectomy: "YES",
					sulcus: "NO",
					capsular_tension_ring: "NO",
					intravitreal_injection: "NO",
					migs: "NO",
					corneal_suture: "YES",
					other: [
						{
							text: "something...",
						},
					],
				},
				complications: {
					iris_prolapse: "YES",
					anterior_capsular_runout: "NO",
					zonular_dialysis: "NO",
					posterior_capsular_rupture: "NO",
					vitreous_loss: "YES",
					dropped_nucleus: "YES",
					wound_leak: "NO",
					wound_burn: "NO",
					suprachoridal_haemorrhage: "YES",
					other: [
						{
							text: "something...",
						},
					],
				},
				comment: "free texts...",
			},
			op_note_os: {
				operative_eye: "YES",
				procedure_type: "PHACO_IOL",
				anaesthetic: "TOPICAL_INTRACAMERAL",
				incisions: [
					{
						size: 1.1,
						enlargement: "NO",
						location_degree: 90,
						location_type: "CORNEAL",
					},
				],
				ovd: ["Duovisc"],
				ccc: {
					dilated_pupil_size: "_4mm",
					pupil_expander: "NONE",
					technique_type: "CYSTOTOME",
					hydrodissection_type: "ANAESTHETIC",
				},
				phaco: {
					machine: "Infinity",
					technique: "divide_and_conquer",
					irrigation: "unipak",
					ultrasound_energy: 90,
				},
				iol: {
					lens_type: "TORIC",
					lens_model: "My lens model",
					lens_power_spherical: -0.5,
					lens_placement: "sulcus",
				},
				intraoperative_medications: [
					{
						medication: "subconjunctival cefazolin",
					},
				],
				adjuvant_procedures: {
					vision_blue: "YES",
					intracameral_phenylephrine: "YES",
					triamcinolone: "NO",
					iris_hooks: "NO",
					malyugan_ring: "YES",
					planned_anterior_vitrectomy: "YES",
					unplanned_anterior_vitrectomy: "NO",
					unplanned_posterior_vitrectomy: "YES",
					sulcus: "NO",
					capsular_tension_ring: "NO",
					intravitreal_injection: "NO",
					migs: "NO",
					corneal_suture: "YES",
					other: [
						{
							text: "something...",
						},
					],
				},
				complications: {
					iris_prolapse: "YES",
					anterior_capsular_runout: "NO",
					zonular_dialysis: "NO",
					posterior_capsular_rupture: "NO",
					vitreous_loss: "YES",
					dropped_nucleus: "YES",
					wound_leak: "NO",
					wound_burn: "NO",
					suprachoridal_haemorrhage: "YES",
					other: [
						{
							text: "something...",
						},
					],
				},
				comment: "free texts...",
			},
			postop_medications: [
				{
					medication: "free text",
					dose: "one or free text",
					frequency: 1,
					route: "OD",
					duration: 14,
				},
			],
			postop_care: {
				duration: "OTHER",
				other_date: "2020-09-30",
				clinician: "any text...",
			},
		};

		var service = {
			isProcessing: false,
			getOperationNotes: function (patientId) {
				return $http({
					method: "GET",
					url: ENV.API + "/api/operation_note/patient/" + patientId + "/summary",
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
				})
					.then(function (response) {
						if (response.data.length > 0) {
							OperationNote.setNoteHistory(angular.copy(response.data));
							OperationNote.setData({});
						} else {
							const newRecord = {
								completed_by_user_id: Session.userId,
								patient_id: patientId,
								surgeon_user_id: null,
								assistant_user_id: null,
								practice_id: Session.practice.id,
								op_note_od: {},
								op_note_os: {},
								postop_care: {
									duration: null,
								},
							};
							OperationNote.setData(newRecord);
							OperationNote.setNoteHistory([]);
						}
					})
					.catch(function (err) {
						console.log(`${JSON.stringify(err)}`);
						// OperationNote.setData(mockResponse);
					});
			},
			getWorkUp: function (patientId) {
				return $http({
					method: "GET",
					url: ENV.API + "/api/operation_note/patient/" + patientId,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
				})
					.then(function (response) {
						// console.log(`res=${JSON.stringify(response)}`);
						// OperationNote.setData(mockResponse);
						if (response.data.operation_note_id == null) {
							const newRecord = {
								...response.data,
								completed_by_user_id: Session.userId,
								patient_id: patientId,
								surgeon_user_id: null,
								assistant_user_id: null,
								practice_id: Session.practice.id,
								op_note_od: {},
								op_note_os: {},
								postop_care: {
									duration: null,
								},
							};
							OperationNote.setData(newRecord);
						} else {
							OperationNote.setData(response.data);
						}
					})
					.catch(function (err) {
						console.log(`${JSON.stringify(err)}`);
						// OperationNote.setData(mockResponse);
					});
			},
			submitWorkUp: function (requestData, option) {

				const parameters = [];
				if (option && option.addAutoTaskHistology === "true") {
					parameters.push("addAutoTaskHistology=true");
				} else if (option && option.addAutoTaskHistology === "false") {
					parameters.push("addAutoTaskHistology=false");
				}
	
				if(option && option.addAutoTaskOpNote === 'true'){
					parameters.push('addAutoTaskOpNote=true');
				}else if(option && option.addAutoTaskOpNote === 'false'){
					parameters.push('addAutoTaskOpNote=false');
				}

				return $http({
					method: "POST",
					url: ENV.API + `/api/operation_note/${parameters.length > 0 ? `?${parameters.join('&')}` : ''}`,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					responseType: "arraybuffer",
					data: requestData,
				});
			},
			addNewComment: function (patient_id, operation_note_uuid, commentBody) {
				return $http({
					method: "POST",
					url: ENV.API + `/api/operation_note/patient/${patient_id}/${operation_note_uuid}/comments`,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: commentBody,
				});
			},
			saveDefaultSetting: function (body) {

				let otherSetting_od = null;
				let otherSetting_os = null; 

				if (body.op_note_od && body.op_note_od.procedure_type === 'OTHER') {
					otherSetting_od = {
						...body.op_note_od.other_procedure,
						instructions_for_patient: body.op_note_od.instructions_for_patient,
						anaesthetic: body.op_note_od.anaesthetic,
						postop_medications: body.postop_medications,
						intraoperative_medications: body.op_note_od.intraoperative_medications,
					};
					body.op_note_od = null;
				}
				if (body.op_note_os && body.op_note_os.procedure_type === 'OTHER') {
					otherSetting_os = {
						...body.op_note_os.other_procedure,
						instructions_for_patient: body.op_note_os.instructions_for_patient,
						anaesthetic: body.op_note_os.anaesthetic,
						postop_medications: body.postop_medications,
						intraoperative_medications: body.op_note_os.intraoperative_medications,
					};
					body.op_note_os = null;
				}
				// If we don't have a non-other procedure in either eye, don't save body.postop_medications.
				if (!((body.op_note_od && body.op_note_od.procedure_type !== 'OTHER') || (body.op_note_os && body.op_note_os.procedure_type !== 'OTHER'))) {
					body.postop_medications = null;
				}
			
				const dataTransform = {
					user_id: Session.userId,
					practice_id: body.practice_id,
					date_created: null,
					date_updated: null,
					op_note_settings: {
						op_note_od_settings: body.op_note_od,
						op_note_os_settings: body.op_note_os,
						postop_medications: body.postop_medications,
						postop_care: body.postop_care,
						op_note_od_other_procedure_settings: otherSetting_od,
						op_note_os_other_procedure_settings: otherSetting_os,
					},
				};
				return $http({
					method: "POST",
					url: ENV.API + "/api/operation_note/settings",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: dataTransform,
				});
			},
			getDefaultSetting: function (surgeonId,patientId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/operation_note/settings/${surgeonId}?practiceId=${Session.practice.id}&patientId=${patientId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
				})
					.then(function (response) {
						OperationNote.setDefaultSetting(response.data);
					})
					.catch(function (err) {
						console.log(`${JSON.stringify(err)}`);
						// OperationNote.setData(mockResponse);
					});
			},
			downloadPDF: function (patientId, operationNoteId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/operation_note/patient/${patientId}/${operationNoteId}/pdf`,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					responseType: "arraybuffer",
				});
			},
		};
		return service;
	},
]);
