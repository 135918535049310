app.component("appointmentSetting", {
	templateUrl: "app/src/views/templates/appointment-setting.tmpl.html",
	controller: "appointmentSettingController",
});

app.controller("appointmentSettingController", [
	"$scope",
	"$mdDialog",
	"AppointmentAPI",
	"Session",
	"$modal",
	"FileUploader",
	"PracticeService",
	function ($scope, $mdDialog, AppointmentAPI, Session, $modal, FileUploader, PracticeService) {
		this.$onInit = function () {
			$scope.API = AppointmentAPI;
			$scope.PracticeAPI = PracticeService;
			$scope.$mdDialog = $mdDialog;
			$scope.Session = Session;
			$scope.setting = null;
			$scope.applyToAllUserPractices = false;

			$scope.userName = $scope.Session.user.firstName || "" + $scope.Session.user.lastName || "";

			$scope.userPractice = $scope.Session.practice.name;

			$scope.sms_placeholder =
				"Hello [PATIENT_NAME],\n\n" +
				"This is a friendly reminder that you have booked an appointment with [PRACTICE_NAME] on [APPOINTMENT_DATE_TIME].\n\n" +
				"If you need to make any changes to your appointment, please contact the practice office at [PRACTICE_PHONE].";

			$scope.email_placeholder =
				"<p>Dear [PATIENT_NAME],</p><p>This is an automated reminder that you have an appointment scheduled with [PRACTICE_NAME] on [APPOINTMENT_DATE_TIME].</p><p><br></p><p>If you have any queries or need to make any changes to your appointment, please contact the practice office at [PRACTICE_PHONE].</p><p><br></p><p> We look forward to seeing you soon.</p><p><br></p><p> </p><p>Kind regards, </p><p>The [PRACTICE_NAME] Team</p>";

			fetchAppointmentCategory();
		};

		var uploader = ($scope.uploader = new FileUploader({
			queueLimit: 1,
		}));

		$scope.change = function (contents) {
			console.log("contents are changed:", contents);
		};

		$scope.options = {
			height: 100,
			focus: true,
			disableResizeEditor: true,
			inheritPlaceholder: true,
			disableDragAndDrop: true,
			dialogsInBody: true,
			toolbar: [["insert", ["link"]]],
		};

		function readFileInputEventAsArrayBuffer(file, callback) {
			var reader = new FileReader();

			reader.onload = function (loadEvent) {
				var arrayBuffer = loadEvent.target.result;
				callback(arrayBuffer);
			};

			reader.readAsArrayBuffer(file);
		}

		uploader.onAfterAddingFile = function (fileItem) {
			console.log(fileItem);

			const type = fileItem._file.type;

			document.getElementById("uploadCaptureInputFile").value = "";
			$scope.uploader.clearQueue();

			let size = 0;

			if($scope.setting.file_data) {
				size =  $scope.setting.file_data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
			}

			if (fileItem.file.size > (10 * 1024 * 1024 - size)) {
				return alert(`All files' maximum size is 10MB.`);
			}

			readFileInputEventAsArrayBuffer(fileItem._file, function (data) {
				var blobData = [data];
				var blob = new Blob(blobData, { type });

				$scope.API.isProcessing = true;
				$scope.API.uploadFile($scope.Session.user.id, fileItem._file.name, $scope.setting.appointment_category_id,blob)
					.then(
						function (resp) {
							fetchSetting();
						},
						function (error) {
							showErrorDialog();
						}
					)
					.then(() => ($scope.API.isProcessing = false));
			});
		};

		$scope.popoverDownload = {
			content: "Download",
		};

		$scope.popoverFileDelete = {
			content: "Delete",
		};

		$scope.dropped = function (dragEl, dropEl) {
			if (!dragEl) {
				return;
			}

			if (dropEl === "custom_email_text") {
				$scope.setting.custom_email_text =
					($scope.setting.custom_email_text ? $scope.setting.custom_email_text : "") + ` ${dragEl} `;
			} else if (dropEl === "custom_sms_text") {
				$scope.setting.custom_sms_text = ($scope.setting.custom_sms_text ? $scope.setting.custom_sms_text : "") + ` ${dragEl} `;
			}

			$scope.$apply();
		};

		function download(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		$scope.downloadFile = function (name) {
			$scope.API.isProcessing = true;
			$scope.API.downloadFile($scope.Session.user.id, name,$scope.setting.appointment_category_id)
				.then(
					function (resp) {
						download(resp.data, name);
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		};

		$scope.deleteFile = function (name) {
			$scope.API.isProcessing = true;
			$scope.API.deleteFile($scope.Session.user.id, name, $scope.setting.appointment_category_id)
				.then(
					function (resp) {
						fetchSetting();
						// download(resp.data, name);
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		};

		function fetchAppointmentCategory() {
			$scope.PracticeAPI.isProcessing = true;
			$scope.PracticeAPI.queryCalendarSetting()
				.then(
					function (resp) {
						$scope.appointment_categories = resp.data.appointment_categories;
					},
					function (error) {
						console.error(error);
					}
				)
				.then(function () {
					$scope.PracticeAPI.isProcessing = false;
					fetchSetting();
				});
		}

		function connectData(data) {
			return data.category_reminder_settings.map((t) => {
				const target = $scope.appointment_categories.find((m) => m.category_id === t.appointment_category_id);
				return { ...t, name: target ? target.name : "unknown", id: t.appointment_category_id};
			});
		}

		$scope.settingId = { id: null };

		function fetchSetting() {
			$scope.API.isProcessing = true;
			$scope.API.getAppointmentReminderSetting($scope.Session.user.id)
				.then(
					function (response) {
						$scope.settingOptions = connectData(response.data);
						if (!$scope.setting) {
							$scope.settingId.id = $scope.settingOptions[0].id;
							$scope.onSettingCatalogChange();
						}
						$scope.onSettingCatalogChange();
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		}

		$scope.onSettingCatalogChange = function () {
			$scope.setting = $scope.settingOptions.find((t) => t.id === $scope.settingId.id);
			if ($scope.setting && !$scope.setting.days_to_remind_before_email) {
				$scope.setting.days_to_remind_before_email = 1;
			}
			if ($scope.setting && !$scope.setting.days_to_remind_before_sms) {
				$scope.setting.days_to_remind_before_sms = 1;
			}

			if ($scope.setting && !$scope.setting.custom_email_text) {
				$scope.setting.custom_email_text =  $scope.email_placeholder;
			}
			if ($scope.setting && !$scope.setting.custom_sms_text) {
				$scope.setting.custom_sms_text = $scope.sms_placeholder;
			}
		};

		$scope.save = function () {
			$scope.API.isProcessing = true;
			$scope.API.updateAppointmentReminderSetting($scope.Session.user.id, $scope.applyToAllUserPractices, {
				category_reminder_settings: $scope.settingOptions,
			})
				.then(
					function (success) {
						$scope.$mdDialog.hide();
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(() => ($scope.API.isProcessing = false));
		};

		$scope.positiveClick = () => {
			if ($scope.serverErrorModal) {
				$scope.serverErrorModal.hide();
				$scope.$mdDialog.hide();
			}
		};

		function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				backdrop: "static",
				keyboard: false,
				title: "CatTrax has encountered an error",
			});
		}
	},
]);
