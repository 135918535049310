app.component("cccSection", {
	templateUrl: "app/src/js/components/operationNote/cccSection/cccSection.html",
	controller: "cccSectionController",
	bindings: {
		data: "<",
		eyeTitle: "@",
	},
});

app.controller("cccSectionController", [
	"$scope",
	"PUPILEXPANDER",
	"CCCTECHNIQUE",
	"HYDRODISSECTION",
	"PUPILEXPANDER",
	"$timeout",
	function ($scope, PUPILEXPANDER, CCCTECHNIQUE, HYDRODISSECTION, PUPILEXPANDER, $timeout) {
		$scope.slider = {
			// value: 5,
			options: {
				// floor: 2,
				// ceil: 10,
				step: 0.5,
				precision: 1,
				showTicksValues: true,
				onChange: function (sliderId, modelValue, highValue, pointerType) {
					$scope.$ctrl.data.ccc.dilated_pupil_size = modelValue;
				},
				stepsArray: [
					{ value: 2, legend: "2" },
					{ value: 2.5, legend: "" },
					{ value: 3, legend: "3" },
					{ value: 3.5, legend: "" },
					{ value: 4, legend: "4" },
					{ value: 4.5, legend: "" },
					{ value: 5, legend: "5" },
					{ value: 5.5, legend: "" },
					{ value: 6, legend: "6" },
					{ value: 6.5, legend: "" },
					{ value: 7, legend: "7" },
					{ value: 7.5, legend: "" },
					{ value: 8, legend: "8" },
					{ value: 8.5, legend: "" },
					{ value: 9, legend: "9" },
					{ value: 9.5, legend: "" },
					{ value: 10, legend: "10" },
				],
				getTickColor: function (value) {
					if (value % 2 === 0) return "transparent";
					return "#3850a2";
				},
			},
		};

		$scope.options = PUPILEXPANDER.map((t) => t);
		$scope.CCCTECHNIQUE = CCCTECHNIQUE.map((t) => t);

		this.$onChanges = function (changesObj) {
			if (changesObj.data.currentValue) {
				if (changesObj.data.currentValue.ccc) {
					$timeout(function () {
						$scope.$broadcast("rzSliderForceRender");
					}, 100);
					if (
						changesObj.data.currentValue.ccc.dilated_pupil_size &&
						angular.isString(changesObj.data.currentValue.ccc.dilated_pupil_size)
					) {
						const val = changesObj.data.currentValue.ccc.dilated_pupil_size;
						const subStr = val.substring(1, val.length - 2);
						
						if (subStr.indexOf("_") === -1) {
							$scope.dilated_pupil_size = parseInt(subStr);
						} else {
							$scope.dilated_pupil_size = parseInt(subStr[0]) + 0.5;
						}
						const obj = $scope.options.find((t) => t.id === changesObj.data.currentValue.ccc.pupil_expander);

						if (obj) {
							$scope.$ctrl.expander = {
								val: {
									id: null,
									name: obj.name,
								},
							};
						} else {
							const appendObj = {
								id: changesObj.data.currentValue.ccc.pupil_expander,
								name: changesObj.data.currentValue.ccc.pupil_expander,
							};
							$scope.options.push(appendObj);
							$scope.$ctrl.expander = {
								val: {
									id: null,
									name: appendObj.name,
								},
							};
						}
					}

					if (
						changesObj.data.currentValue.ccc.technique_type &&
						angular.isString(changesObj.data.currentValue.ccc.technique_type)
					) {
						const obj = $scope.CCCTECHNIQUE.find((t) => t.id === changesObj.data.currentValue.ccc.technique_type);

						if (obj) {
							$scope.$ctrl.technique = {
								val: {
									id: null,
									name: obj.name,
								},
							};
						} else {
							const appendObj = {
								id: changesObj.data.currentValue.ccc.technique_type,
								name: changesObj.data.currentValue.ccc.technique_type,
							};
							$scope.CCCTECHNIQUE.push(appendObj);
							$scope.$ctrl.technique = {
								val: {
									id: null,
									name: appendObj.name,
								},
							};
						}
					}
				}else{
					//when saving an op note of type IOL_ADDON, the phaco section is {} and the ccc section has {technique_type: ""}. These should both be null because those sections are hidden from the user. At the moment this is causing a mapping issue in prod and the pdf isn't being generated.
					// changesObj.data.currentValue.ccc = {};
				}
			}
		};

		$scope.PUPILEXPANDER = PUPILEXPANDER;
		$scope.HYDRODISSECTION = HYDRODISSECTION;

		$scope.onChange = function () {
			if (angular.isString($scope.$ctrl.expander.val)) {
				$scope.$ctrl.expander.val = {
					id: $scope.$ctrl.expander.val,
					name: $scope.$ctrl.expander.val,
				};
				if (!$scope.options.find((t) => t.name === $scope.$ctrl.expander.val.name)) {
					$scope.options.push($scope.$ctrl.expander.val);
				}
			}
			$scope.$ctrl.data.ccc.pupil_expander = $scope.$ctrl.expander.val.id;
		};

		$scope.onChange_technique = function () {
			if (angular.isString($scope.$ctrl.technique.val)) {
				$scope.$ctrl.technique.val = {
					id: $scope.$ctrl.technique.val,
					name: $scope.$ctrl.technique.val,
				};
				if (!$scope.CCCTECHNIQUE.find((t) => t.name === $scope.$ctrl.technique.val.name)) {
					$scope.CCCTECHNIQUE.push($scope.$ctrl.technique.val);
				}
			}
			$scope.$ctrl.data.ccc.technique_type = $scope.$ctrl.technique.val.id;
		};

		$scope.onSelect = function (item, model) {
		};
	},
]);
