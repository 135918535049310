app.component("progressNoteForm", {
	templateUrl: "app/src/js/components/progressNote/progressNoteForm.html",
	controller: "progressNoteFormController",
	bindings: {
        data: "=",
		onSuccess: "<",
	},
});

app.controller("progressNoteFormController", [
	"$scope",
	"$filter",
	"ProgressNoteAPI",
	"$modal",
	"Session",
	function ($scope, $filter, ProgressNoteAPI, $modal, Session) {
		$scope.PNAPI = ProgressNoteAPI;
		$scope.today = $filter("date")(new Date(), "dd-MMMM-yyyy");
		$scope.practiceName = Session.practice.name;
		$scope.isDHBPublic = Session.practice.type === 'DHB_PUBLIC';

		function transformDate(value) {
			// dd/MM/yyyy
			const array = value.split('/');
			return array[2] + '-' + array[1] + '-' + array[0];
		}

		$scope.isButtonDisabled = function() {
			let val = false;
			if(!$scope.$ctrl.data.follow_up_type){
				return true;
			}
			if($scope.$ctrl.data.is_soap_format){
				val = !$scope.$ctrl.data.subjective_note && !$scope.$ctrl.data.objective_note && !$scope.$ctrl.data.assessment_note && !$scope.$ctrl.data.plan_note;
			}else{
				val = !$scope.$ctrl.data.free_text;
			}


			if($scope.$ctrl.data.booked_for_surgery_section.booked_for_surgery){
				const body = $scope.$ctrl.data.booked_for_surgery_section;
				if (body.preferred_timeframe_type === "WEEK") {
					val = !body.timeframe_number_of_weeks;
				} else if (body.preferred_timeframe_type === "RANGE") {
					val = !body.timeframe_before_date || !body.timeframe_after_date;
				}
			}

			if(!val){
				return $scope.$ctrl.data.follow_up_type === 'OTHER' && $scope.$ctrl.data.follow_up_date == null;
			}

			return val;
		}

		$scope.primaryProcedureODCallback = function(form){
			if(form && form.diagnosis_object){
				$scope.$ctrl.data.booked_for_surgery_section.primary_procedure_od = {
					snomed_concept: {
						concept_id: form.diagnosis_object.conceptId,
						fsn: form.diagnosis_object.fsn,
						pt: form.diagnosis_object.pt,
					},
					laterality: "OD",
				}
			}else{
				$scope.$ctrl.data.booked_for_surgery_section.primary_procedure_od = null;
			}
		}

		$scope.primaryProcedureOSCallback = function(form){
			if (form && form.diagnosis_object) {
				$scope.$ctrl.data.booked_for_surgery_section.primary_procedure_os = {
					snomed_concept: {
						concept_id: form.diagnosis_object.conceptId,
						fsn: form.diagnosis_object.fsn,
						pt: form.diagnosis_object.pt,
					},
					laterality: "OS",
				};
			} else {
				$scope.$ctrl.data.booked_for_surgery_section.primary_procedure_os = null;
			}
		}

		$scope.secondaryProcedureODCallback = function(form){
			if(!$scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list){
				$scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list = [];
			}
			const os = $scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list.filter(t=>t.laterality === 'OS');
			if (form) {
				const array = form.map((t) => ({
					snomed_concept: {
						concept_id: t.conceptId,
						fsn: t.fsn,
						pt: t.pt,
					},
					laterality: "OD",
				}));
				$scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list = [...array,...os];
			}
		}

		$scope.secondaryProcedureOSCallback = function(form){
			if(!$scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list){
				$scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list = [];
			}
			const od = $scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list.filter(t=>t.laterality === 'OD');
			if (form) {
				const array = form.map((t) => ({
					snomed_concept: {
						concept_id: t.conceptId,
						fsn: t.fsn,
						pt: t.pt,
					},
					laterality: "OS",
				}));
				$scope.$ctrl.data.booked_for_surgery_section.secondary_procedure_list = [...array,...od];
			}
		}
        // dd/mm/yyyy to yyyy-mm-dd
		$scope.convertDate = function (date) {
			if(date){
				array = date.split('/');
				return new Date(array[2] + '-' + array[1] + '-' + array[0]).toString();
			}
			return new Date().toString();
		}

		$scope.createProgressNoteOption = {};

		$scope.onAutomationTaskChange = function(event) {
			if(event.type === 'surgery_waitlist_automation_settings'){
				$scope.createProgressNoteOption.addAutoTaskWaitlist = event.isAutomationTaskEnabled;
			}else if(event.type === 'progress_note_automation_settings'){
				$scope.createProgressNoteOption.addAutoTaskProgressNote = event.isAutomationTaskEnabled;
			}
		}
		

		$scope.submit = function () {
			const data = {
				...$scope.$ctrl.data,
				date_of_consult: transformDate($scope.$ctrl.data.date_of_consult),
				time_of_consult: $filter("date")(new Date($scope.$ctrl.data.time_of_consult), "HH:mm:ss"),
				date_of_entry: transformDate($scope.$ctrl.data.date_of_entry),
				time_of_entry: $filter("date")(new Date($scope.$ctrl.data.time_of_entry), "HH:mm:ss"),
			}

			if (data.booked_for_surgery_section.booked_for_surgery) {
				const body = data.booked_for_surgery_section;
				body.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				if (body.preferred_timeframe_type === "ASAP") {
					delete body.timeframe_number_of_weeks;
					delete body.timeframe_before_date_UTC;
					delete body.timeframe_after_date_UTC;
				} else if (body.preferred_timeframe_type === "WEEKS") {
					delete body.timeframe_before_date_UTC;
					delete body.timeframe_after_date_UTC;
					// delete body.preferred_timeframe_type;
				} else if (body.preferred_timeframe_type === "DATES") {
					delete body.timeframe_number_of_weeks;
					// delete body.preferred_timeframe_type;
					
					body.timeframe_before_date_UTC = moment.utc(transformDate(body.timeframe_before_date)).toDate().toISOString();
					body.timeframe_after_date_UTC = moment.utc(transformDate(body.timeframe_after_date)).toDate().toISOString();
				}
			} else {
				data.booked_for_surgery_section = { booked_for_surgery: false };
			}
            
			$scope.PNAPI.isProcessing = true;
			$scope.PNAPI.createProgressNote(data, $scope.createProgressNoteOption).then(function (resp) {
				$scope.$ctrl.onSuccess();
				// $scope.$broadcast('trigger:automation-task');
			}, function (error) {
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function () {
				$scope.PNAPI.isProcessing = false;
			})
		}
	},
]);
