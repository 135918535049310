app.service("PremiumLensAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,

			getSavedPriceList: function (userId, practiceId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/premium_lenses/setting/user/${userId}?practiceId=${practiceId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
				});
			},
            getEmailsForPatient: function (patientId, practiceId) {
                return $http({
					method: "GET",
					url: ENV.API + `/api/premium_lenses/patient/${patientId}?practiceId=${practiceId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
				});
            },
            submitPrice: function (body) {
				return $http({
					method: "POST",
					url: ENV.API + "/api/premium_lenses/",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: body,
				});

            }
		};

		return service;
	},
]);
