app.service('CurrentPatient', ['Session','AppointmentTimerService','PatientAuditAPI', function(Session,AppointmentTimerService,PatientAuditAPI) {

	var service = {

		patient: {},
		patientBackup: {},
		patientIsMine: false,
		eligibilitySummaryFirstLoad: true,
		isPatientNew: false,
		isPresetNewPatient: false,
		query: {},
	    patients: [],

		setPatient: function(patient, appointment) {
			if(service.patient && service.patient.id !== patient.id){
				AppointmentTimerService.stopTimer();
			}
			service.isPatientNew = false;
			service.isPresetNewPatient = false;
			if(patient.id !== service.patient.id && patient.practice.id === Session.practice.id){
				PatientAuditAPI.startAudit(patient.id);
			}
			service.patient = patient;
			service.patient.appointment = appointment;
			service.patientBackup = angular.copy(patient);
			if (service.patient && service.patient.practice.id === Session.practice.id) {
				service.patientIsMine = true;
			} else {
				service.patientIsMine = false;
			}
		
			
		},

		clearPatient: function() {
			service.isPatientNew = false;
			service.isPresetNewPatient = false;
			if(service.patient && service.patient.id){
				PatientAuditAPI.endAudit(service.patient.id);
			}
			service.patient = {};
			service.eligibilitySummaryFirstLoad = true;
			
		},

		createNewPatient: function() {
			service.isPatientNew = true;
		},

		createNewPatientWithPreset: function() {
			service.isPatientNew = true;
			service.isPresetNewPatient = true;
		},

		setPatientResult: function(patients, form) {
			service.patients = patients;
			service.query = form;
		},
	
		clearPatientResult: function() {
			service.patients = [];
			service.query = {};
			service.isPatientNew = false;
			service.isPresetNewPatient = false;
			if(service.patient && service.patient.id){
				PatientAuditAPI.endAudit(service.patient.id);
			}
			service.patient = {};
			service.eligibilitySummaryFirstLoad = true;
		},


	};

	return service;
}])