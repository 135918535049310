app.component("preOpMenu", {
	templateUrl: "app/src/js/components/preOp/preOpMenu/preOpMenu.html",
	controller: "preOpMenuController",
	bindings: {
		showButton: '<',
		hide: "&",
	},
});

app.controller("preOpMenuController", [
	"$scope",
	"$state",
	"Session",
	"CurrentPatient",
	"ContactAPI",
	"PatientAPI",
	"ReferralsAPI",
	"CurrentReferral",
	"FEATURE_AGREEMENT_SURGERY",
	function ($scope, $state, Session, CurrentPatient, ContactAPI, PatientAPI, ReferralsAPI, CurrentReferral,FEATURE_AGREEMENT_SURGERY) {
		$scope.CP = CurrentPatient;
		$scope.userIsPracticeAdmin = false;
		$scope.showWorkupAndInjectionButton = false;
		$scope.showOperationNoteButton = false;
		$scope.ContactAPI = ContactAPI;
		$scope.PA = PatientAPI;
		$scope.RA = ReferralsAPI;
		$scope.CR = CurrentReferral;
		$scope.FEATURE_AGREEMENT_SURGERY = FEATURE_AGREEMENT_SURGERY;
		$scope.startClinicalNote = function () {
			$state.go("clinical-note");
		}

		$scope.clinicalExamination = function () {
            $scope.PA.isProcessing = true;
            $scope.RA.startReferral().then(
                function (resp) {
                    $scope.CR.setReferral(resp.data);
                    // $scope.patientDetailsModal.hide();
                    $scope.PA.isProcessing = false;
                    $state.go("examination");
                },
                function (err) {
                    console.log(err);
                    $scope.PA.isProcessing = false;
                }
            );
        };

		$scope.startInjectionNote = function () {
			$state.go("injection-note");
		}

		$scope.startWorkUp = function () {
			$state.go("pre-op");
		};

		$scope.startOperationNote = function () {
			$state.go("operation-note");
		};

		$scope.openSurgeryAgreement = function () {
		//    $scope.$ctrl.hide();
        //    $scope.$ctrl.openSurgeryAgreement();
			$state.go("surgery-agreement");
		}

		$scope.openMedicalCertificate = function (isReturnToWork) {
			$state.go('medical-certificate',{isReturnToWork});
		}

		if (Session.user.roles.length === 1 && Session.user.roles[0] === "PRACTICE_ADMIN") {
			$scope.userIsPracticeAdmin = true;
		}
        // console.log(`=======${Session.practice.type}=====${JSON.stringify(Session.user)}`);
		if (Session.user.roles[0] === "OPTOMETRIST" && ['PRIVATE','DHB_PUBLIC'].includes(Session.practice.type)) {
			$scope.showWorkupAndInjectionButton = true;
		} else if(['TECHNICIAN','GP','REGISTERED_NURSE','CLINICAL_NURSE_SPECIALIST','REGISTRAR','OPHTHALMOLOGIST', 'ADMIN'].some(t => t === Session.user.roles[0])){
			$scope.showWorkupAndInjectionButton = true;
		} else {
			$scope.showWorkupAndInjectionButton = false;
		}

		                 
		if(['REGISTRAR','OPHTHALMOLOGIST', 'ADMIN'].some(t => t === Session.user.roles[0])){
			$scope.showOperationNoteButton = true;
		}else{
			$scope.showOperationNoteButton = false;
		}
	},
]);
