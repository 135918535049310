app.controller("medicalCertificateController", [
	"$scope",
	"SurgeryAgreementAPI",
	"$modal",
	"CurrentPatient",
	"Session",
	"$filter",
	"$window",
    "$stateParams",
	function ($scope,SurgeryAgreementAPI,$modal,CurrentPatient,Session,$filter,$window,$stateParams) {
		this.$onInit = function () {
			$scope.today = $filter("date")(new Date(), "dd-MMMM-yyyy");
			$scope.CP = CurrentPatient;
            $scope.isReturnToWork = $stateParams.isReturnToWork;

            if($scope.isReturnToWork){
                $scope.form = {
                    patient_id: $scope.CP.patient.id,
                    patient_email: $scope.CP.patient.email,
                    user_id: Session.user.id,
                    practice_id: Session.practice.id,
                    patient_certificate_type: 'RETURN_TO_WORK',
                    _date_of_consult_UTC: moment().utc().toISOString(),
                    timezone: "Pacific/Auckland",
                };
            }else {
                $scope.form = {
                    patient_id: $scope.CP.patient.id,
                    patient_email: $scope.CP.patient.email,
                    user_id: Session.user.id,
                    practice_id: Session.practice.id,
                    patient_certificate_type: 'MEDICAL_CERTIFICATE',
                    timezone: "Pacific/Auckland",
                };
            }

			$scope.SAAPI = SurgeryAgreementAPI;
            $scope.saved_user_signature_image = null;
            fetchSignature();
		};

        $scope.boundingBox = {
			width: 600,
			height: 600,
		};

        	// One could simply use Canvas#toBlob method instead, but it's just to show
		// that it can be done using result of SignaturePad#toDataURL.
		function dataURLToBlob(dataURL) {
			// Code taken from https://github.com/ebidel/filer.js
			const parts = dataURL.split(";base64,");
			const contentType = parts[0].split(":")[1];
			const raw = window.atob(parts[1]);
			const rawLength = raw.length;
			const uInt8Array = new Uint8Array(rawLength);

			for (let i = 0; i < rawLength; ++i) {
				uInt8Array[i] = raw.charCodeAt(i);
			}

			return new Blob([uInt8Array], { type: contentType });
		}

        $scope.useClinicianSignature = function(accept){
			$scope.signatureClinician = accept();
			if ($scope.signatureClinician.isEmpty) {
				$scope.signatureClinician.dataUrl = null;
			}
			if (!$scope.signatureClinician.dataUrl) {
				return;
			}
			const userBlob = dataURLToBlob($scope.signatureClinician.dataUrl);
			$scope.SAAPI.isProcessing = true;
			$scope.SAAPI.uploadSignature(Session.user.id, userBlob)
				.then(
					function (resp) {
						if($scope.signatureModal){
                            $scope.signatureModal.hide();
                        }
						
					},
					function (error) {
						showErrorDialog();
					}
				)
				.then(() => ($scope.SAAPI.isProcessing = false));
		}

        function fetchSignature(){
            $scope.SAAPI.isProcessing = true;
            $scope.SAAPI.fetchMedicalCertificateSetting(Session.user.id).then(
				function (resp) {
                    if(resp.data && resp.data.patient_certificate_settings && resp.data.patient_certificate_settings.saved_user_signature_image){
                        $scope.saved_user_signature_image = resp.data.patient_certificate_settings.saved_user_signature_image;
                        const saved_user_signature_image =
								"data:image/png;base64," + $scope.saved_user_signature_image;
							$scope.signatureClinician = { dataUrl: saved_user_signature_image };
                    }
                    $scope.signatureModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.preview-sign.tpl.html",
                        show: true,
                        title: "PREVIEW SIGNATURE",
                        backdrop: "static",
                        keyboard: false,
                    });
				},
				function (err) {
					showErrorDialog();
				}
			)
			.then(function () {
				$scope.SAAPI.isProcessing = false;
			});
        }

		$scope.positiveClick = ()=>{
			if ($scope.serverErrorModal) {
                $scope.serverErrorModal.hide();
                $window.history.back();
            }
		}

		function showErrorDialog() {
			$scope.serverErrorModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
				show: true,
				title: "CatTrax has encountered an error",
			});
		}
    
		$scope.submit = function (){
            $scope.form.date_of_consult_UTC = moment($scope.form._date_of_consult_UTC).utc().toISOString();
            $scope.form.leave_end_date_UTC = moment($scope.form._leave_end_date_UTC).utc().toISOString();

            if(!$scope.isReturnToWork){
                const duration = $scope.form.duration.split('-');
                $scope.form.leave_start_date_UTC = moment(duration[0]).utc().toISOString();
                $scope.form.leave_end_date_UTC = moment(duration[1]).utc().toISOString();
            }

			const form = angular.copy($scope.form);
		
			$scope.SAAPI.isProcessing = true;
			
			$scope.SAAPI.createMedicalCertificate($scope.CP.patient.id,form).then(
				function (resp) {
                    downloadFile(resp.data,getFileName());
                    $window.history.back();
				},
				function (err) {
					showErrorDialog();
				}
			)
			.then(function () {
				$scope.SAAPI.isProcessing = false;
			});


		}

		function getFileName() {
			var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
			return (
				`CatTrax_${$scope.form.patient_certificate_type}_` +
				theTime +
				"_" +
				$scope.CP.patient.firstName +
				"_" +
				$scope.CP.patient.lastName +
				"_" +
				$scope.CP.patient.dateOfBirth +
				".pdf"
			);
		}

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}
	},
]);
