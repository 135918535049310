app.component("procedureSection", {
	templateUrl: "app/src/js/components/injectionNote/procedureSection/procedureSection.html",
	controller: "procedureSectionController",
	bindings: {
		data: "<",
		eyeTitle: "@",
	},
});

app.controller("procedureSectionController", [
	"$scope",
	"INJECTIONPROCEDURE",
	"INJECTIONANAESTHETIC",
	"INJECTIONPREPARATION",
	"INJECTIONLOCATION",
	"DISTANCELIMBUS",
	"INDICATIONTYPE",
	"INCISIONSIZE",
	"OCULARTYPE",
	function ($scope, 
		INJECTIONPROCEDURE, 
		INJECTIONANAESTHETIC, 
		INJECTIONPREPARATION, 
		INJECTIONLOCATION, 
		DISTANCELIMBUS, 
		INDICATIONTYPE, 
		INCISIONSIZE,
		OCULARTYPE) {
		$scope.injectionProcedure = INJECTIONPROCEDURE;
		$scope.anaesthetic = INJECTIONANAESTHETIC;
		$scope.preparation = INJECTIONPREPARATION;
		$scope.ocularOptions =OCULARTYPE;
		$scope.injection_location = INJECTIONLOCATION;
		$scope.distance_from_limbus = DISTANCELIMBUS;
		$scope.indicationType = INDICATIONTYPE;
		$scope.incisionSize = INCISIONSIZE.map((t) => t);
		$scope.ocularModel  = [];

		$scope.$watch("$ctrl.data.ocular", function (newValue, oldValue) {
			if (newValue) {
				newValue.forEach(element => {
					const target = $scope.ocularModel.find(v=>v.id === element);
					if(!target){
						$scope.ocularModel.push(OCULARTYPE.find(t=>t.id === element));
					}
				});
			}
		}, true);

		const onOcularUpdate = function () {
			$scope.$ctrl.data.ocular = $scope.ocularModel.map(t => t.id);
			
        };

        $scope.ocularOptionEvents = {
            onSelectionChanged: onOcularUpdate,
        }


        $scope.ocularOptionSettings = {
            displayProp: 'name',
            smartButtonMaxItems: 3,
            smartButtonTextConverter: function (itemText) {
                return itemText;
            },
            buttonClasses: "btn btn-dropdown-multiselect",
            showCheckAll: false,
            showUncheckAll: false,
            enableSearch: true,
            scrollableHeight: '20em',
	        scrollable: true
        };
		$scope.selection = [];
	},
]);
