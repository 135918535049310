app.service("ENV", [
	function() {
		var service = {};

		if (window.location.origin.indexOf("localhost") !== -1) {
			service.API = "https://dev.cattrax.co.nz/cattrax-rest-api";
		} else {
			service.API = "cattrax-rest-api";
		}

		if (window.location.origin === "https://www.cattrax.co.nz") {
			// Test for production URL
			service.TAG = "GTM-PNZKFMK";
		} else {
			// Otherwise using this tag for dev/test environments
			service.TAG = "GTM-M3MBBJJ";
		}

		return service;
	}
]);
