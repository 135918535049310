app.controller("impactController", [
	"$scope",
	"CurrentPatient",
	"Session",
	"CurrentReferral",
	"$timeout",
	"ReferralsAPI",
	"$state",
	"$rootScope",
	"$modal",
	function ($scope, CurrentPatient, Session, CurrentReferral, $timeout, ReferralsAPI, $state, $rootScope, $modal) {
		$scope.RA = ReferralsAPI;
		$scope.CR = CurrentReferral;
		$scope.CP = CurrentPatient;

		// Set the CP.eligibilitySummaryFirstLoad as this can only trigger once having arrived on this screen
		$scope.CP.eligibilitySummaryFirstLoad = false;

		$scope.todaysDate = new Date();
		$scope.recentlyCompletedForm = false;

		if ($scope.CR.referral.cpacImpactOnLife === null) {
			$scope.recentlyCompletedForm = false;
		} else {
			$scope.recentlyCompletedForm = true;
		}

		$scope.isReferrerRole = ['TECHNICIAN', 'OPTOMETRIST', 'GP', 'REGISTERED_NURSE', 'CLINICAL_NURSE_SPECIALIST', 'REGISTRAR', 'OPHTHALMOLOGIST', 'ADMIN'].some(t => Session.user.roles.includes(t));

		$scope.popoverSocial = {
			title: "Social Interactions",
			content:
				"How much does your condition affect you social interactions? (Examples: meeting friends, going out, joining in groups, going shopping, everyday activities outside the home)",
		};

		$scope.popoverRelationship = {
			title: "Personal Relationships",
			content:
				"How much does your condition affect your personal relationships? (Examples: personal relations with partner, family members, close personal friends)",
		};

		$scope.popoverResponsibilities = {
			title: "Ability to meet your responsibilities to others",
			content:
				"How much does your condition affect your ability to meet your responsibilities to others? (Examples: doing meaningful things for yourself or others, including caring for children, grandchildren or partner; work (both paid and unpaid), including ‘having to rely on others’ may have an impact on you)",
		};

		$scope.popoverCare = {
			title: "Personal Care",
			content:
				"How much does your condition affect your personal care? (Examples: looking after yourself, your health, personal hygiene, need for aids or special clothing)",
		};

		$scope.popoverSafety = {
			title: "Personal Safety",
			content:
				"How much does your condition affect your personal safety? (Examples: being safe from injury and harm; from yourself, or others, and in your surroundings",
		};

		$scope.popoverLeisure = {
			title: "Leisure Activities",
			content:
				"How much does your condition affect your leisure activities? (Examples: getting exercise, hobbies, sporting activities, gardening, do-it-yourself activities, crafts, travel)",
		};

		$scope.warningHasShown = false;

		$scope.submit = function (values) {
			if ($scope.warningHasShown === false) {
				$scope.warningHasShown = true;
				return false;
			}

			$scope.RA.isProcessing = true;
			$scope.RA.impact(values).then(
				function (resp) {
					$scope.CR.setReferral(resp.data);
					$scope.RA.isProcessing = false;
					$state.go("refer.complete");
				},
				function (err) {
					console.log(err);
					$scope.RA.isProcessing = false;
				}
			);
		};
	},
]);
