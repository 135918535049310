app.service("AppointmentTimerService", [
	function () {
		var service = {
            currentPatientId: null,
            timerId: null,
            duration: 0,
            intervalCB: null,
            startTimer: function(patientId) {
                if(service.currentPatientId === patientId || service.timerId){
                    return;
                }
                service.currentPatientId = patientId;
                // service.stopCB = stopCB;
                const startTime = Date.now();
                service.timerId = setInterval(()=>{
                    service.duration = Date.now()-startTime;
                    console.log(service.duration)
                    if(service.intervalCB){
                        service.intervalCB(service.duration)
                    }
                    // console.log(service.duration);
                    // intervalCB(aa)
                }, 1000);
            },
            injectCallBack: function(callback){
                service.intervalCB = callback;
            },
            stopTimer: function () {
                // 1) log out
                // 2) switch patient
                service.currentPatientId = null;
                if (service.timerId) {
					clearInterval(service.timerId);
					service.timerId = null;
				}
                service.duration = 0;
                service.intervalCB = null;
                // if(service.stopCB){
                //     service.stopCB();
                //     service.stopCB = null;
                // }
            }
		};

		return service;
	},
]);
