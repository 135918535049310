app.component("preOpHistoryGlaucomaSurgery", {
	templateUrl: "app/src/js/components/preOpHistory/preOpHistoryGlaucoma/preOpHistoryGlaucomaSurgery/preOpHistoryGlaucomaSurgery.html",
	controller: "preOpHistoryGlaucomaSurgeryController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("preOpHistoryGlaucomaSurgeryController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		this.$onInit = function () {
			this.PreOp = PreOp;
		};
	},
]);
