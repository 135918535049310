app.controller("clinicalNoteController", [
	"$scope",
	"ClinicalNoteAPI",
	"NHI",
	"$timeout",
	"Session",
	"CurrentPatient",
	"$state",
	"$filter",
	"$q",
	"$modal",
	"UserService",
	"OPERATIONPROCEDURE",
	"SurgeryAPI",
	"PreOp",
	"$window",
	function (
		$scope,
		ClinicalNoteAPI,
		NHI,
		$timeout,
		Session,
		CurrentPatient,
		$state,
		$filter,
		$q,
		$modal,
		UserService,
		OPERATIONPROCEDURE,
		SurgeryAPI,
		PreOp,
		$window
	) {
		$scope.CP = CurrentPatient;
		$scope.API = ClinicalNoteAPI;
		$scope.clinicalNoteHeader = 'VIRTUAL CONSULT';
		$scope.ClinicalNote = {};
		$scope.displaySpinner = false;

		$scope.backDashboard = function () {
			$window.history.back();
		}

		$scope.submitClinicalNote = function () {
			$scope.displaySpinner = true;
			const request = {
				patient_id: $scope.CP.patient.id,
                practice_id: Session.practice.id,
				clinical_note: $scope.clinical_note
			}
			$scope.API.submitClinicalNote(request).then(function (resp) {
				$scope.backDashboard();
			}, function (error) {
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function (){
				$scope.API.isProcessing = false;
				$scope.displaySpinner = false;
			});
		}
	},
]);
