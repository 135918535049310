app.service('TasksAPI', ['$http', 'ENV', 'Session', function($http, ENV, Session) {

	var service = {
		isProcessing: false,

		createTask: function (data) {
			return $http({
				method: "POST",
				url: ENV.API + "/api/task",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data:{...data,timezone:"Pacific/Auckland"},
			});
		},
		deleteTask: function (practiceId,taskId) {
			return $http({
				method: "DELETE",
				url: ENV.API + `/api/task/practice/${practiceId}/task/${taskId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		updateTask: function (taskId,practiceId, data) {
			return $http({
				method: "PUT",
				url: ENV.API + `/api/task/practice/${practiceId}/task/${taskId}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data:{...data,timezone:"Pacific/Auckland"},
			});
		},
		addTaskLabel: function(data) {
			return $http({
				method: "POST",
				url: ENV.API + "/api/task/label",
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data,
			});
		},
		updateTaskLabel: function(id,data) {
			return $http({
				method: "PUT",
				url: ENV.API + `/api/task/label/${id}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data,
			});
		},
		queryTaskLabels: function(){
			return $http({
				method: 'GET',
				url: ENV.API + `/api/task/label`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		queryCreatedTasks: function (startDateUTC, endDateUTC) {
			return $http({
				method: 'GET',
				url: ENV.API + `/api/task?startDateUTC=${startDateUTC}&endDateUTC=${endDateUTC}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
        queryAssignedTasks: function (startDateUTC, endDateUTC) {
			return $http({
				method: 'GET',
				url: ENV.API + `/api/task/assigned?startDateUTC=${startDateUTC}&endDateUTC=${endDateUTC}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		getUsers: function(practice_id){
			const practiceId = practice_id ? practice_id : Session.practice.id;
			return $http({
				method: 'GET',
				url: ENV.API + `/api/users/task-users`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${practiceId}`,
				},
			});
		},
		getTaskSetting: function(){
			return $http({
				method: 'GET',
				url: ENV.API + `/api/task/user/${Session.user.id}/settings?practiceId=${Session.practice.id}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
			});
		},
		updateTaskSetting: function(data){
			return $http({
				method: 'POST',
				url: ENV.API + `/api/task/user/${Session.user.id}/settings?practiceId=${Session.practice.id}`,
				headers: {
					Authorization: "Bearer " + Session.token,
					"X-PRACTICE-ID": `${Session.practice.id}`,
				},
				data
			});
		}
	};

	return service;
}])
