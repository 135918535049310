app.component("recentPatients", {
	templateUrl: "app/src/js/directives/recentPatients.html",
	controller: "recentPatientsController",
	bindings: {
        createTask: "&",
	},
});

app.controller("recentPatientsController", [
	"$scope",
	"$modal",
	"$timeout",
	"Session",
	"CurrentPatient",
	"PatientAPI",
    "$state",
	"UserService",
	function ($scope, $modal, $timeout,  Session, CurrentPatient, PatientAPI,$state,UserService) {
		$scope.CP = CurrentPatient;
		$scope.userIsPracticeAdmin = false;
		$scope.showWorkupButton = false;
		$scope.showOperationNoteButton = false;
		$scope.PA = PatientAPI;
		$scope.recentPatientList = [];
		$scope.patientBackup = null;
		$scope.Object = Object;
		$scope.isTaskEnabled = Session.practice.practiceFeatures.sendTasksWithinPracticeFeature;

		this.$onInit = function () {
			UserService.getRecentPatients().then(
				function (resp) {
					$scope.recentPatientList = Object.groupBy(
						resp.data.map((t) => ({ ...t, dateAdded: trim(t.dateAdded) })),
						({ dateAdded }) => formateDate(dateAdded)
					);
				},
				function (error) {
					console.error(error);
				}
			);
		}

		function formateDate(date){
			const array = date.split('-');
			return `${array[2]}/${array[1]}/${array[0]}`
		}

		function trim(date){
			return date.split('T')[0];
		}


		$scope.cancelTransfer = function () {
			$scope.CP.setPatient($scope.patientBackup);
		}

		$scope.showTransferModal = function () {
			$scope.patientDetailsModal.hide();
			$scope.previousPracticeName = $scope.CP.patient.practice.name;
			$scope.transferModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.transfer.tpl.html",
				show: true,
				title: "TRANSFER PATIENT",
			});
		};

		$scope.transferPatient = function () {
			$scope.PA.isProcessing = true;
			$scope.PA.transfer($scope.CP.patient.id, Session.userId, Session.practice.id)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
                    $state.go("refer.search");
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.transferModal.hide();
					$scope.successModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.success.tpl.html",
						show: true,
						title: "SUCCESS",
						onHide: function () {},
					});
				});
		};
	

		$scope.selectPatient = function (patient) {
			if($scope.CP.patientBackup){
				$scope.patientBackup = angular.copy($scope.CP.patientBackup);
			}
			$scope.CP.clearPatient();
			$timeout(function () {
				$scope.CP.setPatient(patient);
				if (!$scope.CP.patientIsMine) {
					//need to transfer
					$scope.patientDetailsModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.patient-details.tpl.html",
						show: true,
						title: "PATIENT DETAILS",
					});
				}else{
                    $state.go("refer.search");
                }
			});

			
		};

		$scope.switchPatient = function (patientId) {
			// $scope.$ctrl.hide();
			if($scope.CP.patient.id === patientId){
				return;
			}
			$scope.PA.isProcessing = true;
			$scope.PA.getPatient(patientId).then(function (resp){
				$scope.selectPatient(resp.data);
			}, function (error) {
				$scope.serverErrorModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
					show: true,
					title: "CatTrax has encountered an error",
				});
			}).then(function (){
				$scope.PA.isProcessing = false;
			});
		}
	},
]);
