app.component("retinopathyPopUp", {
	templateUrl: "app/src/js/components/preOpHistory/preOpDiabetes/retinopathyPopUp/retinopathyPopUp.html",
	controller: "retinopathyPopUpController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("retinopathyPopUpController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		$scope.preop = PreOp;
	},
]);
