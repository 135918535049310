app.service("PracticeService", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,

			getAvailablePractices: function () {
				return $http({
					method: "GET",
					url: ENV.API + "/api/practices/summary",
					headers: {
						Authorization: "Bearer " + Session.token,
					},
				});
			},
			queryUserSetting: function () {
				return $http({
					method: "GET",
					url: ENV.API + `/api/practices/${Session.practice.id}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
			updateCalendarSetting: function (data) {
				return $http({
					method: "PUT",
					url: ENV.API + `/api/practices/${Session.practice.id}/calendar`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${Session.practice.id}`,
					},
					data,
				});
			},
			queryCalendarSetting: function (_practiceId) {
				let practiceId = _practiceId ? _practiceId : Session.practice.id;
				return $http({
					method: "GET",
					url: ENV.API + `/api/practices/${practiceId}/calendar`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${practiceId}`,
					},
				});
			},
			querySMSAudit: function (_practiceId, start,end){
				let practiceId = _practiceId ? _practiceId : Session.practice.id;
				return $http({
					method: "GET",
					url: ENV.API + `/api/chart/practice/${practiceId}/sms?startDateUTC=${start}&endDateUTC=${end}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"X-PRACTICE-ID": `${practiceId}`,
					},
				});
			}
		};

		return service;
	},
]);