app.component("preOpEmail", {
	templateUrl: "app/src/js/components/preOp/preOpEmail/preOpEmail.html",
	controller: "preOpEmailController",
	bindings: {
		hide: "&",
	},
});

app.controller("preOpEmailController", [
	"$scope",
	"CurrentPatient",
	"PatientAPI",
	"$timeout",
	function ($scope, CurrentPatient, PatientAPI, $timeout) {
		this.$onInit = function () {
			// $scope.preop = PreOp;
			$scope.PA = PatientAPI;
			// this.PA = PatientAPI;
			$scope.CP = CurrentPatient;
            $scope.patientEmail = {value: null};
			// $scope.insuranceOptions = MEDICAL_INSURANCE;
			// $scope.insuranceProvider = $scope.CP.patient.insuranceProvider;
		};

		$timeout(function () {
			// focus on input when component opens
			document.querySelector("#patientEmail").focus();
		}, 200);

		$scope.savePatientEmail = function (email) {
			$scope.CP.patient.email = email;
			$scope.PA.isProcessing = true;
			$scope.PA.update($scope.CP.patient)
				.then(function (resp) {
					$scope.CP.setPatient(resp.data);
				})
				.then(function () {
					$scope.PA.isProcessing = false;
					$scope.$ctrl.hide();
				})
				.catch(function (err) {
					$scope.PA.isProcessing = false;
					console.log(err);
				});
		};
	},
]);
