app.component("injectionIntraoperativeSection", {
	templateUrl: "app/src/js/components/injectionNote/intraoperativeSection/intraoperativeSection.html",
	controller: "injectionIntraoperativeSectionController",
	bindings: {
		data: "=",
		eyeTitle: "@",
	},
});

app.controller("injectionIntraoperativeSectionController", [
	"$scope",
	function ($scope) {
	},
]);
