app.component("patientDiagnosis", {
	templateUrl: "app/src/js/components/patientDiagnosis/patientDiagnosis.html",
	controller: "patientDiagnosisController",
    bindings: {
		setDiagnosis: "&",
		diagnosisType: "@"
	},
});

app.controller("patientDiagnosisController", [
	"$scope",
	"CurrentPatient",
	"DiagnosisAPI",
	"$timeout",
	function ($scope, CurrentPatient, DiagnosisAPI, $timeout) {
		this.$onInit = function () {
            $scope.noResults = false;
            $scope.DiagnosisAPI = DiagnosisAPI;
			$scope.formData = {
				diagnosis: "",
                operative_eye: "",
                search_term: "",
				diagnosis_object: null,
				diagnosis_type: $scope.$ctrl.diagnosisType
			};
		};
		// $scope.result = [];
        $scope.loading = false;

		$scope.$watch(
			"formData.diagnosis",
			function (newValue, oldValue) {
				if (newValue === '') {
					$scope.noResults = false;
				}

				if ($scope.formData.diagnosis_object && $scope.formData.diagnosis_object.fsn.term !== newValue) {
					$scope.formData.diagnosis_object = null;
					$scope.$ctrl.setDiagnosis({
						form: {
							diagnosis: $scope.formData.search_term,
							operative_eye: $scope.formData.operative_eye,
							diagnosis_object: null
						}
					});
				}
			},
			true
		);

		$scope.$watch(
			"formData.operative_eye",
			function (newValue, oldValue) {
				if (newValue && $scope.formData.diagnosis_object) {
					$scope.$ctrl.setDiagnosis({
						form: {
							diagnosis: $scope.formData.search_term,
							operative_eye: $scope.formData.operative_eye,
							diagnosis_object: $scope.formData.diagnosis_object
						}
					});
				}
			},
			true
		);

		$scope.$watch(
			"formData.diagnosis_type",
			function (newValue, oldValue) {
				if (newValue) {
					$scope.$ctrl.setDiagnosis({
						form: {
							diagnosis: $scope.formData.search_term,
							operative_eye: $scope.formData.operative_eye,
							diagnosis_object: $scope.formData.diagnosis_object,
							diagnosis_type: $scope.formData.diagnosis_type,
						}
					});
				}
			},
			true
		);

        $scope.fetch = function (value, theForm) {
            $scope.loading = true;
            return new Promise((resolve, reject) => {
                searchDiagnosis(value, resolve, reject)
            })
        };


		function searchDiagnosis(terms, resolve, reject) {
			$scope.result = [];
            $scope.noResults = false;
			const isOcular = $scope.formData.diagnosis_type === 'ocular';
			$scope.DiagnosisAPI.searchDiagnosis(terms, isOcular)
				.then(
					function (resp) {
						if (resp.data) {
							$scope.result=resp.data;
                            $scope.formData.search_term = terms;
							resolve($scope.result)
						}
					},
					function (err) {
						console.log(`${JSON.stringify(err)}`)
                        $scope.noResults = true;
                        $scope.result = [];
						reject(err)
					}
				).then(function () {
					$scope.loading = false;
				})
		}

        $scope.diagnosisOnSelect = function(item, model, label, event) {
			$scope.formData.diagnosis_object = item;
            $scope.$ctrl.setDiagnosis({form:{
                diagnosis: $scope.formData.search_term,
                operative_eye: $scope.formData.operative_eye,
                diagnosis_object: item
            }});
        }
	},
]);
