app.factory('AuthService', ['ENV', 'localStorageService', 'Session', 'UserService', '$http', '$base64', '$rootScope', 'AUTHENTICATION_STATE',"$timeout", 
function(ENV, localStorageService, Session, UserService, $http, $base64, $rootScope, AUTHENTICATION_STATE, $timeout) {
	
	const isMFAConfigJourney = function (resp){
		return !resp.data.mfa_challenge && resp.data.idp_access_token;
	}

	const isMFAVerifyJourney = function (resp){
		return resp.data.mfa_challenge;
	}

	var service = {
		'isProcessing': false,

		'login': function(creds, callback) {
			service.isProcessing = true;
			var authString = 'Basic ' +  $base64.encode(creds.username + ':' + creds.password);
			var _isMFAConfigJourney = false;
			return $http({
				method: 'POST',
				url: ENV.API + '/api/identity/authenticate',
				headers: {'Authorization': authString, },
				data: {}
			}).then(function(resp) {
				Session.create(resp.data.access_token, resp.data.expires_in, resp.headers('x-user-id'), resp.data.idp_access_token, resp.data.refresh_token);
				if(isMFAConfigJourney(resp)){
					_isMFAConfigJourney = true;
					return UserService.getUser();
					// callback(creds, {
					// 	authenticationState: AUTHENTICATION_STATE.configMFA,
					// 	mfa_challenge: null,
					// 	idp_access_token: resp.data.idp_access_token
					// });
				}else if (isMFAVerifyJourney(resp)) {
					//waiting for mfa code input
					callback(creds, {
						authenticationState: AUTHENTICATION_STATE.waitingMFACode,
						mfa_challenge: resp.data.mfa_challenge,
						idp_access_token: resp.data.idp_access_token
					});
				}else{
					return UserService.getUser();
				}
			},function(err) {
				return Promise.reject('error logging in');
			}).then(function(resp) {
				if(resp && !resp.data.passwordUpdateDate){
					//should change password for first login user
					callback(creds, {
						authenticationState: AUTHENTICATION_STATE.updateTempPassword,
						mfa_challenge: null,
						idp_access_token: Session.idp_access_token,
						userName: resp.data.userName
					});
				}else if(resp && _isMFAConfigJourney){
					callback(creds, {
						authenticationState: AUTHENTICATION_STATE.configMFA,
						mfa_challenge: null,
						idp_access_token: Session.idp_access_token
					});
				}else if(resp){
					Session.setCurrentUser(resp.data);
					callback(creds, {authenticationState: AUTHENTICATION_STATE.normal});
				}
				
				service.isProcessing = false;
			})
		},
		'logout': function () {
    		return $http({
				method: "POST",
				url: ENV.API + "/api/security/logout",
				headers: {
					Authorization: "Bearer " + Session.token,
				},
			});
		},
		'isAuthenticated': function () {
			return !!Session.userId;
		},

		'getPasswordResetToken': function(email) {
			service.isProcessing = true;
			return $http({
				method: 'POST',
				url: ENV.API + '/api/security/getPasswordResetToken',
				data: {
					email: email
				}
			}).then(function(resp) {
				service.isProcessing = false;
				return Promise.resolve(resp);
			},function(err) {
				service.isProcessing = false;
				return Promise.reject('error sending request');
			})
		},

		'validatePasswordToken': function(token) {
			service.isProcessing = true;
			return $http({
				method: 'POST',
				url: ENV.API + '/api/security/validateToken',
				data: {
					token: token
				}
			}).then(function(resp) {
				service.isProcessing = false;
			},function(err) {
				service.isProcessing = false;
				return Promise.reject('error sending request');
			})
		},

		'resetPassword': function(token, newPassword, username) {
			service.isProcessing = true;
			return $http({
				method: 'POST',
				url: ENV.API + '/api/security/resetPassword',
				data: {
					username: username,
					token: token,
					newPassword: newPassword
				}
			}).then(function(resp) {
				service.isProcessing = false;
			},function(err) {
				service.isProcessing = false;
				return Promise.reject('error sending request');
			})
		},
		'verifyMfaCode': function(creds,mfa_code) {
			var authString = 'Basic ' +  $base64.encode(creds.username + ':' + creds.password);
			return $http({
				method: 'POST',
				url: ENV.API + '/api/identity/authenticate/mfa/login',
				headers: {'Authorization': authString},
				data: {
					'mfa_challenge': "SOFTWARE_TOKEN_MFA",
    				'mfa_code': mfa_code
				}
			})
            
		},
		'initialMfa': function (idp_access_token) {
			return $http({
				method: 'POST',
				url: ENV.API + '/api/identity/authenticate/mfa/init',
				headers: {
					Authorization: "Bearer " + Session.token,
				},
				data: {
					'idp_access_token': Session.idp_access_token
				}
			})
		},
		'completeMfa': function (idp_access_token, mfa_code) {
			return $http({
				method: 'POST',
				url: ENV.API + '/api/identity/authenticate/mfa/verify',
				headers: {
					Authorization: "Bearer " + Session.token,
				},
				data: {
					'idp_access_token': Session.idp_access_token,
					'mfa_challenge': "SOFTWARE_TOKEN_MFA",
    				'mfa_code': mfa_code
				}
			})
		},
		'updateEmail': function (newEmail) {
			return $http({
				method: 'POST',
				url: ENV.API + '/api/security/changeEmail',
				headers: {
					Authorization: "Bearer " + Session.token,
				},
				data: {
					'idp_access_token': Session.idp_access_token,
					'new_email': newEmail
				}
			})
		},
		'verifyEmail': function (token) {
			return $http({
				method: 'POST',
				url: ENV.API + '/api/security/verifyEmail',
				headers: {
					Authorization: "Bearer " + Session.token,
				},
				data: {
					'idp_access_token': Session.idp_access_token,
					'token': token
				}
			})
		}
	};
	 
	return service;
}])