app.service("UploadFileAPI", [
	"$http",
	"ENV",
	"Session",
	"$base64",
	function ($http, ENV, Session,$base64) {
		var service = {
			isProcessing: false,

			getFiles: function (patientId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/files/patient/${patientId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},

			downloadFile: function (patientId, fileId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/files/patient/${patientId}/file/${fileId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					},
					responseType: "arraybuffer",
				});
			},
			downloadVideoFile: function (url) {
				return $http({
					method: "GET",
					url,
					responseType: "arraybuffer",
				});
			},
			fetchVideoUrl:function (patientId, fileId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/files/patient/${patientId}/file/video/${fileId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					},
					transformResponse: function (data, headersGetter) {
						return data;
					},
					responseType: "text",
				});
			},
            deleteFile: function (patientId, fileId) {
				return $http({
					method: "DELETE",
					url: ENV.API + `/api/files/patient/${patientId}/file/${fileId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					},
				});
			},
			updateFile: function (patientId, fileId, body) {
				return $http({
					method: "PUT",
					url: ENV.API + `/api/files/patient/${patientId}/file/${fileId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					},
					data: body,
				});
			},
			updateSecurity: function (patientId, fileId, level) {
				return $http({
					method: "PUT",
					url: ENV.API + `/api/surgery-waitlist/${patientId}/${fileId}/security?securityLevel=${level}`,
					headers: {
						Authorization: "Bearer " + Session.token,
                        "X-PRACTICE-ID": `${Session.practice.id}`,
					}
				});
			},

		};

		return service;
	},
]);
