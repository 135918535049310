app.component("preOpHistoryPrevOcular", {
	templateUrl: "app/src/js/components/preOpHistory/preOpHistoryPrevOcular/preOpHistoryPrevOcular.html",
	controller: "preOpHistoryPrevOcularController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		eyeTitleLowercase: "@",
	},
});

app.controller("preOpHistoryPrevOcularController", [
	"$scope",
	"PreOp",
	function ($scope, PreOp) {
		this.$onInit = function () {
			this.PreOp = PreOp;
		};

		$scope.$watch("$ctrl.data.prev_ocular_trauma", function (newValue, oldValue) {
			if (newValue === 'NO') {
				$scope.$ctrl.data.phacodonesis = null;
				$scope.$ctrl.data.ppv = null;
				$scope.$ctrl.data.refractive_laser = null;
				$scope.$ctrl.data.corneal_transplant = null;
				$scope.$ctrl.data.strabismus_surgery = null;
			}
		}, true);
	},
]);
