app.component("followUpSection", {
	templateUrl: "app/src/js/components/progressNote/followUpSection.html",
	controller: "followUpSectionController",
	bindings: {
		data: "=",
	},
});

app.controller("followUpSectionController", [
	"$scope",
	function ($scope) {
		$scope.$watch("$ctrl.data.follow_up_type", function (newValue, oldValue) {
			if (newValue === 'DISCHARGE') {
				$scope.clinician_copy = $scope.$ctrl.data.clinician;
				$scope.$ctrl.data.clinician = '';
			} else if($scope.$ctrl.data.clinician === '') {
				$scope.$ctrl.data.clinician = $scope.clinician_copy;
			}
		}, true);
		$scope.options = [  
			{ name: "1 day", value: "ONE_DAY" },
			{ name: "2 days", value: "TWO_DAYS" },
			{ name: "3 days", value: "THREE_DAYS" },
			{ name: "4 days", value: "FOUR_DAYS" },
			{ name: "5 days", value: "FIVE_DAYS" },
			{ name: "6 days", value: "SIX_DAYS" },
			{ name: "7 days", value: "SEVEN_DAYS" },
			{ name: "8 days", value: "EIGHT_DAYS" },
			{ name: "9 days", value: "NINE_DAYS" },
			{ name: "10 days", value: "TEN_DAYS" },
			{ name: "11 days", value: "ELEVEN_DAYS" },
			{ name: "12 days", value: "TWELVE_DAYS" },
			// { name: "1 week", value: "ONE_WEEK" },
			{ name: "2 weeks", value: "TWO_WEEKS" },
			{ name: "3 weeks", value: "THREE_WEEKS" },
			{ name: "4 weeks", value: "FOUR_WEEKS" },
			{ name: "5 weeks", value: "FIVE_WEEKS" },
			{ name: "6 weeks", value: "SIX_WEEKS" },
			{ name: "7 weeks", value: "SEVEN_WEEKS" },
			{ name: "8 weeks", value: "EIGHT_WEEKS" },
			{ name: "9 weeks", value: "NINE_WEEKS" },
			{ name: "10 weeks", value: "TEN_WEEKS" },
			{ name: "11 weeks", value: "ELEVEN_WEEKS" },
			{ name: "12 weeks", value: "TWELVE_WEEKS" },
			// { name: "1 month", value: "ONE_MONTH" },
			// { name: "2 months", value: "TWO_MONTHS" },
			// { name: "3 months", value: "THREE_MONTHS" },
			{ name: "4 months", value: "FOUR_MONTHS" },
			{ name: "5 months", value: "FIVE_MONTHS" },
			{ name: "6 months", value: "SIX_MONTHS" },
			{ name: "7 months", value: "SEVEN_MONTHS" },
			{ name: "8 months", value: "EIGHT_MONTHS" },
			{ name: "9 months", value: "NINE_MONTHS" },
			{ name: "10 months", value: "TEN_MONTHS" },
			{ name: "11 months", value: "ELEVEN_MONTHS" },
			{ name: "12 months", value: "TWELVE_MONTHS" },
			{ name: "24 months", value: "TWENTY_FOUR_MONTHS" },
			// { name: "1 year", value: "ONE_YEAR" },
			{ name: "See as required", value: "SEE_AS_REQUIRED" },
			{ name: "Discharge", value: "DISCHARGE" },
			{ name: "Other", value: "OTHER" },
			
		];
	},
]);
