app.service("InjectionNoteAPI", [
	"$http",
	"ENV",
	"Session",
	function ($http, ENV, Session) {
		var service = {
			isProcessing: false,
			submitInjectionNote: function (requestData,option) {

				let parameters = '';

				if(option && option.addAutoTaskInjection === 'true'){
					parameters = '?addAutoTaskInjection=true';
				}else if(option && option.addAutoTaskInjection === 'false'){
					parameters = '?addAutoTaskInjection=false';
				}

				return $http({
					method: "POST",
					url: ENV.API + `/api/injection_note/${parameters}`,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					responseType: "arraybuffer",
					data: requestData,
				});
			},
			getPatientSetting: function (patientId) {
				return $http({
					method: "GET",
					url: ENV.API + `/api/injection_note/settings/${patientId}`,
					headers: {
						Authorization: "Bearer " + Session.token,
						"Content-Type": "application/json",
					},
				});
					// .then(function (response) {
					// 	OperationNote.setDefaultSetting(response.data);
					// })
					// .catch(function (err) {
					// 	console.log(`${JSON.stringify(err)}`);
					// 	// OperationNote.setData(mockResponse);
					// });
			},
            addNewComment: function (patient_id, injection_note_uuid, commentBody) {
				return $http({
					method: "POST",
					url: ENV.API + `/api/injection_note/patient/${patient_id}/${injection_note_uuid}/comments`,
					headers: {
						Authorization: "Bearer " + Session.token,
					},
					data: commentBody,
				});
			},
		};
		return service;
	},
]);
