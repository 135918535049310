app.component("injectionPostoperativeSection", {
	templateUrl: "app/src/js/components/injectionNote/postoperativeSection/postoperativeSection.html",
	controller: "injectionPostoperativeSectionController",
	bindings: {
		flag: "=",
		data: "<",
		type: "=",
	},
});

app.controller("injectionPostoperativeSectionController", [
	"$scope",
	function ($scope) {
	},
]);
