app.component("floatingButton", {
	templateUrl: "app/src/js/components/floatingButton/FloatingButton.html",
	controller: "floatingButtonController",
});

app.controller("floatingButtonController", [
	'$scope', '$mdDialog',"CurrentPatient", "Session", "AppointmentAPI",function($scope, $mdDialog,CurrentPatient,Session,AppointmentAPI) {
			// Parent of all referral controllers. This controller currently containes no functionality.
	// This file should be 
	$scope.topDirections = ["left", "up"];
	$scope.bottomDirections = ["down", "right"];

	$scope.availableModes = ["md-fling", "md-scale"];
	$scope.selectedMode = "md-scale";

	$scope.availableDirections = ["up", "down", "left", "right"];
	$scope.selectedDirection = "left";

	$scope.Session = Session;
	$scope.CP = CurrentPatient;

	$scope.isDataReady = false;

	$scope.isOpen = false;

	$scope.popoverOpened2 = false;

	$scope.$watch(
		"isOpen",
		function (newValue, oldValue) {
			if (!newValue) {
				$scope.popoverOpened2 = false;
			}else {
				fetchAppointmentsForPatient();
			}
		},
		true
	);

	if($scope.Session.practice && $scope.Session.practice.practiceFeatures){
		$scope.isTaskFeatureEnabled = $scope.Session.practice.practiceFeatures.sendTasksWithinPracticeFeature;
	}

	this.$onInit = function () {
		$scope.AppointmentAPI = AppointmentAPI;
		$scope.hasReminderPermission = false;
		const practiceFeatures = Session.practice.practiceFeatures;
		if(practiceFeatures && (practiceFeatures.appointmentEmailReminderFeature || practiceFeatures.appointmentSMSReminderFeature)){
			$scope.hasReminderPermission = true;
		}
		// fetchAppointmentsForPatient();
	}

	function fetchAppointmentsForPatient() {
		$scope.isDataReady = false;
		$scope.appointments = [];
		$scope.AppointmentAPI.getAppointmentsForPatient($scope.CP.patient.id).then(
			function (response) {
				$scope.appointments = response.data;
				$scope.isDataReady = true;
			},
			function (error) {}
		);
	}

	$scope.showSetting = function (ev) {
		$mdDialog.show({
			controller: "calendarSettingController",
			templateUrl: "app/src/views/templates/calendar-setting.tmpl.html",
			// Appending dialog to document.body to cover sidenav in docs app
			// Modal dialogs should fully cover application to prevent interaction outside of dialog
			parent: angular.element(document.body),
			targetEvent: ev,
			clickOutsideToClose: true,
			fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
			openFrom: "#anchor",
			closeTo: "#anchor",
			scope: $scope.$new(true),
			preserveScope: false,
			resolve: {
				patientId: () => $scope.CP.patient.id,
			},
		});

	}

	$scope.showTasksLabel = function (ev) {
		$mdDialog.show({
			controller: "taskLabelsController",
			templateUrl: "app/src/views/templates/tasks-labels.tmpl.html",
			// Appending dialog to document.body to cover sidenav in docs app
			// Modal dialogs should fully cover application to prevent interaction outside of dialog
			parent: angular.element(document.body),
			targetEvent: ev,
			clickOutsideToClose: false,
			fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
			openFrom: "#anchor",
			closeTo: "#anchor",
			scope: $scope.$new(true),
			preserveScope: false,
			resolve: {
				patientId: () => $scope.CP.patient.id,
				patientName: () => $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName,
			},
		});
	}

	$scope.showAdvanced = function (ev) {
		if(ev){
			$scope.selectedAppointment = null;
		}
		$mdDialog.show({
			controller: "calendarController",
			templateUrl: "app/src/views/templates/calendar.tmpl.html",
			// Appending dialog to document.body to cover sidenav in docs app
			// Modal dialogs should fully cover application to prevent interaction outside of dialog
			parent: angular.element(document.body),
			targetEvent: ev,
			clickOutsideToClose: true,
			fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
			openFrom: "#anchor",
			closeTo: "#anchor",
			scope: $scope.$new(true),
			preserveScope: false,
			resolve: {
				patientId: () => $scope.CP.patient.id,
				patientName: () => $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName,
				selectedAppointment: () => $scope.selectedAppointment,
			},
		});
	};

	$scope.triggerCalendarModal = function(appointment) {
		$scope.selectedAppointment = appointment;
		$scope.showAdvanced(null);
	}

	$scope.showAutomationSetting = function (ev) {
		$mdDialog.show({
			controller: "taskAutomationSettingController",
			templateUrl: "app/src/views/templates/tasks-automation.tmpl.html",
			// Appending dialog to document.body to cover sidenav in docs app
			// Modal dialogs should fully cover application to prevent interaction outside of dialog
			parent: angular.element(document.body),
			targetEvent: ev,
			clickOutsideToClose: false,
			fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
			openFrom: "#anchor",
			closeTo: "#anchor",
			scope: $scope.$new(true),
			preserveScope: false,
			onComplete: (scope, element) => {
				element.prepend('<span id="modal-close-btn" style="top:8rem" class="glyphicon glyphicon-remove dismiss-close-icon pointer modal-close"></span>');
				$("#modal-close-btn").click(function (event) {
					$mdDialog.hide();
				});
			},
			resolve: {
				patientId: () => $scope.CP.patient.id,
				patientName: () => $scope.CP.patient.firstName + ' ' + $scope.CP.patient.lastName,
			},
		});
	}

	$scope.showAppointmentSetting = function (ev) {
		$mdDialog.show({
			controller: "appointmentSettingController",
			templateUrl: "app/src/views/templates/appointment-setting.tmpl.html",
			// Appending dialog to document.body to cover sidenav in docs app
			// Modal dialogs should fully cover application to prevent interaction outside of dialog
			parent: angular.element(document.body),
			targetEvent: ev,
			clickOutsideToClose: false,
			fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
			openFrom: "#anchor",
			closeTo: "#anchor",
			scope: $scope.$new(true),
			preserveScope: false,
			onComplete: (scope, element) => {
				element.prepend('<span id="modal-close-btn" class="glyphicon glyphicon-remove dismiss-close-icon pointer modal-close"></span>');
				$("#modal-close-btn").click(function (event) {
					$mdDialog.hide();
				});
			},
		});
	}


	},
]);
