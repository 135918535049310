app.controller("referralScreenController", [
	"$scope",
	"CurrentPatient",
	"CurrentReferral",
	"UserService",
	"REFERRAL_REASON",
	"REFERRAL_PRIORITY",
	"$modal",
	"$filter",
	"$state",
	"DiagnosisAPI",
	"PatientVisionAPI",
	"PatientAPI",
	"ReferralsAPI",
	"Session",
	"OTHER_REFERRAL_DHB_LIST",
	function (
		$scope,
		CurrentPatient,
		CurrentReferral,
		UserService,
		REFERRAL_REASON,
		REFERRAL_PRIORITY,
		$modal,
		$filter,
		$state,
		DiagnosisAPI,
		PatientVisionAPI,
		PatientAPI,
		ReferralsAPI,
		Session,
		OTHER_REFERRAL_DHB_LIST
	) {
		this.$onInit = function () {
			$scope.form = {
				type: null,
				urgency: null,
				operative_eye: null,
			};
			$scope.currentStep = 0;
		};
		$scope.PA = PatientAPI;
		$scope.RA = ReferralsAPI;

		// $scope.showWarning = false;
	
		$scope.CP = CurrentPatient;
		$scope.CR = CurrentReferral;
		$scope.PV = PatientVisionAPI;
		$scope.US = UserService;
		$scope.DiagnosisAPI = DiagnosisAPI;
		$scope.referral_reason_options = REFERRAL_REASON;
		$scope.referral_priority_options = REFERRAL_PRIORITY;
		$scope.selectedFiles = [];
		$scope.clickNext = function (index) {
			if(index + 1 < $scope.currentStep){
				return;
			}
			if(index === 4 && $scope.selectedFiles.filter(t=>t.value).length === 0){
				$scope.generalModal = $modal({
					scope: $scope,
					templateUrl: "app/src/views/templates/modal.general.tpl.html",
					show: true,
					title: "Add files/scans/images to your referral",
					content: 'You have not selected any files to include with your referral.  Please consider including relevant files such as visual fields, OCT imaging, photographs.  To add a new file click "DOCUMENTS (ADD)", to select a file to include select the corresponding checkbox',
					backdrop: true,
					keyboard: false,
				});
				return;
			}
			$scope.currentStep++;
		}

		$scope.onGeneralClick = function () {
			if($scope.generalModal){
				$scope.generalModal.hide();
				$scope.currentStep++;
			}
		}

		$scope.$watch(
            "currentStep",
            function (newValue, oldValue) {
                if (newValue === 4) {
                    fetchPatientDiagnosis();
                }
            },
            true
        );

		$scope.documentSelectedEvent = function (selection) {
			if (selection) {
				const items = selection.items;
				for (const key of Object.keys(items)) {
					const target = $scope.selectedFiles.find(t=> t.key === key);
					if(!target){
						$scope.selectedFiles.push({key,value:items[key]})
					}else{
						target.value = items[key];
					}
				}
			}
		}
        $scope.isVisionReady = false;
		$scope.visionCallback = function (value) {
			if (value.vision) {
				const vision = value.vision;
				if (vision.od && vision.os) {
					const od = vision.od;
					const os = vision.os;
					if ((od.uncorrected_va_line || od.best_va_line) && (os.uncorrected_va_line || os.best_va_line)) {
						$scope.isVisionReady = true;
					}
				}
			}
		}

		$scope.onReasonChange = function () {
			if ($scope.form.type === 'CATARACT') {
				$scope.CR.isProcessing = true;
				$scope.RA.startReferral().then(
					function (resp) {
						$scope.CR.setReferral(resp.data);
						$state.go("examination",{ isFromReferral: true });
					},
					function (err) {
						console.log(err);
					}
				).then(function(){
					$scope.CR.isProcessing = false;
				})
			}
		}

		$scope.choosePrimaryReason = function () {
			if($scope.currentStep < 3){
				$scope.currentStep = 3;
			}
			
			// if ($scope.form.referral_reason === 'CATARACT') {
			// 	$state.go("examination", { isFromReferral: true });
			// } else {
			// 	$scope.currentStep++;
			// }
		}

		$scope.onVisionUpdate = function () {

		}

		$scope.closeFileUploaderModal = function () {
			if($scope.fileUploaderModal){
				$scope.fileUploaderModal.hide();
			}
		}

		// $scope.showFileUploaderModal = function () {
		// 	$scope.isFileUnsaved = false;
		// 	$scope.fileUploaderModal = $modal({
		// 		scope: $scope,
		// 		templateUrl: "app/src/views/templates/modal.files-uploader.tpl.html",
		// 		show: true,
		// 		title: "UPLOAD FILES",
		// 		backdrop: "static",
		// 		keyboard: false,
		// 	});
		// }

		$scope.diagnosisCallback = function (form){
			$scope.diagnosis = {...$scope.diagnosis,...form};
		}

		$scope.saveDiagnosis = function () {
			$scope.DiagnosisAPI.isProcessing = true;
			let diagnosisArray = [];
			if($scope.diagnosis.diagnosis_type === 'ocular'){
				if($scope.diagnosis.operative_eye === 'OU'){
					diagnosisArray = [{
						date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
						laterality: 'OD',
						search_term: $scope.diagnosis.diagnosis,
						note: $scope.diagnosis.comment,
						type: 'OCULAR',
						snomed_concept: {
							concept_id: $scope.diagnosis.diagnosis_object.conceptId
						}
					}, {
						date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
						laterality: 'OS',
						search_term: $scope.diagnosis.diagnosis,
						note: $scope.diagnosis.comment,
						type: 'OCULAR',
						snomed_concept: {
							concept_id: $scope.diagnosis.diagnosis_object.conceptId
						}
					}];
				}else{
					diagnosisArray = [{
						date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
						laterality: $scope.diagnosis.operative_eye,
						search_term: $scope.diagnosis.diagnosis,
						note: $scope.diagnosis.comment,
						type: 'OCULAR',
						snomed_concept: {
							concept_id: $scope.diagnosis.diagnosis_object.conceptId
						}
					}];
				}
			}else{
				diagnosisArray = [{
					date_diagnosis_onset: $filter("date")(new Date($scope.diagnosis.date_onset), "yyyy/MM/dd"),
					// laterality: $scope.diagnosis.operative_eye,
					search_term: $scope.diagnosis.diagnosis,
					note: $scope.diagnosis.comment,
					type: 'SYSTEMIC',
					snomed_concept: {
						concept_id: $scope.diagnosis.diagnosis_object.conceptId
					}
				}];
			}
	
			
			$scope.DiagnosisAPI.saveDiagnosis($scope.CP.patient.id,diagnosisArray).then(
				function (resp) {
					if (resp.data) {
					}
				},
				function (err) {
					console.log(`${JSON.stringify(err)}`)

				}
			).then(function () {
				$scope.diagnosisModal.hide();
				fetchPatientDiagnosis();
				$scope.DiagnosisAPI.isProcessing = false;
			});

		}

		$scope.showDiagnosis = function (type) {
			$scope.diagnosis = {
				comment: '',
				date_onset: $filter("date")(new Date(), "dd-MMMM-yyyy"),
				diagnosis_type: type,
			}

			$scope.diagnosis_type = type;

			$scope.diagnosisModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.diagnosis.tpl.html",
				show: true,
				title: "DIAGNOSES AND PROCEDURES",
				backdrop: "static",
				keyboard: false,
			});
		}

		$scope.doctorsDhbArray = [];

		/** 
		if($scope.CR.referral && $scope.CR.referral.outcome && $scope.CR.referral.outcome.dhb){
			$scope.dhbName = $scope.CR.referral.outcome.dhb.name;
			$scope.dhbCode = $scope.CR.referral.outcome.dhb.code.toLowerCase();
			$scope.dhbIsAvailable = $scope.CR.referral.outcome.dhb.isAvailable;
		} */

		/**
		 * Remove
		 */
		$scope.eligible = { private: true };
		$scope.dhbIsAvailable = $scope.CP.patient.dhb.isAvailable;
		$scope.dhbCode = $scope.CP.patient.dhb.code ? $scope.CP.patient.dhb.code: $scope.CP.patient.dhb.name;
		const DHBObj = OTHER_REFERRAL_DHB_LIST.find(t=>t.value === $scope.dhbCode);
		if(DHBObj){
			$scope.dhbName = DHBObj.name;
		}else{
			$scope.dhbName = $scope.CP.patient.dhb.name;
		}
		
		
		$scope.dhbId = $scope.CP.patient.dhb.id;
		/**
		 * Remove
		 */
		
		// get list of doctors here
		$scope.US.getDoctors().then(
			function(resp) {
				$scope.doctorsArray = resp.data;

				$scope.doctorsArray.forEach(function(doc) {
					if (doc.dhbName.toLowerCase() === $scope.dhbCode.toLowerCase()) {
						$scope.doctorsDhbArray.push(doc);
					}
				});

				$scope.displayOptomsArray = $scope.doctorsDhbArray;
			},
			function(err) {
				console.log(err);
			}
		);

		$scope.date = new Date();

		$scope.sanitizerDHBName = function (name) {
			if(name === 'DHB'){
				return 'Public hospital';
			}
			return name.replace('District Health Board','region');
		};

		$scope.filterData = {
			showAll: false
		};
		$scope.selectedDoc = null;

		$scope.getInsuranceName = function (provider) {
			const item = MEDICAL_INSURANCE.find(t => t.value === provider);
			return item ? item.name : 'Add insurance';
		}

		$scope.showSouthernCrossLine = function (provider) {
			return false; // provider === 'SOUTHERN_CROSS' || provider === 'AIA';
		}

		$scope.showOtherInsuranceLine = function (provider) {
			return false;
			// provider === 'NIB' || provider === 'AA' || provider === 'PARTNERS_LIFE'
			// 	|| provider === 'ACCURO' || provider === 'OTHER';
		}

		$scope.appendInsuranceName = function (provider, isSourthenCross) {
			if(isSourthenCross) {
				if(provider === 'SOUTHERN_CROSS') return 'Southern Cross';
				if(provider === 'AIA') return 'AIA';
				return 'Southern Cross or AIA'
			} else {
				if(provider === 'NIB') return 'NIB';
				if(provider === 'AA') return 'AA';
				if(provider === 'PARTNERS_LIFE') return 'Partners life';
				if(provider === 'ACCURO') return 'Accuro';
				if(provider === 'OTHER') return 'Other';
				return 'NIB, AA, Partners life, Accuro and other'
			}
		}


		$scope.hiddenEmptyString = function (str) {
			return str ? 'NHI: '+str : '';
		}

		$scope.isEligibleSouthernCrossOrAIA = function (provider){
            return false; // $scope.CR.referral.outcome.isEligibleSouthernCross && (provider === 'SOUTHERN_CROSS' || provider === 'AIA');
		}

		$scope.isEligibleOtherInsurance = function (provider) {
			return false;
			// (provider === 'NIB' || 
			// provider === 'AA' || 
			// provider === 'PARTNERS_LIFE' || 
			// provider === 'ACCURO' || 
			// provider === 'OTHER') && $scope.CR.referral.outcome.isEligiblePrivateOtherInsurance;
		}

		$scope.showCompleteReferralModal = function(referralSystem) {
			// resetFormData();
			$scope.isOtherReferral = true;
			if(!$scope.formData){
				$scope.formData = {};
			}
			$scope.formData.referralSystem = referralSystem;

			if(referralSystem === 'PUBLIC_SYSTEM'){
				return $scope.sendReferral();
			}

			$scope.completeReferralModal = $modal({
				scope: $scope,
				templateUrl: "app/src/views/templates/modal.complete-referral.tpl.html",
				show: true,
				title: "COMPLETE REFERRAL"
			});
			// randomise array here
			$scope.doctorsDhbArray = shuffle($scope.doctorsDhbArray);
		};

		$scope.selectDoctor = function(doc) {
			$scope.selectedDoc = doc;
		};

		function shuffle(array) {
			var currentIndex = array.length,
				temporaryValue,
				randomIndex;
			// While there remain elements to shuffle...
			while (0 !== currentIndex) {
				// Pick a remaining element...
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex -= 1;
				// And swap it with the current element.
				temporaryValue = array[currentIndex];
				array[currentIndex] = array[randomIndex];
				array[randomIndex] = temporaryValue;
			}
			return array;
		}

		$scope.removeDiagnosis = function (diagnosis_id) {
			$scope.DiagnosisAPI.isProcessing = true;
			$scope.DiagnosisAPI.inactivateDiagnosis($scope.CP.patient.id, diagnosis_id)
				.then(
					function (resp) {
						// console.log(`!!!! ${JSON.stringify(resp.data)}`);
						// $scope.diagnosisData = resp.data;
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function (obj) {
					$scope.DiagnosisAPI.isProcessing = false;
					fetchPatientDiagnosis();
				});
			
		}

		function fetchPatientDiagnosis() {
			if (!$scope.CP.patient || !$scope.CP.patient.id || $scope.userIsPracticeAdmin) {
				return;
			}
			$scope.DiagnosisAPI.isProcessing = true;
			$scope.DiagnosisAPI.getDiagnosis($scope.CP.patient.id)
				.then(
					function (resp) {
						if (resp.data) {
							$scope.diagnosisData_systemic = resp.data.filter(m => m.type === 'SYSTEMIC').map(t => {
								if (t.snomed_concept.fsn.term.endsWith('(procedure)')) {
									return { ...t, internal_type: 1, date_diagnosis_onset: new Date(t.date_diagnosis_onset) };
								}
								return { ...t, internal_type: 0, date_diagnosis_onset: new Date(t.date_diagnosis_onset) };
							});

							const array = resp.data.filter(t=>t.type === 'OCULAR').map(t=> {
								if(t.snomed_concept.fsn.term.endsWith('(procedure)')){
									return {...t, internal_type: 1, date_diagnosis_onset: new Date(t.date_diagnosis_onset)};
								}
								return {...t, internal_type: 0, date_diagnosis_onset: new Date(t.date_diagnosis_onset)};
							});
							array.sort(function compareFn(a, b) {
								if (new Date(a.date_diagnosis_onset) > new Date(b.date_diagnosis_onset)) {
									return -1;
								}
								if (new Date(a.date_diagnosis_onset) < new Date(b.date_diagnosis_onset)) {
									return 1;
								}
								return 0;
							});
							array.sort(function compareFn(a, b) {
								if (a.internal_type > b.internal_type) {
									return 1;
								}
								if (a.internal_type < b.internal_type) {
									return -1;
								}
								return 0;
							});
							const od_array = array.filter(t=>t.laterality === 'OD');
							const os_array = array.filter(t=>t.laterality === 'OS');

							const rows = [];
							const simple_rows = [];
							const od_array_simple = array.filter(t=>t.laterality === 'OD' && t.status === 'ACTIVE');
							const os_array_simple = array.filter(t=>t.laterality === 'OS' && t.status === 'ACTIVE');

							const rows_count_simple = Math.max(od_array_simple.length, os_array_simple.length);
							for(let i = 0; i< rows_count_simple; i++ ){
								simple_rows.push({od: od_array_simple[i], os: os_array_simple[i]});
							}

							if (od_array.length === os_array.length) {
								for (let i = 0; i < od_array.length; i++) {
									rows.push({ od: od_array[i], os: os_array[i] });
								}
							}else if(od_array.length > os_array.length){
								for (let i = 0; i < od_array.length; i++) {
									if(!os_array[i]){
										rows.push({ od: od_array[i], os: null });
									}else{
										rows.push({ od: od_array[i], os: os_array[i] });
									}
									
								}
							}else if(od_array.length < os_array.length){
								for (let i = 0; i < os_array.length; i++) {
									if(!od_array[i]){
										rows.push({ od: null, os: os_array[i] });
									}else{
										rows.push({ od: od_array[i], os: os_array[i] });
									}
									
								}
							}
							$scope.diagnosisData_ocular = rows;
							$scope.diagnosisData_ocular_simple = simple_rows;
						}
					},
					function (err) {
						console.log(err);
					}
				)
				.then(function (obj) {
					$scope.DiagnosisAPI.isProcessing = false;
				});
		}

		$scope.gotoIOL = function() {
			if ($scope.CP.eligibilitySummaryFirstLoad) {
				$state.go("refer.impact");
			} else {
				console.log("show complete public referral modal here");
			}
		};

		$scope.displayDoctors = function(bool) {
			$scope.filterData.showAll = bool;
			if ($scope.filterData.showAll === true) {
				$scope.displayOptomsArray = $scope.doctorsArray;
			} else {
				$scope.displayOptomsArray = $scope.doctorsDhbArray;
			}
		};

		$scope.startAgain = function() {
			$scope.successOverrideModal.hide();
			$scope.CP.clearPatientResult();
			$state.go("refer.search");
		};

		$scope.backToDashboard = function() {
			$scope.successOverrideModal.hide();
			$state.go("refer.search");
		}

		function getFileName() {
            var theTime = $filter("date")(new Date(), "yyyy-MM-dd@hmma");
            return (
                "CatTrax_referral_" +
                theTime +
                ".pdf"
            );
        }

		function downloadFile(data, filename, mime, bom) {
			// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
			var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
			var blob = new Blob(blobData, { type: "application/pdf" });
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// IE workaround for "HTML7007: One or more blob URLs were
				// revoked by closing the blob for which they were created.
				// These URLs will no longer resolve as the data backing
				// the URL has been freed."
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
				$scope.displaySpinner = false;
			}
		}

		$scope.sendReferral = function(formData) {
			if ($scope.selectedDoc === null && $scope.formData.referralSystem !== "PUBLIC_SYSTEM") {
				// show warning
				$scope.showDocWarning = true;
				return;
			}
			// if(!$scope.showWarning) {
			// 	$scope.showWarning = true;
			// 	return; 
			// }
			// $scope.showWarning = false;
			/** 
			if ($scope.formData.referralSystem !== "PUBLIC_SYSTEM") {
				formData.referredTo = {
					id: $scope.selectedDoc.id
				};
			}*/
			

			/**
			if (formData.isOverride === true) {
				delete formData.referredTo;
			} */
				
				let body = {
					"patientId": $scope.CP.patient.id,
					"type": $scope.form.type,
					"urgency": $scope.form.urgency,
					"laterality": $scope.form.operative_eye,
					"referredByUserId": Number(Session.userId),
					"referredByPracticeId": Session.practice.id,
					"surgicalRiskId": null,
					// "referredToUserId": $scope.selectedDoc.id,
					// "referredToPracticeId": $scope.selectedDoc.practiceId,
					"resultOverride": false,
					"referredUnder": $scope.formData.referralSystem,
					"cpacOverrideCriteria": null,
					"outcomeComments": null,
					"isEligiblePublic": null,
					"isEligibleSouthernCross": null,
					"isEligiblePrivateOtherInsurance": null,
					"descriptionOfIssue": $scope.form.descriptionOfIssue,
					"additionalComments": $scope.form.additionalComments,
					"fileList": $scope.selectedFiles.filter(t=>t.value).map(m=> m.key),
					"referredToPracticeId": $scope.dhbId,
				}
				if ($scope.selectedDoc) {
					body = { ...body, referredToPracticeId: $scope.selectedDoc.practiceId, referredToUserId: $scope.selectedDoc.id };
				}
				
				$scope.RA.isProcessing = true;
				$scope.RA.saveReferralV2(body).then(function(resp){
					if($scope.completeReferralModal){
						$scope.completeReferralModal.hide();
					}
					if($scope.dhbIsAvailable === 'NO'){
						downloadFile(resp.data, getFileName());
					}
					$scope.$broadcast('trigger:automation-task');
					$scope.successOverrideModal = $modal({
						scope: $scope,
						templateUrl: "app/src/views/templates/modal.successful-override.tpl.html",
						show: true,
						title: "SUCCESS",
						backdrop: "static",
						keyboard: false
					});
				},function(error){
					if($scope.completeReferralModal){
						$scope.completeReferralModal.hide();
					}
					$scope.serverErrorModal = $modal({
                        scope: $scope,
                        templateUrl: "app/src/views/templates/modal.server-error.tpl.html",
                        show: true,
                        title: "CatTrax has encountered an error",
                    });
				}).then(function(){
					$scope.RA.isProcessing = false;
				});
				
		};
	},
]);
