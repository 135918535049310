app.component("preOpStatus", {
	templateUrl: "app/src/js/components/preOpStatus/preOpStatus.html",
	controller: "preOpStatusController",
	bindings: {
		data: "=",
		eyeTitle: "@",
		isAcuityVisible: "=",
		isLinkHidden: "=",
		preset: "="
	},
});

app.controller("preOpStatusController", [
	"$scope",
	"PreOp",
	"VISUALACUITYLINE",
	"LETTER",
	"CYL",
	"DEVICE",
	"$timeout",
	function ($scope, PreOp, VISUALACUITYLINE, LETTER, CYL, DEVICE, $timeout) {
		$scope.visualacuityline = VISUALACUITYLINE;
		$scope.letter = LETTER;
		$scope.cyl = CYL;
		$scope.device = DEVICE;
		$scope.best_va_line_clone = null;
		$scope.best_va_letter_clone = null;

		$scope.$watch("$ctrl.preset", function (newValue, oldValue) {
			if (newValue) {
				//revert values
				$timeout(function () {
					if($scope.best_va_line_clone){
						$scope.$ctrl.data.best_va_line = $scope.best_va_line_clone;
					}
	
					if($scope.best_va_letter_clone){
						$scope.$ctrl.data.best_va_letter = $scope.best_va_letter_clone;
					}
				});
			}
		}, true);



		$scope.$watch("$ctrl.data.refraction_no_improvement", function (newValue, oldValue) {
			if (newValue) {
				$scope.best_va_line_clone = $scope.$ctrl.data.best_va_line;
				$scope.best_va_letter_clone = $scope.$ctrl.data.best_va_letter;
				$scope.$ctrl.data.best_va_line = $scope.$ctrl.data.uncorrected_va_line || $scope.$ctrl.data.best_va_line;
				$scope.$ctrl.data.best_va_letter = $scope.$ctrl.data.uncorrected_va_letter || $scope.$ctrl.data.best_va_letter;
			}
		}, true);

		$scope.$watch("$ctrl.data.uncorrected_va_line", function (newValue, oldValue) {
			if (!newValue) {
				if($scope.$ctrl.data){
					$scope.$ctrl.data.uncorrected_va_letter = null;
				}
			}
		}, true);

		$scope.$watchGroup(["$ctrl.data.refraction_sphere", "$ctrl.data.refraction_cylinder", "$ctrl.data.refraction_axis"], function (newValues, oldValues, scope) {
			if (!$scope.$ctrl.data) return;
			if($scope.isNumber(newValues[0]) && $scope.isNumber(newValues[1]) && $scope.isNumber(newValues[2])){
				// do nothing
			}else if ($scope.isNumber(newValues[0]) || $scope.isNumber(newValues[1]) || $scope.isNumber(newValues[2])) {
				$scope.$ctrl.data.auto_refraction = undefined
			}
		});

		$scope.$watch("$ctrl.data.bestcorrected_medium_va_line", function (newValue, oldValue) {
			if (newValue) {
				$scope.$ctrl.isAcuityVisible = true;
			}
		}, true);

		$scope.$watch("$ctrl.data.bestcorrected_near_va_line", function (newValue, oldValue) {
			if (newValue) {
				$scope.$ctrl.isAcuityVisible = true;
			}
		}, true);


		$scope.$watch("$ctrl.isAcuityVisible", function (newValue, oldValue) {
			if (!newValue && $scope.$ctrl.data) {
				$scope.$ctrl.data.bestcorrected_medium_va_line = null;
				$scope.$ctrl.data.bestcorrected_medium_va_letter = null;
				$scope.$ctrl.data.bestcorrected_near_va_line = null;
				$scope.$ctrl.data.bestcorrected_near_va_letter = null;
			}
		}, true);

		$scope.copyLine = function () {
			if ($scope.$ctrl.data.refraction_no_improvement) {
				$scope.$ctrl.data.best_va_line = $scope.$ctrl.data.uncorrected_va_line;
			}
		};

		$scope.copyLetter = function () {
			if ($scope.$ctrl.data.refraction_no_improvement) {
				$scope.$ctrl.data.best_va_letter = $scope.$ctrl.data.uncorrected_va_letter;
			}
		};

		$scope.addAcuity = function () {
			$scope.$ctrl.isAcuityVisible = true;
		}

		$scope.popoverIntermediate = {
			content:
				"Measure with ETDRS intermediate chart",
		};

		$scope.popoverNear = {
			content:
				"Measure with ETDRS near chart",
		};

		$scope.isNumber = function (value) {
			return (value === 0 || value !=null)
		}

		$scope.refractionCylinderOnChange = function () {
			if ($scope.$ctrl.data.refraction_cylinder === 0){
				$scope.$ctrl.data.refraction_axis = 0;
			}
		}

		$timeout(function () {
			if ($scope.$ctrl.data == undefined) {
				$scope.$ctrl.data = {};
			}
			// UI requires these fields to default to "0" if no value present.
			// $timeout used to wait and see if DB provides value (hack)
			// if ($scope.$ctrl.data.best_va_letter == null || $scope.$ctrl.data.best_va_letter == undefined) {
			// 	$scope.$ctrl.data.best_va_letter = 0;
			// }
			// if ($scope.$ctrl.data.uncorrected_va_letter == null || $scope.$ctrl.data.uncorrected_va_letter == undefined) {
			// 	$scope.$ctrl.data.uncorrected_va_letter = 0;
			// }
		}, 800);
	},
]);
