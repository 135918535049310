app.component("iolSection", {
	templateUrl: "app/src/js/components/operationNote/iolSection/iolSection.html",
	controller: "iolSectionController",
	bindings: {
		data: "<",
		eyeTitle: "@",
	},
});

app.controller("iolSectionController", [
	"$scope",
	"LENS_TYPE",
	"LENSPLACEMENT",
	"LENSMODEL",
	"LENSMODEL_MONOFOCAL",
	"LENSMODEL_MONOFOCAL_TORIC",
	"LENSMODEL_EDOF",
	"LENSMODEL_EDOF_TORIC",
	"LENSMODEL_MULTIFOCAL",
	"LENSMODEL_MULTIFOCAL_TORIC",
	"LENSMODEL_ADDON",
	"LENSMODEL_ADDON_TORIC",
	function ($scope, LENS_TYPE, LENSPLACEMENT, LENSMODEL, LENSMODEL_MONOFOCAL,
		LENSMODEL_MONOFOCAL_TORIC, LENSMODEL_EDOF, LENSMODEL_EDOF_TORIC, LENSMODEL_MULTIFOCAL,
		LENSMODEL_MULTIFOCAL_TORIC, LENSMODEL_ADDON, LENSMODEL_ADDON_TORIC) {
		$scope.LENS_TYPE = LENS_TYPE;
		$scope.LENSPLACEMENT = LENSPLACEMENT.map((t) => t);
		$scope.LENSMODEL = LENSMODEL.map((t) => t.name);
		$scope.tempLensModel = {};
		$scope.onChangeManually = false;
		this.$onInit = function () {
			if (
				$scope.$ctrl.data.iol.target_refraction_cylinder ||
				$scope.$ctrl.data.iol.target_refraction_axis ||
				$scope.$ctrl.data.iol.target_refraction_sphere
			) {
				$scope.onChangeManually = true;
			}
		};
		this.$onChanges = function (changesObj) {
			if (changesObj.data.currentValue) {
				if (changesObj.data.currentValue.iol) {
					if (
						changesObj.data.currentValue.iol.lens_placement &&
						angular.isString(changesObj.data.currentValue.iol.lens_placement)
					) {
						const obj = $scope.LENSPLACEMENT.find((t) => t.id === changesObj.data.currentValue.iol.lens_placement);

						if (obj) {
							$scope.$ctrl.placement = {
								val: {
									id: null,
									name: obj.name,
								},
							};
						} else {
							const appendObj = {
								id: changesObj.data.currentValue.iol.lens_placement,
								name: changesObj.data.currentValue.iol.lens_placement,
							};
							$scope.LENSPLACEMENT.push(appendObj);
							$scope.$ctrl.placement = {
								val: {
									id: null,
									name: appendObj.name,
								},
							};
						}
					}
					if(changesObj.data.currentValue.iol.lens_model){
						const lens_model = changesObj.data.currentValue.iol.lens_model;
						if (lens_model) {
							const selected = LENSMODEL.find((t) => t.value === lens_model);
							if (selected) {
								$scope.tempLensModel = {selected: selected.name};
							} else {
								const newOption = { name: lens_model, value: lens_model };
								$scope.LENSMODEL.push(lens_model);
								$scope.tempLensModel = {selected: lens_model};
							}
						}
					}
				}
			}
		};
		$scope.onTypeChange = function () {
			$scope.onChangeManually = true;
			if (
				$scope.$ctrl.data.iol.lens_type !== "TORIC" &&
				$scope.$ctrl.data.iol.lens_type !== "TORIC_MULTIFOCAL" &&
				$scope.$ctrl.data.iol.lens_type !== "TORIC_ADDON" &&
				$scope.$ctrl.data.iol.lens_type !== "TORIC_EDOF"
			) {
				$scope.$ctrl.data.iol.lens_power_cylinder = null;
				$scope.$ctrl.data.iol.lens_axis = null;
			}
			if($scope.$ctrl.data.iol.lens_type === "NON_TORIC"){
				$scope.LENSMODEL = LENSMODEL_MONOFOCAL.map((t) => t.name);
			}else if($scope.$ctrl.data.iol.lens_type === "TORIC"){
				$scope.LENSMODEL = LENSMODEL_MONOFOCAL_TORIC.map((t) => t.name);
			}else if($scope.$ctrl.data.iol.lens_type === "EDOF"){
				$scope.LENSMODEL = LENSMODEL_EDOF.map((t) => t.name);
			}else if($scope.$ctrl.data.iol.lens_type === "TORIC_EDOF"){
				$scope.LENSMODEL = LENSMODEL_EDOF_TORIC.map((t) => t.name);
			}else if($scope.$ctrl.data.iol.lens_type === "MULTIFOCAL"){
				$scope.LENSMODEL = LENSMODEL_MULTIFOCAL.map((t) => t.name);
			}else if($scope.$ctrl.data.iol.lens_type === "TORIC_MULTIFOCAL"){
				$scope.LENSMODEL = LENSMODEL_MULTIFOCAL_TORIC.map((t) => t.name);
			}else if($scope.$ctrl.data.iol.lens_type === "NON_TORIC_ADDON"){
				$scope.LENSMODEL = LENSMODEL_ADDON.map((t) => t.name);
			}else if($scope.$ctrl.data.iol.lens_type === "TORIC_ADDON"){
				$scope.LENSMODEL = LENSMODEL_ADDON_TORIC.map((t) => t.name);
			}else {
				$scope.LENSMODEL = LENSMODEL.map((t) => t.name);
			}
			$scope.tempLensModel.selected = null;
		};

		$scope.onChange = function () {
			if (angular.isString($scope.$ctrl.placement.val)) {
				$scope.$ctrl.placement.val = {
					id: $scope.$ctrl.placement.val,
					name: $scope.$ctrl.placement.val,
				};
				if (!$scope.LENSPLACEMENT.find((t) => t.name === $scope.$ctrl.placement.val.name)) {
					$scope.LENSPLACEMENT.push($scope.$ctrl.placement.val);
				}
			}
			$scope.$ctrl.data.iol.lens_placement = $scope.$ctrl.placement.val.id;
		};

		$scope.getNewLensModel = function (search) {
			var newSupes = $scope.LENSMODEL.slice();
			if (search && newSupes.indexOf(search) === -1) {
				newSupes.unshift(search);
			}
			return newSupes;
		};

		$scope.onModelChange = function () {
			$scope.onChangeManually = true;
			if (!$scope.LENSMODEL.find((t) => t === $scope.tempLensModel.selected)) {
				$scope.LENSMODEL.push($scope.tempLensModel.selected);
			}
			const obj = LENSMODEL.find(t=>t.name === $scope.tempLensModel.selected);
			if(obj){
				$scope.$ctrl.data.iol.lens_model = obj.value;
			}else{
				$scope.$ctrl.data.iol.lens_model = $scope.tempLensModel.selected;
			}
			
		};

		$scope.onLensPowerChange = function () {
			$scope.onChangeManually = true;
		}
	},
]);
